const repResult = {
  success: 1,
  warning: 2,
  faild: 3,
  information: 4,
  successful: 200,
};
const importType = {
  organization: 1,
  facility: 2,
  boundary: 3,
  subdivision: 4,
  neighborhood: 5,
  votingStation: 6,
  streetIndex: 7,
  otherBooking: 8,
  eleSubdivision: 9,
  eleOtherBooking: 10,
  boundaryMap: 11,
  subdivisionMap: 12,
  boundaryImport: 13,
  translationsMap: 14,
  electionElector: 15,
  item: 18,
  equipment: 19,
  eleEquipment: 21,
  vendor: 23,
  genericContainer: 24,
  individualContainer: 25,
  uniqueContainer: 26,
  deliveryRoute: 27,
  worker: 30,
  demo: 999,
  eleSpecificItem: 29,
  eleResultImportData: 33,
  votingStationUpdateResourceAdjustmentBAU: 35,
  votingStationUpdateResourceAdjustmentEvent: 36,
  ContainerAdjustmentQty: 37,
  storageLocation : 38
};

const operationType = {
  add: 1,
  edit: 2,
  delete: 4,
};

const storageType = {
  storageRoom: 1,
  area: 2,
  temporaryWarehouse: 4,
};

const yesNo = {
  yes: 2,
  no: 1,
};

const applicationMethod = {
  noApplication: 1,
  ballotApplication: 2,
  applicationRequest: 4,
};

const pageConsts = {
  alertRemainSeconds: 3,
};

const fieldType = {
  string: 1,
  int: 2,
  datetime: 3,
};
const sessionStore = {
  electionId: "",
};

export {
  pageConsts,
  repResult,
  fieldType,
  sessionStore,
  importType,
  operationType,
  storageType,
  yesNo,
  applicationMethod,
};
