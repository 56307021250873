var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KValidationObserver',{ref:"observer",staticClass:"content-page",scopedSlots:_vm._u([{key:"default",fn:function(valdata){return [_c('k-form',{staticClass:"content-form",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"nav-btn-group"},[_vm._t("formBar",null,null,valdata),_c('KWikiHelp',{attrs:{"props-wiki-help-code":_vm.wikiHelpCode}})],2),_c('div',{staticClass:"content-main"},[_vm._t("formMsg"),_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-navTab"},[(_vm.showNav)?_c('Nav',{ref:"nav",attrs:{"tab-index":_vm.navIndex,"is-plain-text":_vm.plaintext,"is-event":_vm.isEvent,"is-system":_vm.form.isSystem}}):_vm._e()],1),_c('div',{staticClass:"content-party pt-3"},[_c('div',{staticClass:"content-group"},[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"name","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
                remote: _vm.isReportTemplateNameUsed,
                max: 200,
              },"slim":"","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"input-code","plaintext":_vm.plaintext || _vm.form.isSystem},model:{value:(_vm.form.tempDisplayName),callback:function ($$v) {_vm.$set(_vm.form, "tempDisplayName", $$v)},expression:"form.tempDisplayName"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-suffix":"reportType","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"slim":"","name":"reportType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.form.isCustomReport)?_c('k-form-input',{attrs:{"value":_vm.$g('dataExport'),"plaintext":true}}):_c('k-form-input',{attrs:{"value":_vm.$g('cannedReport'),"plaintext":true}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-suffix":"description","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                max: 2000,
              },"slim":"","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-textarea',{attrs:{"plaintext":_vm.plaintext},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)})],1)],1)])],2)])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }