import { render, staticRenderFns } from "./response.vue?vue&type=template&id=4048ef12&scoped=true&"
import script from "./response.vue?vue&type=script&lang=js&"
export * from "./response.vue?vue&type=script&lang=js&"
import style0 from "./response.vue?vue&type=style&index=0&id=4048ef12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4048ef12",
  null
  
)

export default component.exports