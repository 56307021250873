<template>
  <KValidationObserver
    v-slot="{ handleSubmit }"
    class="content-page"
  >
    <election-select v-if="electionId && !isBau" />
    <k-form
      @keydown.enter.prevent
      ref="form"
      @submit.prevent="handleSubmit(onSubmit)"
      class="content-form"
    >
      <div class="nav-btn-group">
        <k-button-group>
          <k-button @click="goBack">
            document.back
          </k-button>
          <k-button type="submit">
            document.save
          </k-button>
        </k-button-group>
      </div>
      <div class="content-main">
        <div class="content-group">
          <k-form-group
            label-class="require-mark"
            label-suffix="document.title"
            label-for="title"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              rules="required|max:200"
              v-slot="{ errors }"
              slim
              :name="$g('document.title')"
            >
              <k-alert
                :show="errors.length > 0"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="title"
                v-model="form.title"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="document.file"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{
                required: true,
                acceptFileType: accept,
                maxFileSize: size * 1024
              }"
              v-slot="{ errors }"
              slim
              :name="$g('document.file')"
            >
              <k-alert
                :show="errors.length > 0"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <common-upload
                ref="fileinput"
                v-model="form.file"
                :accept="accept"
                :size="size"
                :is-multiple="false"
                :on-delete="onDelete"
                :placeholder="placeholder"
                :show-message="false"
                :key="uploadKey"
                :is-use-internal-validate="false"
              />
            </KValidationProvider>
          </k-form-group>
        </div>
      </div>
    </k-form>
  </KValidationObserver>
</template>

<script>
import { addDocument, updateDocument, getDocument } from "./api";
import { getUploadConfig } from "../../api/upload.js";
import storeage from "../../utils/storeage";

export default {
  props: {
    addDocumentApi: {
      type: Function,
      default: addDocument,
    },
    updateDocumentApi: {
      type: Function,
      default: updateDocument,
    },
    getDocumentApi: {
      type: Function,
      default: getDocument,
    },
  },
  data () {
    return {
      id: this.$route.query.id,
      refId: this.$route.query.refId,
      documentId: this.$route.query.documentId,
      docType: this.$route.query.docType,
      logType: this.$route.query.logType,
      accept: "",
      size: 0,
      form: {
        title: "",
        file: [],
      },
      status: 0,
      message: "",
      placeholder: "No file chosen",
      electionId: storeage.getElectionId(),
      uploadKey: 0,
      isDoSave: false,
      originDataString: null,
      isBau: this.$route.meta.isBau,
    };
  },
  methods: {
    onSubmit () {
      let pmOpt = this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.save"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.saveYes"),
        cancelText: this.$g("const.confirm.no"),
      });
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let opt;
        var formData = new FormData();
        if (this.id) {
          opt = this.updateDocumentApi;
          formData.append("Id", this.id);
        } else {
          opt = this.addDocumentApi;
        }
        formData.append("RefId", this.refId);
        formData.append("DocRequestType", this.docType);
        formData.append("LogRequestType", this.logType);
        formData.append("Title", this.form.title);
        formData.append("File", this.form.file[0]);
        if (this.electionId) {
          formData.append("ElectionId", this.electionId);
        }
        if (this.documentId) {
          formData.append("DocumentId", this.documentId);
        }
        opt(formData).then((m) => {
          this.isDoSave = true;
          this.status = m.data.status;
          this.message = m.message;
          this.goBack();
        });
      });
    },
    goBack () {
      this.$router.go(-1);
    },
    onDelete (file) {
      if (this.form.file.length > 0 && this.form.file[0].name == file.name) this.form.file = [];
    },
    beforeRouteLeave (to, from, next) {
      to.meta.fromDocumentDetails = true;
      to.params.id = this.refId;
      to.query.id = this.refId;
      to.query.status = this.status;
      to.query.message = this.message;

      let formStr = JSON.stringify(this.form);
      if (!this.isDoSave && this.originDataString !== formStr) {
        this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.leaveComfirm"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.leaveComfirmYes"),
          cancelText: this.$g("const.confirm.no"),
        })
            .then(() => {
              next();
            })
            .catch(() => {
              next(false);
            });
      } else {
        next();
      }
    },
  },
  mounted () {
    if (this.documentId) {
      this.getDocumentApi(this.documentId).then((data) => {
        this.form.title = data.data.displayName;
        this.form.file = [{ name: data.data.fileName }];
        this.placeholder = data.data.fileName;
        this.validate = false;
        this.uploadKey++;

        this.originDataString = JSON.stringify(this.form);
      });
    } else {
      this.originDataString = JSON.stringify(this.form);
    }

    getUploadConfig("doc").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize / 1024;
    });
  },
  beforeRouteLeave (to, from, next) {
    this.beforeRouteLeave(to, from, next);
  },
  beforeRouteUpdate (to, from, next) {
    to.meta.title =
        to.query.isAdd === "1" ? "documentDetailAddNew" : "documentDetailEdit";
    next();
  },
  beforeRouteEnter (to, from, next) {
    if (to.query.isAdd === "1") {
      to.meta.title = "documentDetailAddNew";
    } else {
      to.meta.title = "documentDetailEdit";
    }
    next();
  },
};
</script>
<style lang="scss">
.form-group .custom-file {
  max-width: 100%;
}
</style>
