import { removeToken,  getToken } from "./token";
import storeage from "./storeage";
import operatePermission from "./permission/operatePermission";

export const logout = () => {
  storeage.removeElectionId();
  removeToken();
  storeage.removeUserInfo();
  // operatePermission.removePermission();
  storeage.removeLocationMenuList();
  storeage.removeElectionMenuList();
};

export function CheckLogout(url) {
  if (!storeage.getUserInfo()?.userId || storeage.timeGet("tokenOut", true)) {
    storeage.removeElectionId();
    removeToken();
    storeage.removeUserInfo();
    // operatePermission.removePermission();
    storeage.removeLocationMenuList();
    storeage.removeElectionMenuList();
  } else {
    location.href = location.href + url;
  }
};

export const clearNavs = () => {
  storeage.removeMyballotNav();
  storeage.removeBauHelpdeskNav();
  storeage.removeBauAssetNav();
  storeage.removeCandidateNav();
  storeage.removeLocationAllNavs();
  storeage.removeBauStaffNav();
  storeage.removeEventStaffNav();
  storeage.removeBauAssetNav();
  storeage.removeAdminNavs();
  storeage.removeEventElectorNav();
  storeage.removeBauElectorNav();
};

export const clearCache = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export function isElement(el){
  return el && el.nodeType === Node.ELEMENT_NODE;
}

export function removeFromDom(el){
  isElement(el) && isElement(el.parentNode) && el.parentNode.removeChild(el);
}

export function spliceIfExist(arr, item){
  if(Array.isArray(arr)){
    const index = arr.indexOf(item);
    if(index >= 0){
      arr.splice(index, 1);
    }
  }
}

function hasClass(classList, className) {
  var result = false;
  for (var i = 0; i < classList.length; i++) {
    if (classList[i] == className) {
      result = true;
      break;
    }
  }
  return result;
}

export function isPrevent(e) {
  let event = e.srcElement || e.target;
  if (
    event.nodeName !== "TEXTAREA" &&
    event.nodeName !== "BUTTON" &&
    !hasClass(event.classList, "vbst-item")
  ) {
    e.preventDefault();
  }
}



export const debounce = () => {
  let timer = null;
  const newDebounce = function (fn, wait, ...args) {
    return new Promise((resolve, reject) => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(_ => {
        try {
          resolve(fn(...args));
        } catch (e) {
          reject(e);
        }
      }, wait);
    });
  };
  return newDebounce;
};

