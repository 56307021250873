<template>
  <div :style="{ display: !isHasSelect ? 'list-item' : '' }">
    <div
      class="languageTitle"
      v-if="!isHasSelect"
    >
      <div class="languageHeader">
        {{ $g("header.language") }}
      </div>
      <div class="languageGroup">
        <a
          v-for="(item, index) in items"
          :key="index"
          @click="systemLanguageChange(item.value)"
          class="languageItem"
        >
          {{ item.text }}
          <b-icon
            icon="check"
            v-show="item.value == curSystemLanguage"
            style="color: #333 !important"
          />
        </a>
      </div>
    </div>
    <k-form-select
      v-else
      v-model="curSystemLanguage"
      :options="systemLanguageList"
      @change="systemLanguageChange"
    />
  </div>
</template>

<script>
import storeage from "../../utils/storeage";
import { getSysLanguageList } from "../../api/app";

export default {
  name: "SelectLanguage",
  props: {
    isHasSelect: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      curSystemLanguage: storeage.getLanguageId(),
      systemLanguageList: [],
      items: null,
    };
  },
  methods: {
    systemLanguageChange(languageId){
      storeage.removeLanguageRes();
      storeage.setLanguageId(languageId);
      this.$router.go(0);
    },
    bindLanguageList(){
      getSysLanguageList().then((resp) => {
        if(resp.data && resp.data.length > 0){
          this.systemLanguageList = resp.data;
          this.items = resp.data;
        }
      });
    },
  },
  mounted(){
    this.bindLanguageList();
  },
};
</script>

<style lang="scss" scoped>
.languageTitle {
  position: relative;

  &:hover {
    .languageGroup {
      display: block;
    }
  }

  .languageHeader {
    padding: 0.25rem 1.5rem;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }

  .languageGroup {
    display: none;
    position: absolute;
    padding: 0.5rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    left: 0;
    top: -0.5rem;
    transform: translateX(-100%);

    .languageItem {
      display: block;
      padding: 0.5rem;
      cursor: pointer;
      color: #333333;
    }
  }
}
</style>
