<template>
  <div class="content content-shareto">
    <div class="center-bar">
      <div class="content-main">
        <div class="content-navTab flex-row justify-content-between">
          <h3 class="title mb-0">
            {{ $g("users") }}
          </h3>
          <div class="nav-tools flex-row">
            <b-icon-add @click="addUsersClickEvent" class="mr-3" />
            <b-icon-delete @click="deleteClickEvent(type.users)" />
          </div>
        </div>
        <div class="content-party">
          <k-alert-msg ref="userslblMsg" />
          <konnFixedGrid
            :has-action="false"
            ref="selectableTableUsers"
            @total-changed="onTotalChanged"
            :fields="usersFields"
            grid-sort-by="firstName"
            id-field="id"
            class="stickyTable"
            :original-items="usersItems"
            :sticky-header="true"
          />
        </div>
      </div>
    </div>
    <div class="center-bar">
      <div class="content-main">
        <div class="content-navTab flex-row justify-content-between">
          <h3 class="title mb-0">
            {{ $g("groups") }}
          </h3>
          <div class="nav-tools flex-row">
            <b-icon-add @click="addGroupsClickEvent" class="mr-3" />
            <b-icon-delete @click="deleteClickEvent(type.gruops)" />
          </div>
        </div>
        <div class="content-party">
          <k-alert-msg ref="groupslblMsg" />
          <KonnFixedGrid
            :has-action="false"
            ref="selectableTableGroups"
            @total-changed="onTotalChanged"
            :fields="groupsFields"
            grid-sort-by="code"
            id-field="id"
            class="stickyTable"
            :original-items="groupsItems"
            :sticky-header="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getShareToUsers,
  getShareToGroups,
  deleteShareToUsers,
  deletShareToGroups,
} from "../api/searchArea";
import KonnFixedGrid from "../../../business-components/konn-grid/konnFixedGrid";

export default {
  components: { KonnFixedGrid },
  props: {
    propsAddUserClickEvent: {
      type: Function,
      default: null,
    },
    sharedUserId: {
      type: String,
      default: null,
    },
    sharedGroupId: {
      type: String,
      default: null,
    },
    searchId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: [],
      usersItems: [],
      usersFields: [
        {
          key: "firstName",
          label: "modal.shareTo.firstName",
        },
        {
          key: "lastName",
          label: "modal.shareTo.lastName",
        },
        {
          key: "mobilePhone",
          label: "modal.shareTo.phone",
        },
        {
          key: "email",
          label: "modal.shareTo.email",
        },
      ],
      groupsItems: [],
      groupsFields: [
        {
          key: "roleName",
          label: "modal.shareTo.group",
        },
        {
          key: "departMentName",
          label: "modal.shareTo.department",
        },
        {
          key: "userCount",
          label: "modal.shareTo.users",
        },
        {
          key: "roleDesc",
          label: "modal.shareTo.description",
        },
      ],
      type: {
        users: 0,
        gruops: 1,
      },
    };
  },
  computed: {},
  created() {
    this.initGrid();
  },
  methods: {
    getGroupsCondition() {
      return {
        searchId: this.searchId,
        sharedGroupId: this.sharedGroupId,
      };
    },
    onTotalChanged() {},
    onFieldsGet() {},
    getUsersItems() {
      return this.usersItems;
    },
    getGroupsItems() {
      return this.groupsItems;
    },
    addUsersClickEvent() {
      this.$bvModal.show("modal-users");
    },
    addGroupsClickEvent() {
      this.$bvModal.show("modal-groups");
    },
    deleteClickEvent(type) {
      let ref = "";
      let pmOpt = null;
      let items = 0;
      let deleteApi = null;
      let ids = [];
      let refreshGrid = null;
      switch (type) {
        case this.type.users:
          ref = "selectableTableUsers";
          items = this.$refs.selectableTableUsers.getSelectedItems();
          ids = items
            ? items.map((c) => {
                return c.searchSharedUserId;
              })
            : [];
          deleteApi = deleteShareToUsers;
          refreshGrid = this.refreshUsersGrid;
          break;
        case this.type.gruops:
          ref = "selectableTableGroups";
          items = this.$refs.selectableTableGroups.getSelectedItems();
          ids = items
            ? items.map((c) => {
                return c.searchSharedGroupId;
              })
            : [];
          deleteApi = deletShareToGroups;
          refreshGrid = this.refreshGroupsGrid;
          break;
        default:
          break;
      }
      if (!items || items.length <= 0) {
        this.$bvModal.msgBoxOk(this.$g("const.warning.select-records"), {
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }
      this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.delete"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.yes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((result) => {
          if (!result) {
            deleteApi(ids).then((result) => {
              this.showMessage({
                status: result.data.status,
                message: result.message,
                type: type,
              });
              if (result.data.status && refreshGrid) {
                refreshGrid();
                setTimeout(() => {
                  this.$refs[ref].setSelectAll(false);
                }, 500);
              }
            });
          }
        });
    },
    initGrid()  {
      this.refreshUsersGrid();
      this.refreshGroupsGrid();
    },
    refreshUsersGrid()  {
      getShareToUsers({
        searchId: this.searchId,
        sharedUserId: this.sharedUserId,
      }).then((result) => {
        this.usersItems = result.data;
      });
    },
    refreshGroupsGrid()  {
      getShareToGroups({
        searchId: this.searchId,
        sharedGroupId: this.sharedGroupId,
      }).then((result) => {
        this.groupsItems = result.data;
      });
    },
    showMessage(
      options = { status: 1, message: "Saved successfully", type: 0 }
    ) {
      let { status, message, type } = options;
      switch (type) {
        case this.type.users:
          this.$refs.userslblMsg.message(status, message);
          break;
        case this.type.gruops:
          this.$refs.groupslblMsg.message(status, message);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.content-shareto > .center-bar:not(:first-child) {
  margin-top: 10px;
}

/* .content-shareto > .center-bar > .content-main > .content-party {
  margin-top: 10px;
}
.content-shareto > .center-bar > .content-main > .content-navTab > .nav-tools {
  margin-left: calc(100% - 50px);
  max-width: 50px;
  z-index: 9;
}
.content-shareto > .center-bar > .content-main > .content-navTab > .title {
  max-width: 100px;
} */
</style>
