<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content" v-show="!showPreview">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <choose-template
          :is-event="isEvent"
          :template-type="curNav.templateType"
          @templateChoose="onTemplateChoose"
          @templateDelete="onTemplateDelete"
          @docChoose="onDocChoose"
          @docDelete="onDocDelete"
          ref="chooseTemplate"
        />
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button
              @click="sendMsg"
              v-if="curNav.templateType !== enumTemplateType.letter && isSend"
              :key="sendMsgButtonKey"
            >
              buttons.send
            </k-button>
            <k-button
              v-if="curNav.templateType !== enumTemplateType.phone"
              @click="toPreview"
              :key="previewButtonKey"
            >
              buttons.preview
            </k-button>
            <k-button
              v-if="!templateId && isSaveAsTemplate"
              @click="saveTemplateClick"
              :key="saveAsTemplateButtonKey"
            >
              buttons.saveAsTemplate
            </k-button>
            <k-button
              v-if="templateId && isSaveAsTemplate"
              @click="saveTemplateClick"
              :key="updateTemplateButtonKey"
            >
              buttons.updateTemplate
            </k-button>
            <k-button
              @click="doSaveDraft"
              v-if="
                curNav.templateType != enumTemplateType.letter &&
                isSendAsDraft &&
                showSaveDraft
              "
              :key="saveAsDraftButtonKey"
              v-text="$g(btnSaveDraftText)"
            />
            <k-button
              @click="saveLetterDraft"
              v-if="
                curNav.templateType == enumTemplateType.letter &&
                isSendAsDraft &&
                showSaveDraft
              "
              :key="letterSaveAsDraftButtonKey"
              v-text="$g(btnSaveDraftText)"
            />
          </k-button-group>
          <button
            class="btn rounded-0 nav-btn-search search-template"
            @click="onToggleSearch"
            :class="{ active: isActive }"
          >
            <b-icon icon="chevron-right" v-if="seenSearch" />
            <b-icon icon="chevron-left" v-else />
          </button>
          <KWikiHelp v-if="wikiHelpCode" :props-wiki-help-code="wikiHelpCode" />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <status-nav
              :refresh="navClick"
              ref="navTabs"
              :items="navItems"
              :current-index="tabindex"
              :need-total-count="false"
            />
          </div>

          <div class="content-party">
            <KValidationObserver ref="observer">
              <div class="content-group">
                <div class="form-row form-group">
                  <div
                    class="require-mark col-md-3 col-form-label text-md-right"
                  >
                    {{ $g("toGroup") }}:
                  </div>
                  <div class="col-md-7 col-lg-6" v-if="!loadingDraft">
                    <KValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                      slim
                      :name="$g('toGroup')"
                      ref="groupValidation"
                    >
                      <k-alert :show="errors.length > 0" variant="warning">
                        {{ errors[0] }}
                      </k-alert>
                      <k-form-select
                        v-model="form.groupId"
                        :options="groups"
                        :plaintext="isPlainText"
                        @change="onGroupChange"
                        v-if="showGroup"
                      />
                      <k-form-select
                        v-model="contactAction"
                        :options="contactActions"
                        :plaintext="isPlainText"
                        :disabled="true"
                        v-if="!showGroup"
                      />
                    </KValidationProvider>
                  </div>
                  <div class="col-lg-3">
                    <k-button
                      no-locale
                      @click="groupDetailDialog"
                      class="mr-1"
                      v-if="showDetailButton"
                    >
                      {{ detailButton }}
                    </k-button>
                    <k-button
                      v-if="isAddGroup && showAddGroup"
                      @click="toAddGroup"
                    >
                      addGroup
                    </k-button>
                  </div>
                </div>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="contactOption"
                  label-for="ContactOption"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="showContactOption"
                >
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    mode="passive"
                    slim
                    name="contactOption"
                    ref="contactOptionValidation"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-checkbox-group
                      class="col-form-label d-block"
                      v-model="form.contacts"
                      :plaintext="isPlainText"
                      :options="contacts"
                      value-field="contactId"
                      text-field="title"
                      @input="onContactsInput"
                      @change="contactOptionChange"
                    />
                  </KValidationProvider>
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    mode="passive"
                    slim
                    :name="$g('PhoneTypes')"
                    ref="phoneTypesValidation"
                    v-if="
                      false &&
                      (curNav.templateType === enumTemplateType.phone ||
                        curNav.templateType === enumTemplateType.sms) &&
                      form.contacts &&
                      form.contacts.length > 0
                    "
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-checkbox-group
                      class="col-form-label d-block"
                      v-model="selectPhoneTypes"
                      :plaintext="isPlainText"
                      :options="phoneTypes"
                      @input="onContactsInput"
                      @change="phoneTypesOptionChange"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="sendTo"
                  label-for="sendTo"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="showSendTo"
                >
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    slim
                    :name="$g('sendTo')"
                    ref="sendToValidation"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-multiselect
                      :options="sendToOptions"
                      v-model="form.contactOptions"
                    />
                  </KValidationProvider>
                </k-form-group>

                <k-form-group
                  label-suffix="survey"
                  label-for="Survey"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="
                    (curNav.templateType === enumTemplateType.email ||
                      curNav.templateType === enumTemplateType.phone) &&
                    hasSurvey
                  "
                >
                  <k-form-checkbox
                    switch
                    v-model="form.isSurvey"
                    :disabled="isPlainText"
                    class="col-form-label d-block"
                  />
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="response1"
                  label-for="Response1"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="
                    form.isSurvey &&
                    (curNav.templateType === enumTemplateType.email ||
                      curNav.templateType === enumTemplateType.phone)
                  "
                >
                  <KValidationProvider
                    :rules="{ required: true, max: 100 }"
                    v-slot="{ errors }"
                    slim
                    name="response1"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      v-model="form.response1"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="response2"
                  label-for="Response2"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="form.isSurvey"
                >
                  <KValidationProvider
                    :rules="{ required: true, max: 100 }"
                    v-slot="{ errors }"
                    slim
                    name="response2"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input v-model="form.response2" />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-suffix="schedule"
                  label-for="Schedule"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="
                    curNav.templateType === enumTemplateType.email ||
                    curNav.templateType === enumTemplateType.phone ||
                    curNav.templateType === enumTemplateType.sms
                  "
                >
                  <k-form-checkbox
                    switch
                    v-model="form.schedule"
                    :disabled="isPlainText"
                    class="col-form-label d-block"
                  />
                  <KValidationProvider
                    rules="required|validateDateIsRequired|validateDateTimeFormat|validateDatetimeBigNow"
                    v-slot="{ errors }"
                    slim
                    name="schedule"
                    v-if="form.schedule"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-date-timepicker
                      v-model="form.scheduleTime"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="senderEmailAs"
                  label-for="SenderEmailAs"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="curNav.templateType === enumTemplateType.email"
                >
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    slim
                    name="senderEmailAs"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.fromEmail"
                      :options="fromEmails"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="callerID"
                  label-for="CallerID"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="curNav.templateType === enumTemplateType.phone"
                >
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    slim
                    name="callerID"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.callerId"
                      :options="callerIDs"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="phoneNumberAs"
                  label-for="PhoneNumberAs"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="curNav.templateType === enumTemplateType.sms"
                >
                  <KValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    slim
                    name="phoneNumberAs"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.dialPlan"
                      :options="phoneNumbers"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-class="require-mark"
                  label-suffix="title"
                  label-for="title"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: true,
                      max: 200,
                      validateSpecialCharacter: false,
                      remote: isSubjectExits,
                    }"
                    v-slot="{ errors }"
                    slim
                    name="title"
                    ref="title"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      v-model="form.subject"
                      :plaintext="isPlainText"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-for="Content"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  v-if="curNav.templateType === enumTemplateType.phone"
                >
                  <KValidationProvider
                    :rules="{
                      required: true,
                      acceptFileType: accept,
                      maxFileSize: size,
                    }"
                    v-slot="{ errors }"
                    slim
                    :name="$g('content')"
                    ref="contentValidation"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <HuiAudio
                      v-model="form.file"
                      :is-plain-text="isPlainText"
                      :name="form.fileName"
                    />
                    <div class="mt-1" v-show="!isPlainText">
                      {{ $g("size") }}: {{ size / 1024 / 1024 }}MB
                      <br />
                      {{ $g("extensions") }}: {{ accept }}
                    </div>
                  </KValidationProvider>
                </k-form-group>
              </div>
              <KValidationObserver ref="LanguageListObserver">
                <LanguageList
                  ref="languageList"
                  :person-languages="personLanguages"
                  @onLanguageSwitch="onLanguageSwitch"
                  :is-validation="true"
                  v-if="curNav.templateType !== enumTemplateType.phone"
                >
                  <template #content>
                    <SendMessageDetailContent
                      ref="sendMessageDetailContent"
                      :is-plain-text="isPlainText"
                      :template-type="curNav.templateType"
                      :enum-template-type="enumTemplateType"
                      @label-select="onLabelSelect"
                      :form="typeFormContent"
                      @form-change="typeFormContentNewMapChange"
                    >
                      <template #smsInsertLabel="{ form }">
                        <div
                          v-if="!isPlainText"
                          class="col-form-label"
                          id="smsInsertLabel"
                        >
                          <img
                            src="../../../../../assets/image/InsertLabel.png"
                            v-b-tooltip="{
                              placement: 'topright',
                              trigger: 'hover',
                              title: 'Insert Label',
                              boundary: 'window',
                              container: 'insertLabel',
                            }"
                            style="cursor: pointer"
                            alt="smsInsertLabel"
                            v-b-modal.pop-sms-insert-label-dialog
                          />
                        </div>
                        <k-popwindow
                          id="pop-sms-insert-label-dialog"
                          @ok="onSmsLabelSelect(form)"
                          :title="$g('InsertLabels ')"
                        >
                          <slot name="insertLabel" />
                        </k-popwindow>
                      </template>
                      <template #insertLabel>
                        <slot name="insertLabel" /> </template
                      >>
                    </SendMessageDetailContent>
                  </template>
                </LanguageList>
              </KValidationObserver>
            </KValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <k-popwindow
      id="pop-save-template"
      size="lg"
      @ok="saveTemplate"
      no-enforce-focus
      :check-selected="false"
      :before-close="onBeforeClose"
      :title="templateId ? $g('updateTemplate') : $g('saveAsTemplate')"
    >
      <PopSaveTemplate
        :election-id="electionId"
        :template-type="curNav.templateType"
        ref="saveTemplateRef"
      />
    </k-popwindow>
    <k-popwindow id="pop-contact-details" :hide-ok="true">
      <slot
        name="contact-details"
        :groupId="form.groupId"
        :contactOptionIds="form.contacts"
        :groupType="groupType"
        :getContacts="getContacts"
        :templateType="curNav.templateType"
        :actionIds="actionIds"
        :contactAction="contactAction"
        :phoneTypes="selectPhoneTypes"
      />
    </k-popwindow>
    <preview
      v-if="showPreview"
      @preview-back="previewBack"
      :body="form.body"
      :type="curNav.templateType"
      :group-id="form.groupId"
      :contact-action="contactAction"
      :action-ids="actionIds"
      :subject="form.subject"
      :election-id="form.electionId"
      :contact-option-ids="form.contacts"
      :phone-types="selectPhoneTypes"
      :schedule-time="form.scheduleTime"
      :preview-person-languages="getPersonLanguagesCloneDeep"
      :type-form-content-map="getAllTypeFormContentMap()"
      :contact-options="getContactOptions"
      :permission-code="permissionCode"
      :permission-code-event="permissionCodeEvent"
      :has-generate-mailing-address="getHasGenerateMailingAddress"
      :props-wiki-help-code="previewWikiHelpCode"
      ref="preview"
    />
  </div>
</template>
<script>
import {
  getTemplate,
  addTemplate,
  putTemplate,
  getCategories,
  checkSubjectExits,
} from "../../../api/messageTemplate";
import {
  getSendDataInit,
  sendEmail,
  getContactAction,
  getEmailSender,
  getContacts,
  saveEmailDraft,
  getPhoneNumbers,
  getCallerIDs,
  sendSMS,
  saveSMSDraft,
  sendPhone,
  savePhoneDraft,
  saveLetterDraft,
  getPersonLanguages,
} from "../../../api/message";
import {
  getDraft,
  getLetterDraft,
  getActions,
} from "../../../api/messageDraft";
import LanguageList from "../../../components/languageList.vue";
import SendMessageDetailContent from "./sendMessageDetailContent.vue";
import { getGroupDropdown } from "../../../api/messageGroup";
import statusNav from "../../../components/common-nav.vue";
import chooseTemplate from "../../../components/chooseTemplate";
import { enumTemplateType } from "../../../enums/enumTemplateType";
import { msgType } from "../../../enums/msgType";
import { enumContactAction } from "../../../enums/enumContactAction";
import { enumPhoneType } from "../../../enums/enumPhoneType";
import HuiAudio from "../../../../../components/media/audio.vue";
import { originDataChangeMixin } from "../../../../../utils/mixins/mixin";
import preview from "../../../components/preview.vue";
import { repResult } from "../../../../../libs/global";
import { getUploadConfig } from "../../../../../api/upload.js";
import { permissionCode } from "../../../mixins/permissionCode";
import { has } from "../../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../../utils/permission/permission";
import storeage from "../../../../../utils/storeage";
import { enumDeliveryResultType } from "../../../enums/enumDeliveryResultType";
import { aloneMobileLayout } from "../../../../../utils/mixins/aloneMobileLayout";
import { getCurPageConfigbyRoutePath } from "../../../../../utils/tools";
import PopSaveTemplate from "./popSaveTemplate.vue";

export default {
  mixins: [originDataChangeMixin, permissionCode, aloneMobileLayout],
  props: {
    hasGenerateMailingAddress: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showSaveDraft: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hideNav: {
      type: Array,
      default() {
        return [];
      },
    },
    showContactOption: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showSendTo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showDetailButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showAddGroup: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    id: {
      type: String,
      default: "",
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasSurvey: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {
    PopSaveTemplate,
    statusNav,
    chooseTemplate,
    HuiAudio,
    preview,
    SendMessageDetailContent,
    LanguageList,
  },
  data() {
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    return {
      electionId: this.isEvent ? storeage.getElectionId() : "",
      form: {
        electionId: this.isEvent ? storeage.getElectionId() : "",
        contacts: [],
        isSurvey: false,
        response1: "",
        response2: "",
        schedule: false,
        scheduleTime: null,
        fromEmail: "",
        callerId: "",
        dialPlan: "",
        subject: "",
        body: "",
        file: "",
        fileName: "audio",
        contactOptions: [],
      },
      tabindex:
        this.$route.params.tabindex != null
          ? parseInt(this.$route.params.tabindex)
          : 0,
      navItems: [],
      curNav: {},
      enumTemplateType: enumTemplateType,
      groups: [],
      docList: [],
      templateId: "",
      buttonKey: 0,
      categories: {},
      contactCount: "",
      contacts: [],
      fromEmails: [],
      phoneNumbers: [],
      callerIDs: [],
      groupType: 0,
      contactActions: [],
      sendTos: [],
      enumContactAction: enumContactAction,
      phoneTypes: [
        { value: enumPhoneType.workPhone, text: this.$g("options.workPhone") },
        {
          value: enumPhoneType.mobilePhone,
          text: this.$g("options.mobilePhone"),
        },
        { value: enumPhoneType.homePhone, text: this.$g("options.homePhone") },
      ],
      selectPhoneTypes: [],
      showPreview: false,
      contactAction: this.$route.meta.contactAction,
      test: this.$route.meta.contactAction,
      seenSearch: true,
      isActive: true,
      isSaveTemplate: false,
      isBindContacts: false,
      loadingDraft: this.$route.query.id,
      accept: "",
      size: 0,
      has,
      isSend: true,
      isSaveAsTemplate: true,
      isSendAsDraft: true,
      isExporttoPDF: true,
      isAddGroup: true,
      personLanguages: [],
      languageId: "",
      defaultTypeFormContent: {
        body: null,
        files: [],
        footerDisplay: null,
        footerId: null,
        headerDisplay: null,
        headerId: null,
        id: null,
        languageId: null,
        messageId: null,
        subject: null,
      },
      typeFormContent: {},
      typeFormContentMap: new Map(),
      typeFormContentNewMap: new Map(),
      strJsonForm: null,
      sendToOptions: [],
      sendToJumpOptions: [],
      btnSaveDraftText: " buttons.saveAsDraft",
      deliveryResultType: enumDeliveryResultType.unknown,
      wikiHelpCode: null,
      previewWikiHelpCode: null,
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
      smsForm: null,
    };
  },
  computed: {
    getHasGenerateMailingAddress() {
      return (
        this.hasGenerateMailingAddress &&
        this.curNav?.templateType === enumTemplateType.letter
      );
    },
    getContactOptions() {
      let arr = [];
      this.form.contactOptions?.forEach((element, index) => {
        let options = this.sendToOptions.find((c) => c.value == element);
        if (!element.value && element != "sendToJumpOptions") {
          arr.push({
            title: options.text,
            contactId: options.value,
            childrenContactAction: options.childrenContactAction,
          });
        } else {
          this.sendToJumpOptions.forEach((e) => {
            arr.push({
              title: e.text,
              contactId: e.value,
              childrenContactAction: e.childrenContactAction,
            });
          });
        }
      });
      return arr;
    },
    getPersonLanguagesCloneDeep() {
      let data = _.cloneDeep(this.personLanguages.filter((c) => c.isData));
      data.forEach((c) => {
        c.active = c.isDefault;
      });
      return data;
    },
    getContacts() {
      return getContacts;
    },
    detailButton() {
      if (this.contactCount !== "" && this.contactCount !== null) {
        return `${this.$g("details")} (${this.contactCount})`;
      }
      return this.$g("details");
    },
    contentLength() {
      if (this.form.body) {
        return this.form.body.length;
      }
      return 0;
    },
    sendMsgButtonKey() {
      return "sendMsgButton" + this.buttonKey;
    },
    previewButtonKey() {
      return "previewButton" + this.buttonKey;
    },
    saveAsTemplateButtonKey() {
      return "saveAsTemplateButton" + this.buttonKey;
    },
    updateTemplateButtonKey() {
      return "updateTemplateButton" + this.buttonKey;
    },
    saveAsDraftButtonKey() {
      return "saveAsDraftButton" + this.buttonKey;
    },
    letterSaveAsDraftButtonKey() {
      return "letterSaveAsDraftButton" + this.buttonKey;
    },
    sendMethod() {
      var method;
      switch (this.curNav?.templateType) {
        case enumTemplateType.email:
          method = sendEmail;
          this.deliveryResultType = enumDeliveryResultType.email;
          break;
        case enumTemplateType.sms:
          method = sendSMS;
          this.deliveryResultType = enumDeliveryResultType.sms;
          break;
        case enumTemplateType.phone:
          method = sendPhone;
          this.deliveryResultType = enumDeliveryResultType.phone;
          break;
        default:
          break;
      }
      return method;
    },
    saveDraftMethod() {
      var method = saveEmailDraft;
      switch (this.curNav?.templateType) {
        case enumTemplateType.email:
          method = saveEmailDraft;
          break;
        case enumTemplateType.sms:
          method = saveSMSDraft;
          break;
        case enumTemplateType.phone:
          method = savePhoneDraft;
          break;
        default:
          break;
      }
      return method;
    },
    actionIds() {
      var ids = [];
      if (this.sendTos) {
        this.sendTos.forEach((element) => {
          ids.push(element.id);
        });
      }
      return ids;
    },
    getDraftMethod() {
      if (this.curNav.templateType === enumTemplateType.letter) {
        return getLetterDraft;
      }
      return getDraft;
    },
    showGroup() {
      return (
        this.$route.meta.contactAction === enumContactAction.none &&
        this.sendTos.length === 0
      );
    },
  },
  created() {
    this.initBtnSaveDraftText();
  },
  mounted() {
    this.showAlert();
    this.bindSendObjects();
    this.initNav();
    this.bindCategories();
    this.bindGroups();
    this.bindEmailSender();
    this.bindPhoneNumbers();
    this.bindCallerIDs();
    this.bindContactAction();

    getUploadConfig("sendphone").then((data) => {
      this.accept = data.data.acceptTypes;
      this.size = data.data.maxSize;
    });
  },
  methods: {
    getAllTypeFormContentMap() {
      return new Map([
        ...this.typeFormContentMap,
        ...this.typeFormContentNewMap,
      ]);
    },
    initBtnSaveDraftText() {
      if (this.id) {
        this.btnSaveDraftText = " buttons.updateDraft";
      } else {
        this.btnSaveDraftText = " buttons.saveAsDraft";
      }
    },
    async getContent() {
      let contentIsValid = true;
      if (this.curNav.templateType === this.enumTemplateType.letter) {
        contentIsValid = await this.$refs.LanguageListObserver.validate();
      }
      return contentIsValid;
    },
    typeFormContentNewMapChange(newValue) {
      if (!newValue.languageId) {
        newValue.languageId = this.languageId;
      }
      if (!newValue.messageId && this.id) {
        newValue.messageId = this.id;
      }
      if (!this.typeFormContentNewMap.get(this.languageId)) {
        this.typeFormContentNewMap.set(this.languageId, newValue);
      }
      this.personLanguages.find((c) => c.id == this.languageId).isData =
        newValue.isData;
    },
    checkTypeFormContentNewMapIsData(data) {
      if (data.subject && data.body) {
        return true;
      }
      return false;
    },
    setTypeFormContentMap(data) {
      let dataType = Array.isArray(data);
      if (dataType && data.length > 0) {
        data.forEach((c) => {
          this.typeFormContentMap.set(c.languageId, _.cloneDeep(c));
          this.typeFormContentNewMap.set(c.languageId, _.cloneDeep(c));
        });
        this.typeFormContent = this.typeFormContentNewMap.get(this.languageId);
      } else {
        if (!data || dataType) {
          this.typeFormContent = _.cloneDeep(this.defaultTypeFormContent);
        } else {
          this.typeFormContent = data;
        }
      }
    },
    setTypeFormContentNewMap(data) {
      this.typeFormContentNewMap = new Map();
      this.personLanguages.map((c) => {
        c.isData = false;
      });
      let dataType = Array.isArray(data);
      if (dataType && data.length > 0) {
        data.forEach((c) => {
          c.files?.forEach((d) => {
            d.name = d.fileName;
            d.fileName = "";
          });
          this.typeFormContentNewMap.set(
            c.languageId,
            _.cloneDeep({ ...c, body: c.content, id: "" })
          );
          let _personLanguages = this.personLanguages.find(
            (p) => p.id == c.languageId && c.content && c.subject
          );
          if (_personLanguages) {
            _personLanguages.isData = true;
          }
        });
      } else {
        this.typeFormContentNewMap.forEach((value, key, map) => {
          map.set(key, _.cloneDeep(this.defaultTypeFormContent));
        });
      }
      this.typeFormContent = this.typeFormContentNewMap.get(this.languageId);
    },
    onLanguageSwitch(item) {
      if (this.typeFormContent && this.typeFormContent.languageId == item.id) {
        return;
      }
      this.languageId = item.id;
      this.setTypeFormContentMap(this.typeFormContentNewMap.get(item.id));
    },
    setPermission() {
      if (this.isEvent) {
        this.isAddGroup = has(this.permissionCodeEvent.Communication.GroupsAdd);
      } else {
        this.isAddGroup = has(this.permissionCode.Communication.GroupsAdd);
      }

      let wikiHelpCodes = null;
      wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.SendMessage
        : this.$wikiHelpLinkCodes?.BAU.Communication.SendMessage;

      switch (this.curNav?.templateType) {
        case enumTemplateType.email:
          this.isExporttoPDF = false;
          if (this.isEvent) {
            this.isSend = has(
              this.permissionCodeEvent.Communication.SendEmailSend
            );
            this.isSaveAsTemplate = has(
              this.permissionCodeEvent.Communication.SendEmailSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCodeEvent.Communication.SendEmailSendAsDraft
            );
          } else {
            this.isSend = has(this.permissionCode.Communication.SendEmailSend);
            this.isSaveAsTemplate = has(
              this.permissionCode.Communication.SendEmailSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCode.Communication.SendEmailSendAsDraft
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Email.SendPage;
          this.previewWikiHelpCode = wikiHelpCodes?.Email.PreviewPage;
          break;
        case enumTemplateType.sms:
          this.isExporttoPDF = false;
          if (this.isEvent) {
            this.isSend = has(
              this.permissionCodeEvent.Communication.SendSMSSend
            );
            this.isSaveAsTemplate = has(
              this.permissionCodeEvent.Communication.SendSMSSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCodeEvent.Communication.SendSMSSendAsDraft
            );
          } else {
            this.isSend = has(this.permissionCode.Communication.SendSMSSend);
            this.isSaveAsTemplate = has(
              this.permissionCode.Communication.SendSMSSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCode.Communication.SendSMSSendAsDraft
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.SMS.SendPage;
          this.previewWikiHelpCode = wikiHelpCodes?.SMS.PreviewPage;
          break;
        case enumTemplateType.phone:
          this.isExporttoPDF = false;
          if (this.isEvent) {
            this.isSend = has(
              this.permissionCodeEvent.Communication.SendPhoneSend
            );
            this.isSaveAsTemplate = has(
              this.permissionCodeEvent.Communication.SendPhoneSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCodeEvent.Communication.SendPhoneSendAsDraft
            );
          } else {
            this.isSend = has(this.permissionCode.Communication.SendPhoneSend);
            this.isSaveAsTemplate = has(
              this.permissionCode.Communication.SendPhoneSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCode.Communication.SendPhoneSendAsDraft
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Phone.SendPage;
          this.previewWikiHelpCode = wikiHelpCodes?.Phone.PreviewPage;
          break;
        case enumTemplateType.letter:
          if (this.isEvent) {
            this.isSend = false;
            this.isExporttoPDF = has(
              this.permissionCodeEvent.Communication.PrintLetterExporttoPDF
            );
            this.isSaveAsTemplate = has(
              this.permissionCodeEvent.Communication.PrintLetterSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCodeEvent.Communication.PrintLetterSendAsDraft
            );
          } else {
            this.isSend = false;
            this.isExporttoPDF = has(
              this.permissionCode.Communication.PrintLetterExporttoPDF
            );
            this.isSaveAsTemplate = has(
              this.permissionCode.Communication.PrintLetterSaveAsTemplate
            );
            this.isSendAsDraft = has(
              this.permissionCode.Communication.PrintLetterSendAsDraft
            );
          }
          this.wikiHelpCode = wikiHelpCodes?.Letter.SendPage;
          this.previewWikiHelpCode = wikiHelpCodes?.Letter.PreviewPage;
          break;
        default:
          break;
      }
    },
    phoneTypesOptionChange(val) {
      if (val.length > 0) {
        this.$refs.phoneTypesValidation.validate();
      }
    },
    contactOptionChange(val) {
      if (val.length > 0) {
        this.$refs.contactOptionValidation.validate();
      }
    },
    getFormData(isDoSaveDraft = false) {
      this.form.dcoumentListId = [];
      this.docList.forEach((doc) => {
        this.form.dcoumentListId.push(doc.documentId);
      });
      var formData = new FormData();
      formData = this.setGetFormDataFuncFormData();

      if (this.form.isSurvey) {
        formData.append("isSurvey", this.form.isSurvey);
      }
      if (this.form.callerId) {
        formData.append("callerId", this.form.callerId);
      }
      if (this.form.fromEmail) {
        formData.append("fromEmail", this.form.fromEmail);
      }
      if (this.form.dialPlan) {
        formData.append("dialPlan", this.form.dialPlan);
      }
      if (this.form.schedule) {
        formData.append("schedule", this.form.schedule);
      }
      if (this.form.scheduleTime) {
        formData.append("scheduleTime", this.form.scheduleTime);
      }
      formData.append("type", this.convertType(this.curNav.templateType));
      if (this.form.dcoumentListId && this.form.dcoumentListId.length > 0) {
        this.form.dcoumentListId.forEach((element) => {
          formData.append("dcoumentListId[]", element);
        });
      }
      if (this.form.file && this.form.file.size > 0) {
        formData.append("file", this.form.file);
      }
      if (this.form.file && this.form.file.length > 0) {
        formData.append("fileUrl", this.form.file);
      }
      if (this.form.fileId && this.form.fileId.length > 0) {
        formData.append("fileId", this.form.fileId);
      }
      formData = this.getTypeFormContentaData(formData, isDoSaveDraft);
      return formData;
    },
    getTypeFormContentaData(
      _formData,
      isDoSaveDraft = false,
      formDataKey = "MsgLanguageDetails"
    ) {
      let formData = _formData;
      let msgLanguageDetailsForm = [];
      if (
        this.typeFormContentMap.size <= 0 &&
        this.typeFormContentNewMap <= 0
      ) {
        return msgLanguageDetailsForm;
      }
      for (let [key, value] of this.typeFormContentNewMap) {
        if (!value.subject && !value.body) {
          this.typeFormContentNewMap.delete(key);
        }
      }
      if (isDoSaveDraft) {
        this.setTypeFormContentToForm(
          this.typeFormContentNewMap,
          formData,
          formDataKey
        );
      } else {
        let mapData = new Map([
          ...this.typeFormContentMap,
          ...this.typeFormContentNewMap,
        ]);
        if (mapData && mapData.size > 0) {
          this.setTypeFormContentToForm(mapData, formData, formDataKey);
        }
      }
      return formData;
    },
    setTypeFormContentToForm(
      map,
      formData,
      formDataKey = "MsgLanguageDetails"
    ) {
      let index = 0;
      const defaultKey = Object.keys(this.defaultTypeFormContent);
      let setTypeFormContentToFormFiles = (v) => {
        if (!v) {
          return;
        }
        v.forEach((value, i) => {
          formData.append(
            `${formDataKey}[${index}].files[${i}].isDocAdd`,
            value["isDocAdd"] ?? false
          );
          formData.append(
            `${formDataKey}[${index}].files[${i}].MessageRecordsId`,
            value["messageRecordsId"] ?? ""
          );
          formData.append(
            `${formDataKey}[${index}].files[${i}].DocumentId`,
            value["documentId"] ?? ""
          );
          if (!value.documentId) {
            formData.append(
              `${formDataKey}[${index}].files[${i}].FileName`,
              value.name
            );
            formData.append(`${formDataKey}[${index}].files[${i}].File`, value);
          } else {
            formData.append(
              `${formDataKey}[${index}].files[${i}].FileName`,
              value["fileName"]
            );
          }
        });
      };
      let switchDefaultKey = (k, value) => {
        switch (k.toLocaleLowerCase()) {
          case "body":
            if (formDataKey != "MsgLanguageDetails") {
              formData.append(
                `${formDataKey}[${index}].content`,
                value[k] ?? ""
              );
            } else {
              formData.append(`${formDataKey}[${index}].${k}`, value[k] ?? "");
            }
            break;
          case "files":
            setTypeFormContentToFormFiles(value[k]);
            break;
          default:
            formData.append(`${formDataKey}[${index}].${k}`, value[k] ?? "");
            break;
        }
      };
      map.forEach((value, key) => {
        defaultKey.forEach((k) => {
          switchDefaultKey(k, value);
        });
        index++;
      });
    },
    switchDefaultKeySetTypeFormContentToForm() {},
    setGetFormDataFuncFormData() {
      let formData = new FormData();
      if (this.form.id) {
        formData.append("id", this.form.id);
      }
      if (this.form.electionId) {
        formData.append("electionId", this.form.electionId);
      }
      if (this.contactAction) {
        formData.append("contactAction", this.contactAction);
      }
      if (this.form.groupId) {
        formData.append("groupId", this.form.groupId);
      }
      if (this.sendTos) {
        this.sendTos.forEach((element) => {
          formData.append("actionIds[]", element.id);
        });
      }
      if (this.form.contactOptions) {
        this.getContactOptions.forEach((element, index) => {
          formData.append(`ContactOptions[${index}].title`, element.title);
          formData.append(
            `ContactOptions[${index}].contactId`,
            element.contactId
          );
          formData.append(
            `ContactOptions[${index}].childrenContactAction`,
            element.childrenContactAction
          );
        });
      }
      if (this.form.contacts) {
        this.form.contacts.forEach((element) => {
          formData.append("contacts[]", element);
        });
      }
      if (this.selectPhoneTypes) {
        this.selectPhoneTypes.forEach((element) => {
          formData.append("phoneTypes[]", element);
        });
      }
      if (this.form.response1) {
        formData.append("response1", this.form.response1);
      }
      if (this.form.response2) {
        formData.append("response2", this.form.response2);
      }
      if (this.form.subject) {
        formData.append("subject", this.form.subject);
      }
      if (this.form.body) {
        formData.append("body", this.form.body);
      }
      return formData;
    },
    async doSaveDraft() {
      this.setIsSaveTemplate(false);
      const isValid = await this.$refs.title.validate();
      if (isValid.valid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt
          .then(() => {
            var formData = this.getFormData(true);
            this.saveDraftMethod(formData).then((m) => {
              this.setOrignData(this.form);
              this.$refs.lblMsg.message(m.data.status, m.message);
              this.$router.push({
                name: this.isEvent ? "eleSendMessage" : "sendMessage",
                query: {
                  id: m.data.info,
                  msgType: this.curNav.templateType,
                  t: new Date().getTime(),
                },
                params: {
                  status: m.data.status,
                  message: m.message,
                },
              });
            });
          })
          .catch((err) => {
            throw err;
          });
      }
      this.$refs.title.$el
        ?.querySelector('.validateAlert [role="alert"]')
        ?.parentNode?.querySelector("input,select,textarea")
        ?.focus();
    },
    async saveLetterDraft() {
      this.setIsSaveTemplate(false);
      const isValid = await this.$refs.title.validate();
      if (isValid.valid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(()=>{
          let msgLanguageDetails = [];
          this.typeFormContentNewMap.forEach((v, k) => {
            msgLanguageDetails.push(v);
          });
          let para = {
            id: this.form.id,
            groupId: this.form.groupId,
            contacts: this.form.contacts,
            subject: this.form.subject,
            body: this.form.body,
            electionId: this.form.electionId,
            contactAction: this.contactAction,
            actionIds: this.actionIds,
            msgLanguageDetails: msgLanguageDetails,
          };
          saveLetterDraft(para).then((r) => {
            this.$refs.lblMsg.message(r.data.status, r.message);
            this.$router.push({
              name: this.isEvent ? "eleSendMessage" : "sendMessage",
              query: {
                id: r.data.info,
                msgType: this.curNav.templateType,
              },
              params: {
                status: r.data.status,
                message: r.message,
              },
            });
          });
        })
      }
    },
    CheckSubjectBody() {
      let isShowErr = true;
      if (this.curNav.templateType != enumTemplateType.phone) {
        for (let [key, value] of this.typeFormContentNewMap) {
          if (key && value.subject && value.body) {
            isShowErr = false;
          }
        }
      } else {
        isShowErr = false;
      }
      return isShowErr;
    },
    async sendMsg() {
      this.setIsSaveTemplate(false);
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (this.CheckSubjectBody()) {
          this.$refs.lblMsg.message(
            repResult.faild,
            this.$g("const.confirm.messageContentNull")
          );
          return;
        }
        var formData = this.getFormData();
        this.sendMethod(formData).then((m) => {
          this.$refs.lblMsg.message(m.data.status, m.message);
          if (m.data.status != repResult.faild) {
            this.setOrignData(this.form);
            this.btnSaveDraftText = " buttons.saveAsDraft";
            if (m.data.info.result) {
              this.$router.push({
                name: this.isEvent
                  ? "eleMessageDeliveryReport"
                  : "messageDeliveryReport",
                query: {
                  id: m.data.info.id,
                  isView: "1",
                  dbtn: "0",
                  deliveryType: this.deliveryResultType,
                  fromSend: "1",
                  contactCount: this.contactCount,
                },
              });
            }
          }
        });
      }
    },
    onGroupChange(val) {
      this.isBindContacts = false;
      this.form.contacts = [];
      this.selectPhoneTypes = [];
      if (val) {
        this.bindContacts(val);
      } else {
        if (this.showGroup) {
          this.contactAction = null;
        }
        this.bindContacts();
      }
    },
    onContactsInput() {
      if (this.form.groupId || !this.showGroup)
        this.bindContacts(this.form.groupId);
    },
    groupDetailDialog() {
      this.$bvModal.show("pop-contact-details");
    },
    toAddGroup() {
      this.$router.push({
        name: this.isEvent ? "eleMessageGroupAdd" : "messageGroupAdd",
        query: {
          hidePreNext: "1",
          tabindex: this.tabindex,
          otherBackRoute: this.isEvent ? "eleSendMessage" : "sendMessage",
          msgType: this.$route.query.msgType,
        },
      });
    },
    initNav() {
      // this.navItems = [
      //   {
      //     text: this.$g('SendEmail'),
      //     templateType: enumTemplateType.email,
      //     orderIndex: 0,
      //   },
      //   {
      //     text: this.$g('SendSMS'),
      //     templateType: enumTemplateType.sms,
      //     orderIndex: 1,
      //   },
      //   {
      //     text: this.$g('SendPhone'),
      //     templateType: enumTemplateType.phone,
      //     orderIndex: 2,
      //   },
      //   {
      //     text: this.$g('PrintLetter'),
      //     templateType: enumTemplateType.letter,
      //     orderIndex: 3,
      //   },
      // ]
      let pl_code = this.isEvent
        ? this.permissionCodeEvent
        : this.permissionCode;

      let PermissionNavs = [
        {
          text: this.$g("tabs.detail.sendEmail"),
          templateType: enumTemplateType.email,
          permissionCode: pl_code.Communication.SendEmail,
        },
        {
          text: this.$g("tabs.detail.sendSMS"),
          templateType: enumTemplateType.sms,
          permissionCode: pl_code.Communication.SendSMS,
        },
        {
          text: this.$g("tabs.detail.sendPhone"),
          templateType: enumTemplateType.phone,
          permissionCode: pl_code.Communication.SendPhone,
        },
        {
          text: this.$g("tabs.detail.printLetter"),
          templateType: enumTemplateType.letter,
          permissionCode: pl_code.Communication.PrintLetter,
        },
      ];
      PermissionNavs = PermissionNavs.filter(
        (c) => !this.hideNav.find((d) => d == c.templateType)
      );
      PermissionNavs = PermissionNavs.filter(
        (c) => !this.hiddenNav.find((d) => d == c.templateType)
      );
      let tempAry = handleTabs(PermissionNavs);
      this.navItems = tempAry?.map((item, index) => {
        return {
          ...item,
          orderIndex: index,
        };
      });
      if (this.$route.query.msgType) {
        if (!this.navItems.length) {
          this.navItems = PermissionNavs.filter((_item) => {
            return _item.templateType == this.$route.query.msgType;
          }).map((_item, index) => {
            return {
              ..._item,
              orderIndex: index,
            };
          });
        } else {
          let isExist = this.navItems.some(
            (item) => item.templateType == this.$route.query.msgType
          );
          if (!isExist) {
            let item = PermissionNavs.find(
              (it) => it.templateType == this.$route.query.msgType
            );
            let length = this.navItems.length;
            item.orderIndex = length;
            this.navItems.push(item);
          }
        }

        this.navItems?.forEach((element) => {
          if (element.templateType == this.$route.query.msgType) {
            this.tabindex = element.orderIndex;
            // element.disabled = false
          }
        });
      }
      this.curNav = this.navItems[this.tabindex];
      this.setPhoneTypes(this.curNav.templateType);
      this.initData();
      this.setPermission();
    },
    bindContacts(groupId) {
      if (!this.isBindContacts) {
        this.isBindContacts = true;
        let getId = function (list) {
          return list.id;
        };
        let data = {
          groupId: groupId,
          contactAction: this.contactAction,
          actionIds: this.sendTos.map(getId),
          electionId: this.form.electionId,
          contactOptionIds: this.form.contacts || [],
          type: this.curNav.templateType,
          phoneTypes: this.selectPhoneTypes,
        };
        getSendDataInit(data).then((res) => {
          if (this.showContactOption) this.contacts = res.data.contactOptions;
          if (this.showSendTo) {
            this.buildShowSendToOptions(res);
          }
          this.groupType = res.data.groupType;
          this.contactCount = res.data.count;
          this.contactAction = res.data.contactAction;
          this.isBindContacts = false;
        });
      }
    },
    buildShowSendToOptions(res) {
      this.sendToOptions = [];
      res.data.contactOptions?.forEach((c) => {
        if (c.childrenContactAction != 5 && c.childrenContactAction != 7) {
          this.sendToOptions.push({
            text: c.title,
            value: c.contactId,
            childrenContactAction: c.childrenContactAction,
          });
        } else {
          this.sendToJumpOptions.push({
            text: c.title,
            value: c.contactId,
            childrenContactAction: c.childrenContactAction,
          });
        }
      });
      if (this.sendToJumpOptions.length > 0) {
        this.sendToOptions.unshift({
          text: "Candidate",
          value: "sendToJumpOptions",
        });
      }
    },
    setPersonLanguages(item, msgLanguageDetails) {
      let data = _.cloneDeep(item);
      data?.forEach((c) => {
        c.active = c.isDefault;
        c.isData =
          msgLanguageDetails && msgLanguageDetails.length > 0
            ? msgLanguageDetails.findIndex(
                (d) => c.id == d.languageId && d.subject && d.body
              ) >= 0
            : false;
      });
      this.personLanguages =
        data?.sort((a, b) => b.isDefault - a.isDefault) ?? [];
      this.languageId =
        this.personLanguages?.find((c) => c.isDefault)?.id ?? "";
    },
    async initData() {
      if (this.id) {
        this.getDraftMethod(this.id).then((data) => {
          this.form = data.data;
          if (!this.form.contacts) {
            this.form.contacts = [];
          }
          this.setPersonLanguages(
            data.data.personLanguages,
            data.data.msgLanguageDetails
          );
          this.setTypeFormContentMap(data.data.msgLanguageDetails);
          this.initDataFuncData(data);
          this.form.electionId = this.isEvent ? storeage.getElectionId() : "";
          if (!data.data.schedule) this.form.scheduleTime = null;
          if (data.data.actionIds) {
            getActions({
              contactAction: this.contactAction,
              actionIds: data.data.actionIds,
            }).then((res) => {
              this.sendTos = res.data;
              this.loadingDraft = false;
              this.bindContacts();
            });
          } else {
            this.loadingDraft = false;
            this.bindContacts(this.form.groupId);
          }
          this.$nextTick(() => {
            if (!this.typeFormContent.body) {
              this.typeFormContent.body = "";
            } else {
              if (!this.typeFormContent.body.endsWith("\n")) {
                this.typeFormContent.body = this.typeFormContent.body + "\n";
              }
              this.typeFormContent.body = this.typeFormContent.body.replace(
                /\r\n/g,
                "\n"
              );
            }
            this.form.typeFormContent = this.typeFormContent;
            this.setOrignData(this.form);
          });
        });
      } else {
        this.bindContacts();
        await this.initPersonLanguages();
        this.$nextTick(() => {
          if (!this.typeFormContent.body) {
            this.typeFormContent.body = "";
          }
          this.form.typeFormContent = this.typeFormContent;
          this.setOrignData(this.form);
        });
      }
    },
    async initPersonLanguages() {
      await getPersonLanguages()
        .then((result) => {
          this.setPersonLanguages(result.data);
          this.setTypeFormContentMap();
        })
        .catch((err) => {
          throw err;
        });
    },
    initDataFuncData(data) {
      if (data.data.fileUrl) {
        this.form.file = data.data.fileUrl;
        this.form.fileName = data.data.fileName;
      }
      if (data.data.documents) {
        data.data.documents.forEach((doc) => {
          this.docList.push(doc);
        });
      }
      if (data.data.contactAction) {
        this.contactAction = data.data.contactAction;
      }
      if (data.data.phoneTypes) {
        this.selectPhoneTypes = data.data.phoneTypes;
      }
    },
    async toPreview() {
      this.setIsSaveTemplate(false);
      const groupValidation = await this.$refs.groupValidation.validate();
      const sendToValidation = this.$refs.sendToValidation
        ? (await this.$refs.sendToValidation.validate()).valid
        : true;
      const contactOptionValidation = this.showContactOption
        ? await this.$refs.contactOptionValidation.validate()
        : { valid: true };
      const title = await this.$refs.title.validate();

      const contentIsValid = await this.getContent();
      if (
        groupValidation.valid &&
        contactOptionValidation.valid &&
        title.valid &&
        sendToValidation &&
        contentIsValid
      ) {
        for (let [key, value] of this.typeFormContentNewMap) {
          if (key && value.subject && value.body) {
            this.showPreview = true;
            return;
          }
        }
        if (!this.showPreview) {
          this.$refs.lblMsg.message(
            repResult.faild,
            this.$g("const.confirm.messageContentNull")
          );
        }
        //this.$refs.preview.bindContactDropdown()
      }
      document
        .querySelector('.validateAlert [role="alert"]')
        ?.parentNode?.querySelector("input,select,textarea")
        ?.focus();
    },
    bindGroups() {
      if (this.showAddGroup) {
        getGroupDropdown(this.form.electionId).then((res) => {
          if (res.data.length > 0) {
            this.groups = res.data;
          }
        });
      }
    },
    bindCategories() {
      let data = {
        enumTemplateType: this.curNav.templateType,
        electionId: this.electionId,
      };
      getCategories(data).then((res) => {
        this.categories = res.data || [];
      });
    },
    bindEmailSender() {
      getEmailSender({ electionId: this.form.electionId }).then((res) => {
        if (res.data.length > 0) {
          this.fromEmails = res.data;
        }
      });
    },
    bindPhoneNumbers() {
      getPhoneNumbers({ electionId: this.form.electionId }).then((res) => {
        if (res.data.length > 0) {
          this.phoneNumbers = res.data;
        }
      });
    },
    bindCallerIDs() {
      getCallerIDs({ electionId: this.form.electionId }).then((res) => {
        if (res.data.length > 0) {
          this.callerIDs = res.data;
        }
      });
    },
    bindContactAction() {
      getContactAction().then((res) => {
        if (res.data.length > 0) {
          this.contactActions = res.data;
        }
      });
    },
    bindSendObjects() {
      if (this.contactAction !== enumContactAction.none) {
        //sort
        let communicationIds = [];
        if (
          this.$route.query &&
          (this.$route.query.isCampaignFinance ||
            this.$route.query.isStoreageCommunicationIds)
        ) {
          communicationIds = JSON.parse(storeage.getCommunicationIds());
        } else {
          communicationIds = this.$store.state.user.communicationIds;
        }
        this.sendTos = communicationIds.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        //this.bindContacts();
      }
    },
    saveTemplate() {
      this.form.category = this.$refs.saveTemplateRef.categoryId;
      this.saveTemplateFunc(false);
    },
    onBeforeClose() {
      return this.$refs.saveTemplateRef.checkValid();
    },
    saveTemplateFunc(value) {
      let result = value != null && !value;
      if (result) {
        let opt;
        let formData = new FormData();
        if (this.templateId) {
          formData.append("id", this.templateId);
        }
        formData.append("electionId", this.form.electionId);
        formData.append("subject", this.form.subject);
        formData.append("content", this.form.body);
        formData.append("category", this.form.category);
        formData.append("templateType", this.curNav.templateType);
        if (this.form.electionId) {
          formData.append("electionId", this.form.electionId);
        }
        if (this.form.file && this.form.file.size > 0) {
          formData.append("File", this.form.file);
        }
        formData = this.getTypeFormContentaData(
          formData,
          false,
          "MsgTemplateLanguageDetails"
        );
        if (this.templateId) {
          opt = putTemplate;
        } else {
          opt = addTemplate;
        }
        opt(formData).then((m) => {
          this.$refs.lblMsg.message(m.data.status, m.message);
          if (m.data.status != repResult.faild) {
            this.templateId = m.data.info;
          }
          this.$bvModal.hide("pop-save-template");
          this.$refs.chooseTemplate.refreshTemplateList();
        });
      }
    },
    async saveTemplateClick() {
      this.isSaveTemplate = true;
      if ((await this.$refs.title.validate()).valid) {
        this.$bvModal.show("pop-save-template");
        this.isSaveTemplate = false;
      }
      if (
        this.curNav.templateType === this.enumTemplateType.phone &&
        this.$refs.contentValidation?.validate()?.valid
      ) {
        this.$bvModal.show("pop-save-template");
        this.isSaveTemplate = false;
      }
      this.$refs.title.$el
        ?.querySelector('.validateAlert [role="alert"]')
        ?.parentNode?.querySelector("input,select,textarea")
        ?.focus();
    },
    onTemplateChoose(template) {
      getTemplate(template.id).then((res) => {
        this.templateId = template.id;
        this.buttonKey++;
        this.form.subject = res.data.subject;
        this.form.body = res.data.content;
        this.form.category = res.data.category;
        if (res.data.filePath) {
          this.form.file = res.data.filePath;
          this.form.fileName = res.data.fileName;
          this.form.fileId = res.data.documentId;
        }
        this.setTypeFormContentNewMap(res.data.msgTemplateLanguageDetails);
      });
    },
    onTemplateDelete(templateIds, status, message) {
      this.$refs.lblMsg.message(status, message);
      templateIds.forEach((id) => {
        if (this.templateId == id) {
          this.templateId = "";
        }
      });
    },
    onDocChoose(doc) {
      if (this.curNav.templateType !== enumTemplateType.email) return false;
      if (
        this.typeFormContent.files.find((c) => c.documentId == doc.docmentId)
      ) {
        return;
      }
      let nowDoc = { ...doc };
      delete nowDoc.docmentId;
      nowDoc.name = nowDoc.fileName;
      nowDoc.documentId = doc.docmentId;
      nowDoc.isDocAdd = true;
      delete nowDoc.name;
      this.typeFormContent.files.push(nowDoc);
    },
    onDocDelete(ids, status, message) {
      this.$refs.lblMsg.message(status, message);
      ids.forEach((id) => {
        for (var i = 0; i < this.docList.length; i++) {
          if (this.docList[i].id === id) {
            this.docList.splice(i, 1);
            break;
          }
        }
      });
    },
    onDeleteDoc(doc) {
      this.removeArray(this.docList, doc);
    },
    onEditClick(item) {
      this.$router.push({
        name: "documentTemplateEdit",
        query: {
          id: item.id,
          isView: "0",
          dbtn: "1",
          templateType: this.curNav.templateType,
        },
      });
    },
    navClick(item, lastIndex) {
      if (item.orderIndex !== this.tabindex) {
        let formStr = JSON.stringify(this.curDataFrom);
        let data = JSON.parse(this.originDataString);
        data.groupId = this.form.groupId;
        this.originDataString = JSON.stringify(data);
        if (this.originDataString !== formStr) {
          this.$confirm({
            title: this.$g("const.confirm.confirm"),
            content: this.$g("const.confirm.leaveComfirm"),
            cancelType: "secondary",
            okText: this.$g("const.confirm.leaveComfirmYes"),
            cancelText: this.$g("const.confirm.no"),
          })
            .then((value) => {
              this.navClickFunc(item);
            })
            .catch((value) => {
              this.tabindex = lastIndex;
              this.$refs.navTabs.tabindex = lastIndex;
              this.$refs.observer.reset();
            });
        } else {
          this.navClickFunc(item);
        }
        this.$refs.observer.reset();
      }
    },
    navClickFunc(item) {
      if (this.id) {
        this.setOrignData(this.form);
        this.$router.push({
          name: this.isEvent ? "eleSendMessage" : "sendMessage",
          query: {
            msgType: item.templateType,
            tabindex: item.orderIndex,
          },
        });
      } else {
        this.curNav = item;
        this.buttonKey++;
        this.tabindex = item.orderIndex;
        this.form.body = "";
        this.form.file = "";
        this.form.fileName = "audio";
        this.form.fromEmail = "";
        this.form.isSurvey = false;
        this.form.response1 = "";
        this.form.response2 = "";
        this.form.contacts = [];
        this.contactCount = "";
        this.selectPhoneTypes = [];
        this.templateId = "";
        this.form.subject = "";
        this.setPhoneTypes(item.templateType);
        this.bindCategories();
        this.setPermission();
        this.typeFormContent = _.cloneDeep(this.defaultTypeFormContent);
        this.typeFormContentMap = new Map();
        this.setTypeFormContentNewMap();
        if (!this.typeFormContent) {
          this.typeFormContent = _.cloneDeep(this.defaultTypeFormContent);
        }
        this.onGroupChange(this.form.groupId);
        this.$nextTick(() => {
          if (item.templateType !== enumTemplateType.phone) {
            if (!this.typeFormContent.body) {
              this.typeFormContent.body = "";
            }
            if (!this.typeFormContent.headerId) {
              this.typeFormContent.headerDisplay = "";
            }
            if (!this.typeFormContent.footerId) {
              this.typeFormContent.footerDisplay = "";
            }
            if (this.typeFormContent.subject && this.typeFormContent.body) {
              this.typeFormContent.isData = true;
            } else {
              this.typeFormContent.isData = false;
            }
            this.typeFormContent.languageId = this.languageId;
          }
          this.form.typeFormContent = this.typeFormContent;
          this.setOrignData(this.form);
        });
      }
    },
    setPhoneTypes(templateType) {
      if (templateType == enumTemplateType.sms) {
        this.phoneTypes = [
          {
            value: enumPhoneType.mobilePhone,
            text: this.$g("options.mobilePhone"),
          },
        ];
      } else if (templateType == enumTemplateType.phone) {
        this.phoneTypes = [
          {
            value: enumPhoneType.workPhone,
            text: this.$g("options.workPhone"),
          },
          {
            value: enumPhoneType.mobilePhone,
            text: this.$g("options.mobilePhone"),
          },
          {
            value: enumPhoneType.homePhone,
            text: this.$g("options.homePhone"),
          },
        ];
      }
    },
    onLabelSelect() {
      this.smsForm = null;
      this.$emit("label-select");
    },
    onSmsLabelSelect(form) {
      this.smsForm = form;
      this.$emit("label-select");
    },
    insertHtml(val) {
      if (this.smsForm) {
        this.smsForm.body = (this.smsForm.body ?? "") + val;
      } else {
        this.$refs.sendMessageDetailContent.$refs.messageEditor.insertHtml(val);
      }
    },
    isInArray(arr, value) {
      for (let i of arr) {
        if (value === i) {
          return true;
        }
      }
      return false;
    },
    removeArray(arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === val) {
          arr.splice(i, 1);
          break;
        }
      }
    },
    previewBack() {
      this.showPreview = false;
    },
    convertType(templateType) {
      switch (templateType) {
        case enumTemplateType.email:
          return msgType.email;
        case enumTemplateType.sms:
          return msgType.sms;
        case enumTemplateType.phone:
          return msgType.voice;
        default:
          return templateType;
      }
    },
    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
    showAlert() {
      if (this.$route.params.status) {
        this.$refs.lblMsg.message(
          this.$route.params.status,
          this.$route.params.message
        );
      }
    },
    async isSubjectExits() {
      if (!this.isSaveTemplate) return { data: { status: repResult.success } };
      let para = {};
      para.subject = this.form.subject;
      para.id = this.templateId;
      para.templateType = this.curNav.templateType;
      para.electionId = this.form.electionId;
      let result = null;
      this.isSaveTemplate = false;
      await checkSubjectExits(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    setIsSaveTemplate(data = false) {
      this.isSaveTemplate = data;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .search-template:not(.active) path {
  stroke: #333;
  stroke-width: 1px;
}
</style>
