<template>
  <span>
    <span
      v-if="plaintext"
      class="col-form-label d-block"
    >
      {{ displayValue }}
    </span>
    <div v-else>
      <b-form-select
        class="mr-1 w-auto"
        v-model="hourValue"
        :options="hourOptions"
        @change="onHourChange"
        :aria-label="$g('hour')"
        :tabIndex="tabIndex"
      />
      <b-form-select
        class="mr-1 w-auto"
        v-model="minValue"
        :options="minOptions"
        @change="onMinChange"
        :aria-label="$g('minutes')"
        :tabIndex="tabIndex"
      />
    </div>
  </span>
</template>

<script>
import { BFormTimepicker } from "bootstrap-vue";
import Moment from "moment-timezone";
import storeage from "../../utils/storeage";

const addedProps = {
  plaintext: {
    type: Boolean,
    default(){
      return false;
    },
  },
  isTimeStamp: {
    type: Boolean,
    default(){
      return true;
    },
  },
  useDefault: {
    type: Boolean,
    default(){
      return false;
    },
  },
  isLocalTimeZone: {
    type: Boolean,
    default(){
      return false;
    },
  },
  tabIndex: {
    type: Number,
    default() {
      return 0;
    },
  },
};

const parentProps = Object.assign(
    {},
    BFormTimepicker.options.props,
    addedProps,
);
parentProps.value.type = [String, Number];

export default {
  model: { prop: "value", event: "input" },
  props: {
    ...parentProps,
  },
  data(){
    return {
      displayValue: "",
      hourValue: "",
      minValue: "",
      hourOptions: [],
      minOptions: [],
      year: 1970,
      month: 1,
      day: 1,
      HURANDMIN_FORMAT: "YYYY-MM-DD HH A mm",
      VALUE_FORMAT: "hh:mm A",
      Default_FORMAT: "HH:mm",
      TIMESTAMP_FORMAT: "YYYY-MM-DD hh:mm A",
      INVALID_DATE: "Invalid date",
      timeZone: storeage.getTimeZone(),
      utc19900101: - 2208988800000,
    };
  },
  created(){
    if(this.isLocalTimeZone){
      this.timeZone = Moment.tz.guess();
    }
  },
  methods: {
    setkvalue(){
      if(!this.value || this.value === 0 || this.value == this.utc19900101){
        this.hourValue = null;
        this.minValue = null;
      } else if(this.value !== this.INVALID_DATE){
        let m;
        if(this.isTimeStamp){
          m = Moment.tz(this.value, this.timeZone);
          this.year = m.year();
          this.month = m.month() + 1;
          this.day = m.date();
        } else {
          m = Moment(this.value, this.Default_FORMAT);
        }
        let timeStr = m.format(this.VALUE_FORMAT);
        this.hourValue = timeStr.slice(0, 2) + timeStr.slice(5);
        this.minValue = timeStr.slice(3, 5);
      }
    },
    setDisplayValue(){
      if(this.value){
        let moment;
        if(this.isTimeStamp){
          moment = Moment.tz(this.value, this.timeZone);
        } else {
          moment = Moment(this.value, this.Default_FORMAT);
        }
        this.displayValue = moment.format("hh:mm A");
      } else {
        this.displayValue = "";
      }
    },
    onHourChange(value){
      if(value && !this.minValue){
        this.minValue = "00";
      }
      if(value && this.minValue){
        this.formatHourAndMin();
      } else if(!value && !this.minValue){
        this.$emit("input", null);
      } else {
        this.$emit("input", this.INVALID_DATE);
      }
      this.$emit("onTimeChange");
      this.$emit("change");
    },
    onMinChange(value){
      if(value && this.hourValue){
        this.formatHourAndMin();
      } else if(!value && !this.hourValue){
        this.$emit("input", null);
      } else {
        this.$emit("input", this.INVALID_DATE);
      }
      this.$emit("onTimeChange");
      this.$emit("change");
    },
    formatHourAndMin(){
      let datestr = `${this.year}-${this.month}-${this.day} ${this.hourValue} ${this.minValue}`;
      if(this.isTimeStamp){
        let timestamp = Moment.tz(
            datestr,
            this.HURANDMIN_FORMAT,
            this.timeZone,
        ).valueOf();
        this.$emit("input", timestamp);
      } else {
        let moment = Moment.tz(datestr, this.HURANDMIN_FORMAT, this.timeZone);
        this.$emit("input", moment.format(this.Default_FORMAT));
      }
    },
  },
  mounted(){
    this.hourOptions.push({ value: null, text: "" });
    this.hourOptions.push({ value: "12 AM", text: "12 AM" });
    for (let i = 1; i < 12; i ++) {
      let value = i < 10 ? `0${i} AM` : `${i} AM`;
      this.hourOptions.push({ value: value, text: value });
    }
    this.hourOptions.push({ value: "12 PM", text: "12 PM" });
    for (let i = 1; i < 12; i ++) {
      let value = i < 10 ? `0${i} PM` : `${i} PM`;
      this.hourOptions.push({ value: value, text: value });
    }
    this.minOptions.push({ value: null, text: "" });
    for (let i = 0; i < 60; i ++) {
      let value = i < 10 ? `0${i}` : i;
      this.minOptions.push({ value: value, text: value });
    }
    if(this.plaintext){
      this.setDisplayValue();
    } else {
      this.setkvalue();
    }
  },
  watch: {
    value(){
      if(this.plaintext){
        this.setDisplayValue();
      } else {
        this.setkvalue();
      }
    },
    plaintext(val){
      if(val){
        this.setDisplayValue();
      } else {
        this.setkvalue();
      }
    },
  },
};
</script>
