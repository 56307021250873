<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
          :show-template="showSearchAreaTemplate"
        >
          <KValidationObserver ref="searchObserver">
            <k-form-group label-suffix="search.title">
              <k-form-input v-model="queryParams.subject" />
            </k-form-group>
            <k-form-group label-suffix="search.sendBy">
              <k-form-input v-model="queryParams.sendBy" />
            </k-form-group>
            <k-form-group label-suffix="search.sendRangeFrom">
              <KValidationProvider
                rules="validateDateFormat"
                slim
                name="querySendDateTimeFrom"
                v-slot="{ errors }"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-datepicker v-model="queryParams.sendDateTimeFrom" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group label-suffix="search.sendRangeTo">
              <KValidationProvider
                rules="validateDateFormat"
                slim
                name="querySendDateTimeTo"
                v-slot="{ errors }"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-datepicker v-model="queryParams.sendDateTimeTo" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group label-suffix="search.status">
              <k-multiselect
                :options="statusList"
                v-model="queryParams.status"
              />
            </k-form-group>
          </KValidationObserver>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-export-button
              v-if="isExport"
              :condition-getter="getSearchState"
              :export-handler="exportHandler"
              :check-has-data="checkHasData"
            >
              buttons.export
            </k-export-button>
            <k-button v-show="curTypeIndex !== 1 && isCancel" @click="cancel">
              buttons.cancel
            </k-button>
          </k-button-group>
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp
            :props-wiki-help-code="wikiHelpCode"
            :props-has-wiki-help="!isNotification"
          />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-delete v-if="isDel" @click="delDeliveryResultHandle" />
              <column-config-icon
                :list-id="getPageListId()"
                @column-changed="onColumnChanged"
              />
            </div>
            <status-Nav
              :refresh="navTabClick"
              ref="navTabs"
              :items="navTabItems"
              :current-index="curTabIndex"
              :need-total-count="true"
            />
          </div>
          <div class="content-navTab">
            <status-Nav
              :refresh="navTypeClick"
              ref="navTypes"
              :items="navTypeItems"
              :current-index="curTypeIndex"
              :need-total-count="true"
            />
          </div>

          <div class="content-party">
            <konn-grid
              v-show="!hiddenTable"
              :select-mode="selectMode"
              :has-check-box="hasCheckBox"
              :has-action="hasAction"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @total-changed="onTotalChanged"
              @fields-get="onFieldsGet"
              @edit-clicked="onEditClicked"
              grid-sort-by="sendDateTime"
              :set-paras="setParas"
              :list-id="getPageListId()"
              id-field="id"
              :auto-bind="false"
              :grid-sort-desc="true"
              :sticky-header="true"
              class="stickyTable"
            >
              <template #cell(subject)="row">
                <router-link
                  :to="{
                    name: getMessageDeliveryReportRouteName(),
                    query: {
                      id: row.item.id,
                      isView: '1',
                      dbtn: '0',
                      deliveryType: navTabItems[curTabIndex].type,
                    },
                  }"
                >
                  {{ row.value }}
                </router-link>
              </template>
              <template #cell(showInPortal)="row" v-if="hasShowInPortal">
                <k-form-checkbox
                  v-model="row.value"
                  :value="true"
                  :unchecked-value="false"
                  @change="showInPortalChangeEvent(row.value, row.item.id)"
                  switch
                  v-if="row.item.folder != 128"
                />
                <div v-else />
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDeliveryResultList,
  getDeliveryResultStatusDropdown,
  getSumcount,
  delDeliveryResult,
  exportDeliveryResultList,
  cancelMessage,
  updateShowInPortal,
} from "../../api/messageDeliveryResult";
import statusNav from "../../components/common-nav";
import { repResult } from "../../../../libs/global";
import { enumSearchType } from "../../../../utils/enums/enumSearchType";
import { enumDeliveryResultType } from "../../enums/enumDeliveryResultType";
import { has } from "../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../utils/permission/permission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";
import { DeliveryResults } from "../../mixins/messagePage";
import { getCurPageConfigbyRoutePath } from "../../../../utils/tools";

export default {
  mixins: [permissionCode, DeliveryResults],
  props: {
    hasShowInPortal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideNav: {
      type: Array,
      default() {
        return [];
      },
    },
    hasNavTabItems: {
      type: Array,
      default() {
        return [];
      },
    },
    propApi: {
      type: Object,
      default() {
        return {
          getDeliveryResultList,
          getDeliveryResultStatusDropdown,
          getSumcount,
          delDeliveryResult,
          exportDeliveryResultList,
          cancelMessage,
        };
      },
    },
    pageListId: {
      type: String,
      default: "",
    },
  },
  components: {
    statusNav,
    // ColumnConfigIcon,
  },
  data() {
    let isEvent = this.$route.name.startsWith("ele");
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    return {
      isEvent: isEvent,
      electionId: isEvent ? storeage.getElectionId() : null,
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        subject: "",
        sendBy: "",
        sendDateTimeFrom: "",
        sendDateTimeTo: "",
        status: [],
      },
      statusList: [],
      typeName: isEvent ? "EleDeliveryResult" : "DeliveryResult",
      curTabIndex:
        this.$route.params.tabindex != null
          ? parseInt(this.$route.params.tabindex)
          : 0,
      fields: [],
      navTabItems: [],
      navTypeItems: [],
      curTypeIndex:
        this.$route.params.typeindex != null
          ? parseInt(this.$route.params.typeindex)
          : 0,
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      hasAction: true,
      showThisSearchArea: true,

      categories: [],
      useFors: [],
      seenSearch: true,
      isActive: true,
      initPaymentStatus: [],
      showSearchStatus: true,
      totalCount: 0,
      isExport: false,
      isCancel: false,
      isDel: false,
      hiddenTable: true,
      fromSend:
        this.$route.params.fromSend != null
          ? parseInt(this.$route.params.fromSend)
          : 0,
      wikiHelpCode: null,
      showSearchAreaTemplate: false,
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
    };
  },
  computed: {
    dataSource: function () {
      return this.propApi.getDeliveryResultList;
    },
    exportHandler: function () {
      return this.propApi.exportDeliveryResultList;
    },
  },

  mounted() {
    this.initPage();
  },
  methods: {
    getPageListId() {
      if (this.pageListId) {
        return this.pageListId;
      }
      return this.isEvent
        ? "elemsgdeliveryresultlist"
        : "msgdeliveryresultlist";
    },
    showInPortalChangeEvent(v, id) {
      let query = {
        id: id,
        showInPortal: v,
        electionId: this.isEvent ? storeage.getElectionId() : "",
      };
      updateShowInPortal(query).then((rep) => {
        this.$refs.lblMsg.message(rep.data.status, rep.message);
      });
    },
    initPage() {
      let { isHasEmail, isHasSms, isHasPhone, isHasLetter } =
        this.permissionJudge();
      if (isHasEmail || isHasSms || isHasPhone || isHasLetter) {
        this.initNav();
      } else {
        this.hiddenTable = true;
      }
    },
    setPermission() {
      let { isHasEmailSub, isHasSMSSub, isHasPhoneSub } =
        this.permissionJudge();
      switch (this.navTabItems[this.curTabIndex]?.type) {
        case enumDeliveryResultType.email:
          this.hiddenTable = !isHasEmailSub;
          break;
        case enumDeliveryResultType.sms:
          this.hiddenTable = !isHasSMSSub;
          break;
        case enumDeliveryResultType.phone:
          this.hiddenTable = !isHasPhoneSub;
          break;

        default:
          break;
      }
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.DeliveryResult
        : this.$wikiHelpLinkCodes?.BAU.Communication.DeliveryResult;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.DeliveryResult
          : this.$wikiHelpLinkCodes?.BAU.Notification?.DeliveryResult;
      }
      if (this.isEvent) {
        wikiHelpCodes =
          this.$wikiHelpLinkCodes?.Event.Communication.DeliveryResult;
        switch (this.navTabItems[this.curTabIndex]?.type) {
          case enumDeliveryResultType.email:
            this.isExport = has(
              this.permissionCodeEvent.Communication.EmailResultExport
            );
            this.isCancel = has(
              this.permissionCodeEvent.Communication.EmailResultCancel
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.EmailResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.sms:
            this.isExport = has(
              this.permissionCodeEvent.Communication.SMSResultExport
            );
            this.isCancel = has(
              this.permissionCodeEvent.Communication.SMSResultCancel
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.SMSResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.phone:
            this.isExport = has(
              this.permissionCodeEvent.Communication.PhoneResultExport
            );
            this.isCancel = has(
              this.permissionCodeEvent.Communication.PhoneResultCancel
            );
            this.isDel = has(
              this.permissionCodeEvent.Communication.PhoneResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.letter:
            this.isExport = has(
              this.permissionCodeEvent.Communication.LetterResultExport
            );
            this.isCancel = false;
            this.isDel = has(
              this.permissionCodeEvent.Communication.LetterResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          default:
            break;
        }
      } else {
        switch (this.navTabItems[this.curTabIndex]?.type) {
          case enumDeliveryResultType.email:
            this.isExport = has(
              this.permissionCode.Communication.EmailResultExport
            );
            this.isCancel = has(
              this.permissionCode.Communication.EmailResultCancel
            );
            this.isDel = has(
              this.permissionCode.Communication.EmailResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.sms:
            this.isExport = has(
              this.permissionCode.Communication.SMSResultExport
            );
            this.isCancel = has(
              this.permissionCode.Communication.SMSResultCancel
            );
            this.isDel = has(this.permissionCode.Communication.SMSResultDelete);
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.phone:
            this.isExport = has(
              this.permissionCode.Communication.PhoneResultExport
            );
            this.isCancel = has(
              this.permissionCode.Communication.PhoneResultCancel
            );
            this.isDel = has(
              this.permissionCode.Communication.PhoneResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          case enumDeliveryResultType.letter:
            this.isExport = has(
              this.permissionCode.Communication.LetterResultExport
            );
            this.isCancel = false;
            this.isDel = has(
              this.permissionCode.Communication.LetterResultDelete
            );
            this.wikiHelpCode = wikiHelpCodes?.Email.List;
            break;
          default:
            break;
        }
      }
    },
    cancel() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.cancelMsg")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        this.propApi.cancelMessage(delIds).then((rep) => {
          this.$refs.lblMsg.message(rep.data.status, rep.message);
          if (rep.data.status === 1 || rep.data.status === 4) {
            this.initNav();
          }
        });
      });
    },
    onEditClicked(item) {
      this.$router.push({
        name: this.getMessageDeliveryReportRouteName(),
        query: {
          id: item.id,
          isView: "1",
          dbtn: "0",
          deliveryType: this.navTabItems[this.curTabIndex].type,
        },
      });
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    getSearchState() {
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    bindSearchStatusData() {
      let params = {
        status: this.navTypeItems[this.curTypeIndex]?.type,
        electionId: this.queryParams.electionId,
      };
      if (this.isNotification) {
        this.$set(params, "isNotification", this.isNotification);
      }
      this.propApi.getDeliveryResultStatusDropdown(params).then((data) => {
        this.statusList = data.data;
      });
    },
    delDeliveryResultHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        this.propApi.delDeliveryResult(delIds).then((r) => {
          this.$refs.lblMsg.message(r.data.status, r.message);
          if (r.data.status != repResult.faild) {
            this.initNav();
          }
        });
      });
    },
    filterTabs(items, isEvent) {
      let pl_code = isEvent ? this.permissionCodeEvent : this.permissionCode;
      let filterAry = [
        {
          permissionCode: pl_code.Communication.EmailResult,
          type: enumDeliveryResultType.email,
        },
        {
          permissionCode: pl_code.Communication.SMSResult,
          type: enumDeliveryResultType.sms,
        },
        {
          permissionCode: pl_code.Communication.PhoneResult,
          type: enumDeliveryResultType.phone,
        },
        {
          permissionCode: pl_code.Communication.LetterResult,
          type: enumDeliveryResultType.letter,
        },
      ];

      let tempAry = handleTabs(filterAry);
      if (!tempAry.length) {
        return [];
      }
      return items
        .filter((item) => {
          return (tempAry || []).some((ele) => ele.type === item.type);
        })
        .map((item, index) => {
          return {
            ...item,
            orderIndex: index,
          };
        });
    },
    permissionJudge() {
      let pl_code = this.isEvent
        ? this.permissionCodeEvent
        : this.permissionCode;
      let isHasEmailSub = has([
        pl_code.Communication.EmailResultOutbox,
        pl_code.Communication.EmailResultDelivered,
        pl_code.Communication.EmailResultAll,
      ]);
      let isHasSMSSub = has([
        pl_code.Communication.SMSResultOutbox,
        pl_code.Communication.SMSResultDelivered,
        pl_code.Communication.SMSResultAll,
      ]);
      let isHasPhoneSub = has([
        pl_code.Communication.PhoneResultOutbox,
        pl_code.Communication.PhoneResultDelivered,
        pl_code.Communication.PhoneResultAll,
      ]);
      let isHasLetter = has(pl_code.Communication.LetterResult);
      let isHasEmail = has(pl_code.Communication.EmailResult);
      let isHasSms = has(pl_code.Communication.SMSResult);
      let isHasPhone = has(pl_code.Communication.PhoneResult);
      return {
        isHasEmailSub,
        isHasSMSSub,
        isHasPhoneSub,
        isHasLetter,
        isHasEmail,
        isHasSms,
        isHasPhone,
      };
    },
    filterSubTabs(itemAry) {
      if (!itemAry.length) return [];
      let { isHasEmailSub, isHasSMSSub, isHasPhoneSub } =
        this.permissionJudge();
      return itemAry.filter((item) => {
        switch (item.type) {
          case enumDeliveryResultType.email:
            return isHasEmailSub;
          case enumDeliveryResultType.sms:
            return isHasSMSSub;
          case enumDeliveryResultType.phone:
            return isHasPhoneSub;
          default:
            return true;
        }
      });
    },
    initNav(isSetPage1) {
      this.propApi
        .getSumcount({
          electionId: this.queryParams.electionId,
          isNotification: this.isNotification,
        })
        .then((data) => {
          let items = data.data;
          if (this.hasNavTabItems.length > 0) {
            items = data.data.filter(
              (c) =>
                this.hasNavTabItems.findIndex(
                  (d) => d == c.deliveryResultType
                ) >= 0
            );
          }
          items = items.filter(
            (c) => !this.hiddenNav.find((d) => d == c.deliveryResultType)
          );
          if (items.length) {
            const navArr = [];
            items.forEach(function (item, index) {
              navArr.push({
                text: item.text,
                orderIndex: index,
                total: item.count,
                type: item.deliveryResultType,
                sonCount: item.sonCount,
              });
            });
            this.navTabItems = this.filterTabs(navArr, this.isEvent);
            let { isHasLetter, isHasEmail, isHasSms, isHasPhone } =
              this.permissionJudge();
            if (!isHasEmail && !isHasSms && !isHasPhone && isHasLetter) {
              this.$router.push({
                name: this.getMessageDeliveryLetterListRouteName(),
                query: {
                  tabindex: 0,
                },
              });
              return;
            }
            this.setPermission();
            this.refreshTypeNavCount();
            if (this.fromSend) this.curTypeIndex = 2;
            this.$refs.navTypes.tabindex = this.curTypeIndex;
            this.showSearchAreaTemplate = true;
            this.refreshSearchTemplate();
            if (isSetPage1) {
              this.$refs.selectableTable?.refreshSetPage1();
            } else {
              this.$refs.selectableTable?.refresh();
            }
            this.bindSearchStatusData();
            this.fromSend = 0;
          }
        });
    },
    refreshTypeNavCount() {
      let curNavTab = this.navTabItems[this.curTabIndex].sonCount;
      let pl_code = this.isEvent
        ? this.permissionCodeEvent
        : this.permissionCode;
      let outboxPermissionCode = "";
      let DeliveredCode = "";
      let allCode = "";
      switch (this.navTabItems[this.curTabIndex].type) {
        case enumDeliveryResultType.email:
          outboxPermissionCode = pl_code.Communication.EmailResultOutbox;
          DeliveredCode = pl_code.Communication.EmailResultDelivered;
          allCode = pl_code.Communication.EmailResultAll;
          break;
        case enumDeliveryResultType.sms:
          outboxPermissionCode = pl_code.Communication.SMSResultOutbox;
          DeliveredCode = pl_code.Communication.SMSResultDelivered;
          allCode = pl_code.Communication.SMSResultAll;
          break;
        case enumDeliveryResultType.phone:
          outboxPermissionCode = pl_code.Communication.PhoneResultOutbox;
          DeliveredCode = pl_code.Communication.PhoneResultDelivered;
          allCode = pl_code.Communication.PhoneResultAll;
          break;
        default:
          break;
      }
      let tempAry = handleTabs([
        {
          text: this.$g("outBox"),
          orderIndex: 0,
          total: curNavTab.outBoxCount,
          type: 1,
          permissionCode: outboxPermissionCode,
        },
        {
          text: this.$g("delivered"),
          orderIndex: 1,
          total: curNavTab.deliveredCount,
          type: 2,
          permissionCode: DeliveredCode,
        },
        {
          text: this.$g("all"),
          orderIndex: 2,
          total: curNavTab.allCount,
          type: 0,
          permissionCode: allCode,
        },
      ]);
      this.navTypeItems = tempAry.map((_item, index) => {
        return {
          ..._item,
          orderIndex: index,
        };
      });
    },

    onFieldsGet(fields) {
      this.fields = fields;
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields(); //清除当前选择的字段
      this.$refs.searchArea.clearHandler();
    },

    navTabClick(item) {
      if (item.orderIndex !== this.curTabIndex) {
        if (item.type === enumDeliveryResultType.letter) {
          this.$router.push({
            name: this.getMessageDeliveryLetterListRouteName(),
            query: {
              tabindex: item.orderIndex,
            },
          });
        } else {
          this.curTypeIndex = 0;
          this.curTabIndex = item.orderIndex;
          this.clear();
          this.$refs.selectableTable.clearFields();
          this.initNav(true);
        }
      }
    },

    navTypeClick(item) {
      if (item.orderIndex !== this.curTypeIndex) {
        this.curTypeIndex = item.orderIndex;
        this.clear();
        this.$refs.selectableTable.clearFields();
        this.refreshSearchTemplate();
        this.$refs.selectableTable?.refreshSetPage1();
        this.bindSearchStatusData();
        let curNavTab = this.navTabItems[this.curTabIndex].sonCount;
        this.navTypeItems.forEach((item) => {
          if (item.type == 1) {
            item.total = curNavTab.outBoxCount;
          } else if (item.type == 2) {
            item.total = curNavTab.deliveredCount;
          } else if (item.type == 0) {
            item.total = curNavTab.allCount;
          }
        });
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onTotalChanged(total) {
      this.totalCount = total;
      if (this.navTypeItems.length > 0) {
        this.navTypeItems[this.curTypeIndex].total = total;
      }
    },
    clear() {
      this.queryParams.subject = "";
      this.queryParams.sendBy = "";
      this.queryParams.sendDateTimeFrom = "";
      this.queryParams.sendDateTimeTo = "";
      this.queryParams.status = [];
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras, isSearchArea) {
      if (!this.fromSend) {
        this.curTabIndex =
          isSearchArea || !this.$route?.query?.restore
            ? this.curTabIndex
            : paras.tabindex;
        this.curTypeIndex =
          isSearchArea || !this.$route?.query?.restore
            ? this.curTypeIndex
            : paras.typeindex;
        this.queryParams = {
          ...paras,
          electionId: this.isEvent ? this.electionId : null,
        };
      }
    },

    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      this.queryParams.deliveryResultType =
        this.navTabItems[this.curTabIndex]?.type;
      this.queryParams.deliveryResultStatus =
        this.navTypeItems[this.curTypeIndex]?.type;
      this.queryParams.tabindex = this.curTabIndex;
      this.queryParams.typeindex = this.curTypeIndex;
      if (this.isNotification) {
        this.$set(this.queryParams, "isNotification", this.isNotification);
      }
      return this.queryParams;
    },

    search: function () {
      this.$refs.searchObserver.validate().then((isValid) => {
        if (isValid) {
          this.$refs.selectableTable.pageChange(1);
        }
      });
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },

    getSearchType(msgType) {
      switch (msgType) {
        case 1:
          return enumSearchType.communicationEmailDelivery;
        case 2:
          return enumSearchType.communicationPhoneDelivery;
        case 4:
          return enumSearchType.communicationSMSDelivery;
        case 5:
          return enumSearchType.communicationLetterDelivery;
        default:
          break;
      }
    },
    refreshSearchTemplate() {
      this.$refs.searchArea?.getTemplateListByTemplateType(
        this.getSearchType(this.navTabItems[this.curTabIndex].type)
      );
    },
  },
};
</script>
