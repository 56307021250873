var render = function render(){var _vm=this,_c=_vm._self._c;return _c('detail',{ref:"detail",attrs:{"is-event":_vm.isEvent,"is-plain-text":true,"id":_vm.id,"has-search-category":_vm.hasSearchCategory,"is-notification":_vm.isNotification,"message-template-detail-content-content-editor-required":_vm.MessageTemplateDetailContentSubjectRequired,"message-template-detail-content-subject-required":_vm.MessageTemplateDetailContentSubjectRequired},scopedSlots:_vm._u([{key:"formBar",fn:function({ form }){return [_c('div',[_c('k-button-back',{attrs:{"back-route":_vm.getBackRoute(),"back-query":{
          tabindex: _vm.$route.query.tabindex,
          templateType:_vm.$route.query.templateType
        }}},[_vm._v(" buttons.back ")]),(_vm.isNotification ? (form.isCreator || _vm.isTemplateEdit) : _vm.isTemplateEdit)?_c('k-button',{on:{"click":_vm.onRedirectEdit}},[_vm._v(" buttons.edit ")]):_vm._e(),_c('k-pre-next',{ref:"pn",attrs:{"has-save-next":false}}),(_vm.isTemplateDel)?_c('k-button-delete',{attrs:{"delete-handler":_vm.deleteHandler,"redirect-route-name":_vm.getBackRoute(),"del-id":_vm.id,"redirect-query":{ tabindex: _vm.$route.query.tabindex }},on:{"delete-failed":_vm.deleteFailed}}):_vm._e()],1),_c('div',{staticClass:"nav-search"},[_c('search-bar',{attrs:{"data-source":_vm.searchBarDataSource,"query-keyword":"keywords","search-bar-fomatter":_vm.searchBarFomatter,"sort":"subject","order":"Asc","other-conditions":{
          electionId: _vm.electionId,
          templateType: _vm.$route.query.templateType,
          isNotification: _vm.isNotification,
          ignoreElection: _vm.ignoreElection,
        }},on:{"option-selected":_vm.searchSelected}})],1)]}},{key:"formBarNav",fn:function({ form }){return [_c('k-alert-msg',{ref:"lblMsg"}),_c('template-nav',{ref:"nav",attrs:{"tab-index":_vm.navIndex,"is-plain-text":true,"is-event":_vm.isEvent,"is-notification":_vm.isNotification,"show-edit":_vm.isNotification ? (form.isCreator || _vm.isTemplateEdit) : _vm.isTemplateEdit}})]}},{key:"insertLabel",fn:function(){return [_vm._t("insertLabel")]},proxy:true},{key:"formGroup",fn:function({ form, plaintext, templateType, enumTemplateType }){return [_vm._t("formGroup",null,{"form":form,"plaintext":plaintext,"templateType":templateType,"enumTemplateType":enumTemplateType})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }