// main.js
import Vue from "vue";
import store from "./store";
import { router, App, i18n, googleMapKey, vbMsgBox } from "fe-infrastractures";

import "./plugins";
import "./router/gateway";
import "../node_modules/fe-infrastractures/src/router/googlemap";
// import vbMsgBox from "bootstrap-vue-msgbox";
import permissionDirective from "./directive/permission";
import "./styles/layout/base.scss";

Vue.use(permissionDirective);
Vue.use(vbMsgBox);
Vue.use(googleMapKey);

Vue.directive("focus", {
  inserted: function (el, binding){
    if(!binding.value){
      el.childNodes[0].focus();
    }
  },
});


Vue.directive("lastFocus", {
  inserted: function (el, binding){
    if(!binding.value){
      el.onkeydown = function (e){
        if(!e.shiftKey && e.key == "Tab"){
          e.preventDefault();
        }
      };
    }
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
