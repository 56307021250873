<template>
  <k-button
    v-bind="$props"
    v-on="listeners"
    @click="onClick"
    class="btn rounded-0"
  >
    <slot />
  </k-button>
</template>
<script>
import { BButton } from "bootstrap-vue";

const addedProps = {
  exportHandler: {
    type: Function,
    default: null,
  },
  beforeExport: {
    type: Function,
    default: null,
  },
  conditionGetter: {
    type: Function,
    default: null,
  },
  checkHasData: {
    type: Function,
    default: null,
  },
  exportedCallback: {
    type: Function,
    default: null,
  },
  paramsGetter: {
    type: Function,
    default: null,
  },
};
const btnProps = Object.assign({}, BButton.options.props, addedProps);
export default {
  components: {},
  props: {
    ...btnProps,
  },
  computed: {
    listeners() {
      let { ...params } = this.$listeners;
      return params;
    },
  },
  methods: {
    onClick() {
      if (!this.conditionGetter && !this.paramsGetter) {
        throw new TypeError("please set conditionHandler");
      }
      if (!this.exportHandler) {
        throw new TypeError("please set exportHandler");
      }
      if (this.checkHasData && this.checkHasData()) {
        let expCondition;
        if (this.paramsGetter) {
          expCondition = this.paramsGetter();
        } else if (this.conditionGetter) {
          expCondition = this.$store.getters.exportCondition;
          if (!expCondition) {
            expCondition = this.conditionGetter();
          } else {
            expCondition.SelectedGuidIDs =
              this.conditionGetter().SelectedGuidIDs;
          }
        }
        let canExport = true;
        if (this.beforeExport) {
          canExport = this.beforeExport();
        }
        if (canExport) {
          this.exportHandler(expCondition).then((data) => {
            if (this.exportedCallback) {
              this.exportedCallback(data);
            }
          });
        }
      }
    },
  },
};
</script>
