import http from "../utils/http";
import { storeage } from "../../index";
import globalEnv from "../libs/globalEnv";

export const getSystemInfo = () => {
  return http.get(
    "/api/system_information",
    {},
    { loading: false, baseURL: globalEnv.VUE_APP_API_URL },
  );
};

export const getSysLanguageRes = async data => {
  let config = {
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  };
  if (data && data.sysId) {
    config.systemPATH = "admin";
  }
  let languageRes = storeage.getLanguageRes();
  if (!languageRes[process.env.VUE_APP_SYSTEM_ID]) {
    await http.get("/anon/language", data, config).then(resp => {
      languageRes[process.env.VUE_APP_SYSTEM_ID] = resp.data;
      storeage.setLanguageRes(languageRes);
    });
  }
  return Promise.resolve({
    data: languageRes[process.env.VUE_APP_SYSTEM_ID],
  });
};

export const getSysLanguageList = (data) => {
  return http.get("/anon/language/list", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getSysLanguagePersonList = data => {
  return http.post("/anon/language/personlist", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getSysGeneralInfo = data => {
  return http.get("/anon/general", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getSysMenuList = (data) => {
  return http.get("/anon/system/list", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getSysMoudleLog = (data) => {
  let params = {
    "sysID": process.env.VUE_APP_SYSTEM_ID,
    "routeLogDetail": data
  }
  return http.post("/anon/api-web-log/actions/save-route-log", params, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
    showErrorMessage: false,
    useBody: true,
  });
};

export const saveFeError = (data) => {
  return http.post("/anon/api-web-log/actions/save-fe-error", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
    showErrorMessage: false,
    useBody: true,
  });
};

export const getResetPasswordView = () => {
  return http.get("/api/authorityuser/actions/resetpassword ", null, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
    showErrorMessage: false,
  });
};

export const getUserNameRegex = (data) => {
  return http.get("/anon/username-regex", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getPasswordRegex = (data) => {
  return http.get("/anon/pwd-regex", data, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};

export const getSysParam = name => {
  return http.get(`/anon/getsystemparpam/${name}`, null, {
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};