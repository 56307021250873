<template>
  <KValidationObserver ref="observer" v-slot="valdata" class="content-page">
    <k-form
      @keydown.enter="isPrevent"
      @submit.prevent="onSubmit"
      class="content-form"
    >
      <div class="nav-btn-group">
        <slot name="formBar" v-bind="valdata" />
        <KWikiHelp :props-wiki-help-code="wikiHelpCode" />
      </div>
      <div class="content-main">
        <slot name="formMsg" />
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <Nav
            v-if="showNav"
            ref="nav"
            :tab-index="navIndex"
            :is-plain-text="plaintext"
            :is-event="isEvent"
            :is-system="form.isSystem"
          />
        </div>
        <div class="content-party pt-3">
          <div class="content-group">
            <k-form-group
              label-class="require-mark"
              label-suffix="name"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  remote: isReportTemplateNameUsed,
                  max: 200,
                }"
                v-slot="{ errors }"
                slim
                name="name"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input
                  id="input-code"
                  v-model="form.tempDisplayName"
                  :plaintext="plaintext || form.isSystem"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="reportType"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider v-slot="{ errors }" slim name="reportType">
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input
                  v-if="form.isCustomReport"
                  :value="$g('dataExport')"
                  :plaintext="true"
                />
                <k-form-input
                  v-else
                  :value="$g('cannedReport')"
                  :plaintext="true"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="description"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  max: 2000,
                }"
                v-slot="{ errors }"
                slim
                name="description"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-textarea
                  v-model="form.description"
                  :plaintext="plaintext"
                />
              </KValidationProvider>
            </k-form-group>
          </div>
        </div>
      </div>
    </k-form>
  </KValidationObserver>
</template>

<script>
import {
  checkReportTemplateName,
  getReportTemplateDetail,
} from "../api/report";
import Nav from "../components/template-nav";
import { originDataChangeMixin } from "../../../utils/mixins/mixin";

export default {
  mixins: [originDataChangeMixin],
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      isEvent: isEvent,
      showNav: false,
      form: {
        id: this.$route.query.id,
        tempDisplayName: "",
        updateMethod: 0,
        description: "",
        isCustomReport: false,
        isSystem: false,
      },
      navIndex: 0,
      wikiHelpCode: this.$route.meta.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Report.Template.BasicInfo
        : this.$wikiHelpLinkCodes?.BAU.Report.Template.BasicInfo,
    };
  },
  components: {
    Nav,
  },
  props: {
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    save: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  methods: {
    isPrevent(e) {
      let event = e.srcElement || e.target;
      if (event.nodeName !== "TEXTAREA") {
        e.preventDefault();
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.save(this.form);
      }
    },
    async isReportTemplateNameUsed() {
      let result = null;
      let para = {};
      para.name = this.form.tempDisplayName;
      para.id = this.form.id;
      await checkReportTemplateName(para).then((rep) => {
        result = rep;
      });
      return result;
    },
  },
  mounted() {
    getReportTemplateDetail(this.form.id).then((resp) => {
      if (resp.data) {
        this.form = resp.data;
        this.showNav = true;
      }
      this.setOrignData(this.form);
    });
  },
};
</script>
