<template>
  <KValidationObserver ref="observer" v-slot="valdata" class="content-page">
    <k-form @keydown.enter="isPrevent" @submit.prevent="saveReportWidgetDetial" class="content-form">
      <div class="nav-btn-group">
        <slot name="formBar" v-bind="valdata" />
        <b-btn-group>
          <k-button-back
            :back-route="
              isEvent ? 'eleReport-widget-list' : 'report-widget-list'
            "
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
          <k-button
            v-if="isView"
            @click="goEdit"
            v-permission="
              permissionCode.DashboardConfiguration.ConfigurationEdit
            "
          >
            buttons.edit
          </k-button>
          <k-button v-if="!isView" type="submit">
            buttons.save
          </k-button>
          <k-button v-if="!isView && form.id" @click="goView">
            buttons.view
          </k-button>
        </b-btn-group>
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <Nav ref="nav" :tab-index="navIndex" />
        </div>
        <div class="content-party pt-3">
          <div class="content-group">
            <k-form-group
              label-class="require-mark"
              label-suffix="widgetName"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  max: 200,
                  remote: isNameExits,
                }"
                v-slot="{ errors }"
                slim
                name="widgetName"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input v-model="form.widgetName" :plaintext="isView" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="size"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                slim
                name="size"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.size"
                  :options="sizeTypes"
                  :plaintext="isView"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="widgetType"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <div v-if="form.widgetType != 2">
                <KValidationProvider
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="widgetType"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    v-model="form.widgetType"
                    :options="widgetTypes"
                    :plaintext="isView"
                  />
                </KValidationProvider>
              </div>
              <span v-else class="otherDiv d-flex">
                <KValidationProvider
                  v-if="!isView && form.widgetType != 1"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="widgetType"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    v-model="form.widgetType"
                    :options="widgetTypes"
                    :plaintext="isView"
                    @change="onWidgetTypesChanged"
                  />
                </KValidationProvider>
                <KValidationProvider
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="chartType"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    v-model="form.chartType"
                    :options="chartTypes"
                    :plaintext="isView"
                    @change="onChartTypesChanged"
                  />
                </KValidationProvider>
              </span>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="status"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                slim
                name="status"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.widgetStatus"
                  :options="widgetStatus"
                  :plaintext="isView"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="dataSource"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                slim
                name="dataSource"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-select
                  v-model="form.reportId"
                  :options="dataSource"
                  :plaintext="isView"
                  @change="onDataSourceChanged"
                />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-class="require-mark"
              label-suffix="order"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  required: true,
                  max: 3,
                  numberBetween: { minValue: 1, maxValue: 999 },
                  numeric: true,
                  remote: isOrderExits,
                }"
                v-slot="{ errors }"
                slim
                name="order"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-input v-model="form.order" :plaintext="isView" />
              </KValidationProvider>
            </k-form-group>
            <k-form-group
              label-suffix="description"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <KValidationProvider
                :rules="{
                  max: 2000,
                }"
                v-slot="{ errors }"
                slim
                name="description"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-textarea
                  v-model="form.description"
                  :plaintext="isView"
                />
              </KValidationProvider>
            </k-form-group>
            <span class="d-flex">
              <div :class="{ chart: true, active: form.widgetType != 2 }">
                <Pivot
                  ref="dataRocks"
                  :beforetoolbarcreated="customizeToolbar"
                  :toolbar="true"
                  @ready="onChartTypesChanged"
                />
              </div>
              <GChart
                v-if="form.widgetType === 2"
                class="chart"
                :type="type"
                :data="data"
                :options="options"
                :settings="{ packages: ['corechart', 'table', 'sankey'] }"
              />
            </span>
          </div>
        </div>
      </div>
    </k-form>
  </KValidationObserver>
</template>

<script>
import {
  getSizeTypeList,
  getWidgetStatusList,
  getWidgetTypeList,
  getChartTypeList,
  checkReportWidgetName,
  checkReportWidgetOrder,
  addReportWidget,
  saveReportWidget,
  getReportWidgetDetail,
  getEleReportDropList,
  getBauReportDropList,
} from "../../api/report";
import storeage from "../../../../utils/storeage";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import { getReportURL } from "../../api/report";
import { Pivot } from "vue-webdatarocks";
import { GChart } from "vue-google-charts/legacy";
import "webdatarocks/webdatarocks.googlecharts.js";
import Nav from "../../components/widget-nav";
import { getTemplateList, saveTemplate } from "../../../../api/searchTemplate";
import { originDataChangeMixinOnlyEdit } from "../../../../utils/mixins/mixin";
export default {
  mixins: [originDataChangeMixinOnlyEdit],
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      type: "PieChart",
      rockType: "pie",
      oldReportId: "",
      oldFilePath: "",
      templateType: 419,
      templateName: "",
      navIndex: 0,
      data: [],
      options: {
        title: "",
        curveType: "function",
        legend: { position: "bottom" },
        is3D: false,
      },
      isEvent: isEvent,
      isView: parseInt(this.$route.query.isView ?? "0") === 1,
      form: {
        id: this.$route.query.id,
        widgetName: "",
        reportId: "",
        templateId: "",
        electionId: isEvent ? storeage.getElectionId() : "",
        order: "",
        category: this.$route.query.tabindex
          ? parseInt(this.$route.query.tabindex) + 1
          : 1,
        widgetStatus: "",
        widgetType: "",
        chartType: "",
        size: "",
        isBau: !isEvent,
        filePath: "",
        description: "",
        createBy: "",
      },
      widgetTypes: [],
      widgetStatus: [],
      sizeTypes: [],
      chartTypes: [],
      dataSource: [],
      templateList: [],
      exportType: 1,
      defultOptions: {
        grid: { type: "flat", showTotals: "off", showGrandTotals: "off" },
      },
    };
  },
  components: { Pivot, GChart, Nav },
  props: {},
  computed: {},
  methods: {
    customizeToolbar(toolbar) {
      var tabs = toolbar.getTabs();
      toolbar.getTabs = function () {
        delete tabs[0];
        delete tabs[1];
        delete tabs[2];
        return tabs;
      };
    },
    bindSizeTypeList() {
      getSizeTypeList().then((resp) => {
        this.sizeTypes = resp.data || [];
      });
    },
    bindWidgetStatusList() {
      getWidgetStatusList().then((resp) => {
        this.widgetStatus = resp.data || [];
      });
    },
    bindWidgetTypeList() {
      getWidgetTypeList().then((resp) => {
        this.widgetTypes = resp.data || [];
      });
    },
    bindChartTypeList() {
      getChartTypeList().then((resp) => {
        this.chartTypes = resp.data || [];
      });
    },
    bindReportList() {
      if (this.isEvent) {
        getEleReportDropList(this.form.electionId).then((resp) => {
          this.dataSource = resp.data || [];
        });
      } else {
        getBauReportDropList().then((resp) => {
          this.dataSource = resp.data || [];
        });
      }
    },
    bindListData() {
      this.bindSizeTypeList();
      this.bindWidgetStatusList();
      this.bindWidgetTypeList();
      this.bindChartTypeList();
      this.bindReportList();
    },
    isPrevent(e) {
      let event = e.srcElement || e.target;
      if (event.nodeName !== "TEXTAREA") {
        e.preventDefault();
      }
    },
    goEdit() {
      this.$router.push({
        name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
        query: {
          id: this.$route.query.id,
          isEvent: this.isEvent,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    goView() {
      this.$router.push({
        name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
        query: {
          id: this.$route.query.id,
          isView: 1,
          isEvent: this.isEvent,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    requestData() {
      return {
        reportId: this.form.reportId,
        electionId: this.form.electionId,
        exportType: this.exportType,
      };
    },
    onChartTypesChanged() {
      this.setGoogleChartsType();
      this.$refs.dataRocks.webdatarocks.googlecharts.getData(
        { type: this.rockType },
        this.getData,
        this.getData
      );
    },
    setGoogleChartsType() {
      switch (this.form.chartType) {
        case 1:
          this.type = "AreaChart";
          this.rockType = "area";
          break;
        case 2:
          this.type = "BarChart";
          this.rockType = "bar";
          break;
        case 4:
          this.type = "ColumnChart";
          this.rockType = "column";
          break;
        case 8:
          this.type = "LineChart";
          this.rockType = "line";
          break;
        case 16:
          this.type = "PieChart";
          this.rockType = "pie";
          break;
        case 32:
          this.type = "Sankey";
          this.rockType = "sankey";
          break;
        default:
          this.type = "AreaChart";
          this.rockType = "area";
          break;
      }
    },
    onWidgetTypesChanged() {
      this.$refs.dataRocks.webdatarocks.refresh();
    },
    onDataSourceChanged() {
      if (this.oldReportId === this.form.reportId && this.oldFilePath != "") {
        this.form.filePath = this.oldFilePath;
        this.$refs.dataRocks.webdatarocks.updateData({
          filename: this.form.filePath,
        });
        this.getTemplate();
      } else {
        getReportURL(this.requestData()).then((rep) => {
          this.form.filePath = rep.data;
          this.$refs.dataRocks.webdatarocks.updateData({
            filename: this.form.filePath,
          });
          this.$refs.dataRocks.webdatarocks.googlecharts.getData(
            { type: this.rockType },
            this.getData,
            this.getData
          );
        });
      }
    },
    async isNameExits() {
      let para = {};
      para.id = this.form.id;
      para.category = this.form.category;
      para.isBau = this.form.isBau;
      para.electionid = this.form.electionId;
      para.widgetName = this.form.widgetName;
      let result = null;
      await checkReportWidgetName(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    async isOrderExits() {
      let para = {};
      para.id = this.form.id;
      para.category = this.form.category;
      para.isBau = this.form.isBau;
      para.electionid = this.form.electionId;
      para.order = this.form.order;
      let result = null;
      await checkReportWidgetOrder(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
    async saveReportWidgetDetial() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(async () => {
          this.saveViewTemplate();
        });
      }
    },
    saveWidgetDetial() {
      let api = addReportWidget;
      if (this.$route.query.id) {
        api = saveReportWidget;
      }
      if (this.form.chartType === "") {
        this.form.chartType = 0;
      }
      api(this.form).then((response) => {
        if (response.data.status === repResult.success) {
          this.setOrignData(this.form);
          this.$router.push({
            name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
            query: {
              id: response.data.info.id,
              tabindex: this.$route.query.tabindex,
              isView: 1,
              isEvent: this.isEvent,
            },
            params: {
              message: response.message,
              status: response.data.status,
            },
          });
        } else {
          this.$refs.lblMsg.message(response.data.status, response.message);
        }
      });
    },
    getData(_data) {
      this.data = _data.data;
    },
    getTemplate(isSave) {
      let model = {
        templateType: this.templateType,
        electionid: this.form.electionId,
        reportId: this.form.reportId,
      };
      getTemplateList(model).then((res) => {
        const { data } = res;
        this.templateList = data;
        if (isSave === 1) {
          this.form.templateId = this.templateList[0].id;
          this.saveWidgetDetial();
        } else {
          for (const template of this.templateList) {
            if (template.id == this.form.templateId) {
              var report = JSON.parse(template.content);
              if (report.dataSource) {
                report.dataSource.filename = this.form.filePath;
                this.$refs.dataRocks.webdatarocks.setReport(report);
              }
              break;
            }
          }
        }
      });
    },
    saveViewTemplate() {
      let report = this.$refs.dataRocks.webdatarocks.getReport();
      let data = {
        reportId: this.form.reportId,
        templateType: this.templateType,
        templateName:
          this.form.widgetName +
          "(" +
          this.form.category +
          ")" +
          " Widget" +
          "(" +
          this.form.createBy +
          ")",
        description: "Widget Template",
        content: JSON.stringify(report),
        electionid: this.form.electionId,
        usedInWidget: true,
      };
      if (this.form.templateId) {
        saveTemplate(data, this.form.templateId).then((res) => {
          if (res.data) {
            this.saveWidgetDetial();
          }
        });
      } else {
        saveTemplate(data).then((res) => {
          if (res.data) {
            this.getTemplate(1);
          }
        });
      }
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
  },
  created() {
    this.bindListData();
    if (this.form.id) {
      getReportWidgetDetail(this.form.id).then((resp) => {
        let data = resp.data;
        if (data) {
          this.form.id = data.id;
          this.form.widgetName = data.widgetName;
          this.form.reportId = data.reportId;
          this.oldReportId = data.reportId;
          this.form.templateId = data.templateId;
          this.form.order = data.order;
          this.form.widgetStatus = data.widgetStatus;
          this.form.widgetType = data.widgetType;
          this.form.chartType = data.chartType;
          this.form.size = data.size;
          this.form.filePath = data.filePath;
          this.oldFilePath = data.filePath;
          this.form.description = data.description;
          this.form.createBy = data.createBy;
          this.setGoogleChartsType();
        }
        if (this.form.filePath != "") {
          this.$refs.dataRocks.webdatarocks.updateData({
            filename: this.form.filePath,
          });
          this.$refs.dataRocks.webdatarocks.googlecharts.getData(
            { type: this.rockType },
            this.getData,
            this.getData
          );
          this.getTemplate();
        } else {
          this.onDataSourceChanged();
        }
        this.$nextTick(() => {
          this.setOrignData(this.form);
        });
      });
    } else {
      getReportWidgetDetail("00000000-0000-0000-0000-000000000000").then(
        (resp) => {
          let data = resp.data;
          if (data) {
            this.form.createBy = data.createBy;
          }
          this.$nextTick(() => {
            this.setOrignData(this.form);
          });
        }
      );
    }
  },
  mounted() {
    this.showAlert();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.form.id && this.isDataChanges()) {
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.leaveComfirm"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.leaveComfirmYes"),
        cancelText: this.$g("const.confirm.no"),
      })
        .then(() => {
          next();
        })
        .catch(() => {
          this.navToBack();
          next(false);
        });
    } else {
      next();
    }
    if (!to.query.id) {
      to.meta.title = "add";
    } else {
      to.meta.title =
        to.query.isView == 1
          ? to.meta.isEvent
            ? "eleReportWidgetView"
            : "reportWidgetView"
          : to.meta.isEvent
          ? "eleReportWidgetEdit"
          : "reportWidgetEdit";
    }
  },
  beforeRouteEnter(toEnter, from, next) {
    if (!toEnter.query.id) {
      toEnter.meta.title = "add";
    } else {
      toEnter.meta.title =
        toEnter.query.isView == 1
          ? toEnter.meta.isEvent
            ? "eleReportWidgetView"
            : "reportWidgetView"
          : toEnter.meta.isEvent
          ? "eleReportWidgetEdit"
          : "reportWidgetEdit";
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .otherDiv {
  width: 100%;

  > span {
    flex: auto;
  }

  span:nth-child(2) {
    flex: 0 0 50%;
    margin-left: 5px;
  }
}

.chart {
  flex: 0 0 50%;
  margin-left: 5px;
  height: 500px;
}

.chart.active {
  margin: 0 auto;
  flex: 0 0 50%;
  height: 500px;
}

.google-visualization-tooltip {
  pointer-events: none;
}
</style>
