import { render, staticRenderFns } from "./messageGroupMatchingList.vue?vue&type=template&id=9bf6bd9a&"
import script from "./messageGroupMatchingList.vue?vue&type=script&lang=js&"
export * from "./messageGroupMatchingList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports