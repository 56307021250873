<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="seenSearch">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          :show-template="showSearchAreaTemplate"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          ref="searchArea"
          :fields="fields"
          :show-dynamic="hasSearchAreaDynamic"
        >
          <k-form-group label-suffix="search.title">
            <k-form-input v-model="queryParams.subject" />
          </k-form-group>
          <k-form-group label-suffix="search.useFor" v-if="hasSearchUserFor">
            <k-form-select
              :options="useForsOptions"
              v-model="queryParams.useFor"
            />
          </k-form-group>
          <k-form-group
            label-suffix="search.useFor"
            v-if="hasSearchUserForMultiselect"
          >
            <k-multiselect
              :options="useForsOptions"
              v-model="queryParams.useFor"
              :placeholder="$g('multiselect.pressNnterSelect')"
            />
          </k-form-group>
          <k-form-group label-suffix="search.category" v-if="hasSearchCategory">
            <k-multiselect
              :options="categories"
              v-model="queryParams.category"
              :placeholder="$g('multiselect.pressNnterSelect')"
            />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button
              v-text="$g(getCategoryButtonText)"
              @click="toMessageTemplateCategoryList"
              v-if="hasSearchCategory"
            />
          </k-button-group>
          <button
            class="btn rounded-0 nav-btn-search"
            @click="onToggleSearch"
            :class="{ active: isActive }"
          >
            <b-icon icon="search" />
          </button>
          <KWikiHelp
            :props-wiki-help-code="
              propsWikiHelpCode ? propsWikiHelpCode : wikiHelpCode
            "
          />
        </div>

        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add v-if="templateAdd" @click="addTemplateHandle" />
              <b-icon-delete v-if="templateDel" @click="delTemplateHandle" />
              <column-config-icon
                v-if="hasColumnConfig"
                :list-id="listId"
                @column-changed="onColumnChanged"
              />
            </div>
            <status-Nav
              :refresh="navClick"
              ref="navTabs"
              :items="navItems"
              :current-index="tabindex"
              :need-total-count="true"
            />
          </div>
          <div class="content-party">
            <konn-grid
              :select-mode="selectMode"
              :has-check-box="hasCheckBox"
              :has-action="hasAction"
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              @row-selected="onRowSelected"
              @total-changed="onTotalChanged"
              @fields-get="onFieldsGet"
              grid-sort-by="subject"
              :set-paras="setParas"
              :list-id="listId"
              :auto-bind="false"
              :sticky-header="true"
              class="stickyTable"
              :set-interval-bind-grid-repeat-total="30"
              @items-get="itemsGet"
              actionColWidth="220"
            >
              <template #cell(subject)="row">
                <k-link @click="onViewClick(row.item)" class="mr-1">
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(actions)="row">
                <k-link
                  v-if="
                    isNotification
                      ? templateEdit || row.item.isCreator
                      : templateEdit
                  "
                  @click="onEditClick(row.item)"
                  class="mr-1"
                >
                  {{ $g("edit") }}
                </k-link>
                <k-link
                  @click="onGeneratePDFClick(row.item.id)"
                  class="mr-1"
                  v-if="
                    (curNav.templateType === enumTemplateType.email ||
                      curNav.templateType === enumTemplateType.letter) &&
                    templateGeneratePDFDocument &&
                    hasGeneratePDF
                  "
                >
                  {{ $g("generatePDFDocument") }}
                </k-link>
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
    <k-modal id="pop-generate" size="lg" @ok="generatePDF">
      <template #modal-footer="{ cancel, ok }">
        <k-button variant="primary" @click="ok()"> buttons.save </k-button>
        <k-button @click="cancel()"> buttons.cancel </k-button>
      </template>
      <KValidationObserver ref="observer" class="content-page">
        <div class="content-main">
          <div class="content-navTab">
            <slot name="formBarNav" />
          </div>
          <div class="content-party pt-3">
            <div class="content-group">
              <k-form-group
                label-class="require-mark"
                label-suffix="category"
                label-for="Category"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  slim
                  name="category"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    v-model="doc.category"
                    :options="docCategories"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-class="require-mark"
                label-suffix="fileTitle"
                label-for="FileTitle"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{
                    required: true,
                    max: 200,
                    validateSpecialCharacter: true,
                    remote: isSubjectExits,
                  }"
                  v-slot="{ errors }"
                  slim
                  name="fileTitle"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input v-model="doc.fileTitle" />
                </KValidationProvider>
              </k-form-group>
            </div>
          </div>
        </div>
      </KValidationObserver>
    </k-modal>
  </div>
</template>
<script>
import {
  getUseFor,
  getCategories,
  getTemplateList,
  delTemplate,
  getSumcount,
  generatePDFDocument,
  checkSubjectExits,
} from "../../api/messageTemplate";
import statusNav from "../../components/common-nav";
import { enumTemplateType } from "../../enums/enumTemplateType";
import { repResult } from "../../../../libs/global";
import { saveAlert } from "../../../../utils/saveAlert";
import storeage from "../../../../utils/storeage";
import { enumSearchType } from "../../../../utils/enums/enumSearchType";
import { has } from "../../../../utils/permission/operatePermission";
import { handleTabs } from "../../../../utils/permission/permission";
import { permissionCode } from "../../mixins/permissionCode";
import { getCurPageConfigbyRoutePath } from "../../../../utils/tools";
import KWikiHelp from "../../../wiki-help/wiki-help-link";

export default {
  mixins: [permissionCode],
  props: {
    hideNav: {
      type: Array,
      default() {
        return [];
      },
    },
    hasSearchCategory: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasSearchUserFor: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasSearchUserForMultiselect: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasSearchAreaDynamic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasColumnConfig: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isNotification: {
      type: Boolean,
      default() {
        return false;
      },
    },
    ignoreElection: {
      type: Boolean,
      default(){
        return null;
      },
    },
    propRouteName: {
      type: String,
      default() {
        return "";
      },
    },
    hasGeneratePDF: {
      type: Boolean,
      default() {
        return true;
      },
    },
    useForApi: {
      type: Function,
      default: null,
    },
    propsWikiHelpCode: {
      type: String,
      default: null,
    },
  },
  components: {
    statusNav,
    KWikiHelp,
  },
  data() {
    let isEvent = this.$route.meta.isEvent;
    let curPageConfig = getCurPageConfigbyRoutePath(
      this.$route.meta.project,
      this.$route.path
    );
    return {
      isEvent: isEvent,
      typeName: isEvent ? "eleCommunicationTemplate" : "communicationTemplate",
      queryParams: {
        electionId: isEvent ? storeage.getElectionId() : "",
        subject: "",
        category: [],
        useFor: 0,
        isNotification: this.isNotification,
      },
      tabindex:
        this.$route.query.tabindex != null
          ? parseInt(this.$route.query.tabindex)
          : 0,
      fields: [],
      navItems: [],
      items: [],
      selectMode: "multi",
      selected: [],
      hasCheckBox: true,
      showSearchAreaTemplate: false,
      docCategories: [],
      categories: [],
      useForsOptions: [],
      seenSearch: true,
      isActive: true,
      initPaymentStatus: [],
      curNav: {},
      showSearchStatus: true,
      selectedId: "",
      doc: {},
      enumTemplateType: enumTemplateType,

      templateAdd: false,
      templateEdit: true,
      templateDel: false,
      templateGeneratePDFDocument: true,
      electionIsArchived: storeage.isElectionIsArchived(),
      categoryButtonText: "",
      wikiHelpCode: "",
      hiddenNav: curPageConfig?.templateTabs?.hidNav ?? [],
      isHasCreator: false,
    };
  },

  computed: {
    dataSource: function () {
      return getTemplateList;
    },
    hasAction() {
      let isHasAction = false;
      if (this.isNotification) {
        isHasAction =
          !this.electionIsArchived &&
          (this.templateEdit ||
            this.isHasCreator ||
            ((this.curNav.templateType === enumTemplateType.email ||
              this.curNav.templateType === enumTemplateType.letter) &&
              this.templateGeneratePDFDocument &&
              this.hasGeneratePDF));
      } else {
        isHasAction =
          !this.electionIsArchived &&
          (this.templateEdit ||
            ((this.curNav.templateType === enumTemplateType.email ||
              this.curNav.templateType === enumTemplateType.letter) &&
              this.templateGeneratePDFDocument &&
              this.hasGeneratePDF));
      }
      if (isHasAction && this.$refs.selectableTable?.gridFields) {
        this.$refs.selectableTable?.bindGridFieldsFunc2();
      }
      return isHasAction;
    },
    listId() {
      var pageId = "";
      let templateType = this.$route.query.templateType;
      if (!templateType) {
        templateType = this.curNav.templateType;
      }
      if (templateType) {
        templateType = parseInt(templateType);
      }
      if (this.isEvent) {
        switch (templateType) {
          case enumTemplateType.email:
            pageId = "eletemplatelist";
            break;
          case enumTemplateType.phone:
            pageId = "eletemplatephonelist";
            break;
          case enumTemplateType.sms:
            pageId = "eletemplatesmslist";
            break;
          case enumTemplateType.letter:
            pageId = "eletemplateletterlist";
            break;
          case enumTemplateType.documentation:
            pageId = "eletemplatedoclist";
            break;
          default:
            pageId = "eletemplatelist";
            break;
        }
      } else {
        switch (templateType) {
          case enumTemplateType.email:
            pageId = "templatelist";
            break;
          case enumTemplateType.phone:
            pageId = "templatephonelist";
            break;
          case enumTemplateType.sms:
            pageId = "templatesmslist";
            break;
          case enumTemplateType.letter:
            pageId = "templateletterlist";
            break;
          case enumTemplateType.documentation:
            pageId = "templatedoclist";
            break;
          default:
            pageId = "templatelist";
            break;
        }
      }
      return pageId;
    },
    getCategoryButtonText() {
      let categoryButtonText = "";
      switch (this.curNav.templateType) {
        case enumTemplateType.email:
          categoryButtonText = "email";
          break;
        case enumTemplateType.phone:
          categoryButtonText = "phone";
          break;
        case enumTemplateType.sms:
          categoryButtonText = "sms";
          break;
        case enumTemplateType.letter:
          categoryButtonText = "letter";
          break;
        case enumTemplateType.documentation:
          categoryButtonText = "documentation";
          break;
        default:
          categoryButtonText = "default";
          break;
      }
      return categoryButtonText;
    },
  },

  mounted() {
    this.initNav();
    if (this.hasSearchUserFor || this.hasSearchUserForMultiselect) {
      this.bindUseFors();
    }
    if (this.hasSearchCategory || this.hasGeneratePDF) {
      this.bindDocCategories();
    }
    this.showAlert();
  },

  methods: {
    itemsGet(items) {
      this.isHasCreator = false;
      items.forEach((item) => {
        if (item.isCreator) {
          this.isHasCreator = true;
        }
      });
    },
    toMessageTemplateCategoryList() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateList"
          : "notificationDocumentTemplateList";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateCategoryList"
          : "messageTemplateCategoryList";
      }
      this.$router.push({
        name: routeName,
        query: {
          tabindex: this.curNav.orderIndex,
          templateType: this.curNav.templateType,
        },
      });
    },
    setPermission() {
      if (!this.curNav) {
        this.templateAdd = false;
        this.templateEdit = false;
        this.templateDel = false;
        this.templateGeneratePDFDocument = false;
        return;
      }
      let permissionCode = this.isEvent
        ? this.permissionCodeEvent.Communication
        : this.permissionCode.Communication;
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.Template
        : this.$wikiHelpLinkCodes?.BAU.Communication.Template;
      if (this.isNotification) {
        wikiHelpCodes = this.isEvent
          ? this.$wikiHelpLinkCodes?.Event.Notification?.Template
          : this.$wikiHelpLinkCodes?.BAU.Notification?.Template;
      }
      switch (this.curNav.templateType) {
        case enumTemplateType.email:
          this.templateAdd = has(permissionCode.EmailTemplateAdd);
          this.templateEdit = has(permissionCode.EmailTemplateEdit);
          this.templateDel = has(permissionCode.EmailTemplateDelete);
          this.templateGeneratePDFDocument = has(
            permissionCode.EmailTemplateGeneratePDFDocument
          );
          this.wikiHelpCode = wikiHelpCodes?.Email.List;
          break;
        case enumTemplateType.phone:
          this.templateGeneratePDFDocument = false;
          this.templateAdd = has(permissionCode.PhoneTemplateAdd);
          this.templateEdit = has(permissionCode.PhoneTemplateEdit);
          this.templateDel = has(permissionCode.PhoneTemplateDelete);
          this.wikiHelpCode = wikiHelpCodes?.Phone.List;
          break;
        case enumTemplateType.sms:
          this.templateGeneratePDFDocument = false;
          this.templateAdd = has(permissionCode.SMSTemplateAdd);
          this.templateEdit = has(permissionCode.SMSTemplateEdit);
          this.templateDel = has(permissionCode.SMSTemplateDelete);
          this.wikiHelpCode = wikiHelpCodes?.SMS.List;
          break;
        case enumTemplateType.letter:
          this.templateAdd = has(permissionCode.LetterTemplateAdd);
          this.templateEdit = has(permissionCode.LetterTemplateEdit);
          this.templateDel = has(permissionCode.LetterTemplateDelete);
          this.templateGeneratePDFDocument = has(
            permissionCode.LetterTemplateGeneratePDFDocument
          );
          this.wikiHelpCode = wikiHelpCodes?.Letter.List;
          break;
        case enumTemplateType.documentation:
          break;
        default:
          break;
      }
    },
    generatePDF(bvModalEvent) {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          let data = {
            ...this.doc,
            electionId: this.queryParams.electionId,
            isNotification: this.isNotification,
          };
          generatePDFDocument(data).then((m) => {
            this.$refs.lblMsg.message(m.data.status, m.message);
            this.initNav();
            this.$bvModal.hide("pop-generate");
          });
        }
      });

      bvModalEvent.preventDefault();
    },
    onGeneratePDFClick(id) {
      this.doc = {};
      this.doc.id = id;
      this.$bvModal.show("pop-generate");
    },
    onViewClick(item) {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotificationView" : "notificationView";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateView"
          : "messageTemplateView";
      }
      this.$router.push({
        name: routeName,
        query: {
          id: item.id,
          isView: "1",
          dbtn: "1",
          templateType: this.curNav.templateType,
          tabindex: this.tabindex,
        },
      });
    },
    onEditClick(item) {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotificationEdit" : "notificationEdit";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateEdit"
          : "messageTemplateEdit";
      }
      this.$router.push({
        name: routeName,
        query: {
          id: item.id,
          isView: "0",
          dbtn: "1",
          templateType: this.curNav.templateType,
          tabindex: this.tabindex,
        },
      });
    },
    delTemplateHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        delTemplate(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    addTemplateHandle() {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent ? "eleNotificationAdd" : "notificationAdd";
      } else {
        routeName = this.isEvent
          ? "eleMessageTemplateAdd"
          : "messageTemplateAdd";
      }
      this.$router.push({
        name: routeName,
        query: {
          isView: "0",
          dbtn: "1",
          templateType: this.curNav.templateType,
          hidePreNext: "1",
          tabindex: this.tabindex,
        },
      });
    },
    bindUseFors() {
      let data = {};
      if (this.hasSearchUserForMultiselect) {
        data = { electionId: this.queryParams.electionId };
      }
      let api = getUseFor;
      data = { ...data, templateType: this.curNav.templateType };
      if (this.useForApi) {
        api = this.useForApi;
        data = { ...data, templateType: this.curNav.templateType };
      }
      api(data).then((res) => {
        if (res.data.length > 0) {
          if (this.hasSearchUserFor) {
            res.data[0].text = "All";
          }
          this.useForsOptions = res.data;
        }
      });
    },
    bindCategories() {
      if (!this.hasSearchCategory) {
        return;
      }
      let data = {
        enumTemplateType: this.curNav.templateType,
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      };
      getCategories(data).then((res) => {
        this.categories = res.data || [];
      });
    },
    bindDocCategories() {
      let data = {
        enumTemplateType: enumTemplateType.documentation,
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      };
      getCategories(data).then((res) => {
        if (res.data.length > 0) {
          this.docCategories = res.data;
        }
      });
    },
    filterTabs(items, isEvent) {
      let pl_code = isEvent ? this.permissionCodeEvent : this.permissionCode;
      let filterAry = [
        {
          permissionCode: pl_code.Communication.EmailTemplate,
          templateType: enumTemplateType.email,
        },
        {
          permissionCode: pl_code.Communication.SMSTemplate,
          templateType: enumTemplateType.sms,
        },
        {
          permissionCode: pl_code.Communication.PhoneTemplate,
          templateType: enumTemplateType.phone,
        },
        {
          permissionCode: pl_code.Communication.LetterTemplate,
          templateType: enumTemplateType.letter,
        },
        {
          permissionCode: pl_code.Communication.Documentation,
          templateType: enumTemplateType.documentation,
        },
      ];

      let tempAry = handleTabs(filterAry);
      if (!tempAry.length) {
        return [];
      }
      return items
        .filter((item) => {
          return tempAry.some((ele) => ele.templateType === item.templateType);
        })
        .map((item, index) => {
          return {
            ...item,
            orderIndex: index,
          };
        });
    },
    initNav: function () {
      getSumcount({
        electionId: this.queryParams.electionId,
        isNotification: this.isNotification,
      }).then((data) => {
        let items = data.data.filter(
          (c) => this.hiddenNav.indexOf(c.templateType) < 0
        );
        items = items.filter((c) => this.hideNav.indexOf(c.templateType) < 0);
        if (items.length) {
          const navArr = [];
          let orderIndex = 0;
          items.forEach(function (item) {
            navArr.push({
              text: item.text,
              templateType: item.templateType,
              total: item.count,
              orderIndex: orderIndex++,
            });
          });
          this.navItems = this.filterTabs(navArr, this.isEvent);
          if (
            this.navItems[0] &&
            this.navItems[0].templateType === enumTemplateType.documentation
          ) {
            let routeName = this.initNavGetRouteName();
            this.$router.push({
              name: routeName,
              query: {
                tabindex: this.navItems[0].orderIndex,
              },
            });
            return;
          }
          this.curNav = this.navItems[this.tabindex];
          this.setPermission();
          this.showSearchAreaTemplate = true;
          this.refreshSearchTemplate();
          if (this.$route.query.tabindex != this.tabindex) {
            delete this.$route.query.tabindex;
            delete this.$route.query.restore;
            delete this.$route.query.templateType;
            this.$refs.selectableTable?.refreshSetPage1();
          } else {
            this.$refs.selectableTable?.refresh();
          }
          this.bindCategories();
          //init_UseFor
          if (this.hasSearchUserFor || this.hasSearchUserForMultiselect) {
            this.bindUseFors();
          }
        }
      });
    },
    initNavGetRouteName() {
      if (this.isNotification) {
        return this.isEvent
          ? "eleNotificationDocumentTemplateList"
          : "notificationDocumentTemplateList";
      } else {
        return this.isEvent
          ? "eleDocumentTemplateList"
          : "documentTemplateList";
      }
    },
    onFieldsGet(fields) {
      this.fields = fields;
    },

    onColumnChanged() {
      this.$refs.selectableTable.clearFields();
      this.$refs.searchArea.clearHandler();
    },

    navClick(item) {
      let routeName = "";
      if (this.isNotification) {
        routeName = this.isEvent
          ? "eleNotificationDocumentTemplateList"
          : "notificationDocumentTemplateList";
      } else {
        routeName = this.isEvent
          ? "eleDocumentTemplateList"
          : "documentTemplateList";
      }
      if (item.orderIndex !== this.tabindex) {
        if (item.templateType === enumTemplateType.documentation) {
          this.$router.push({
            name: routeName,
            query: {
              tabindex: item.orderIndex,
            },
          });
        } else {
          this.curNav = item;
          this.tabindex = item.orderIndex;
          this.clear();
          this.$refs.selectableTable.clearFields();
          this.initNav();
        }
      }
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onTotalChanged(total) {
      this.curNav.total = total;
    },
    clear() {
      this.queryParams.subject = "";
      this.queryParams.category = [];
      this.queryParams.useFor = 0;
      this.$refs.searchArea.setDynamicConditions();
    },
    getParas() {
      return {
        query: this.queryParams,
      };
    },

    setParas(paras) {
      this.queryParams = paras;
    },

    getCondition() {
      this.queryParams.dynamicConditions =
        this.$refs.searchArea.getDynamicConditions();
      this.queryParams.templateType = this.curNav.templateType;
      return this.queryParams;
    },

    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },

    onToggleSearch() {
      this.seenSearch = !this.seenSearch;
      this.isActive = !this.isActive;
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status === repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    getSearchType(templateType) {
      switch (templateType) {
        case enumTemplateType.email:
          return this.isNotification
            ? enumSearchType.notificationEmailTemplate
            : enumSearchType.communicationEmailTemplate;
        case enumTemplateType.phone:
          return this.isNotification
            ? enumSearchType.notificationPhoneTemplate
            : enumSearchType.communicationPhoneTemplate;
        case enumTemplateType.sms:
          return this.isNotification
            ? enumSearchType.notificationSMSTemplate
            : enumSearchType.communicationSMSTemplate;
        case enumTemplateType.letter:
          return this.isNotification
            ? enumSearchType.notificationLetterTemplate
            : enumSearchType.communicationLetterTemplate;
        case enumTemplateType.documentation:
          return this.isNotification
            ? enumSearchType.notificationDocumentTemplate
            : enumSearchType.communicationDocumentTemplate;
        default:
          break;
      }
    },
    refreshSearchTemplate() {
      let searchTemplateType = this.getSearchType(this.curNav.templateType);
      this.$refs.searchArea?.getTemplateListByTemplateType(searchTemplateType);
    },
    async isSubjectExits() {
      let para = {};
      para.subject = this.doc.fileTitle;
      para.templateType = enumTemplateType.documentation;
      para.electionId = this.queryParams.electionId;
      para.isNotification = this.isNotification;
      para.ignoreElection = this.ignoreElection;
      let result = null;
      await checkSubjectExits(para).then(function (rep) {
        result = rep;
      });
      return result;
    },
  },
};
</script>
