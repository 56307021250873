<template>
  <div class="content content-shareto">
    <div class="center-bar">
      <div class="content-main">
        <div class="content-navTab">
          <div class="nav-tools"></div>
          <div class="form-inline">
            <k-form-group
              label-suffix="groupName"
              label-for="groupName"
              label-align-md="right"
              label-cols-md="4"
              content-cols-lg="6"
              content-cols-md="7"
            >
              <k-form-input name="groupName" v-model="queryGrid.groupName" />
            </k-form-group>
            <k-button class="ml-4" type="button" @click="refreshGrid"
              >search</k-button
            >
          </div>
        </div>
        <div class="content-party">
          <konn-grid
            :has-action="false"
            ref="selectableTable"
            :dataSource="dataSource"
            :getCondition="getCondition"
            @total-changed="onTotalChanged"
            :fields="fields"
            grid-sort-by="roleName"
            class="stickyTable"
            :hasSearchDefaultTemplate="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroups } from "../api/searchArea";
export default {
  components: {},
  props: {
    sharedGroupId: {
      type: String,
      default: null,
    },
    searchId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "roleName",
          label: "modal.shareTo.addGroups.group",
        },
        {
          key: "departMentName",
          label: "modal.shareTo.addGroups.department",
        },
        {
          key: "userCount",
          label: "modal.shareTo.addGroups.users",
        },
        {
          key: "roleDesc",
          label: "modal.shareTo.addGroups.description",
        },
      ],
      queryGrid: {
        groupName: "",
      },
    };
  },
  computed: {
    dataSource() {
      return getGroups;
    },
  },
  methods: {
    getCondition() {
      return {
        ...this.queryGrid,
        sharedGroupId: this.sharedGroupId,
        searchId: this.searchId,
      };
    },
    onTotalChanged() {},
    onFieldsGet() {},
    getSelectItems() {
      console.log(this.$refs.selectableTable.getSelectedItems());
      return this.$refs.selectableTable.getSelectedItems();
    },
    refreshGrid() {
      return this.$refs.selectableTable.refresh();
    },
  },
};
</script>

<style>
.content-shareto > .center-bar:not(:first-child) {
  margin-top: 10px;
}
.content-shareto > .center-bar > .content-main > .content-party {
  margin-top: 10px;
}
</style>
