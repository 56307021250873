import { render, staticRenderFns } from "./messageLetterDeliveryDetails.vue?vue&type=template&id=d4a5e0f2&scoped=true&"
import script from "./messageLetterDeliveryDetails.vue?vue&type=script&lang=js&"
export * from "./messageLetterDeliveryDetails.vue?vue&type=script&lang=js&"
import style0 from "./messageLetterDeliveryDetails.vue?vue&type=style&index=0&id=d4a5e0f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4a5e0f2",
  null
  
)

export default component.exports