<template>
  <span id="multiselect" ref="multiselect">
    <span
      v-if="plaintext"
      class="col-form-label d-block"
      :style="{
        color: displayColor,
        whiteSpace: isStacked ? 'pre-line' : '',
      }"
    >
      {{ displayText }}
    </span>
    <multiselect
      v-else
      ref="multiselectModel"
      v-model="value"
      @input="onChange"
      :tag-placeholder="tagplaceholder"
      :placeholder="curPlaceholder"
      label="text"
      track-by="value"
      select-label=""
      :hide-selected="multiple ? true : false"
      :options="curOptions"
      :multiple="multiple"
      :taggable="taggable"
      @remove="handleRemove"
      :block-keys="selectLabel ? [] : ['Enter']"
      @tag="handleAddTag"
      :options-limit="optionsLimit"
      @search-change="searchChange"
      @close="multiselectClose"
      @open="multiselectOpen"
      @select="handleSelect"
      :tabindex="tabIndex"
      :openDirection="openDirection"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <div :style="{ color: option.color ? option.color : '#000000' }">
          {{ option.text }}
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div
          :style="{
            color: props.option.color ? props.option.color : '#000000',
          }"
        >
          {{ props.option.text }}
        </div>
      </template>
      <template slot="tag" slot-scope="{ option, remove }">
        <slot name="tagremove" :option="option" :remove="remove" />
      </template>
    </multiselect>
    <span
      tabindex="-1"
      ref="focusTool"
      @keydown.enter.prevent="openMultiselect"
      @focus="focusToolFocus"
      @blur="focusToolBlur"
      style="margin: 0; padding: 0"
      class="multiselect__focusTool"
    ></span>
    <!--focusTool This is needed for the drop-down keyboard event -->
  </span>
</template>
<script>
import multiselect from "vue-multiselect";
import { g } from "../../locale/lang-val";
import { debounce } from "../../utils/tools";

export default {
  components: { multiselect },
  props: {
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    tagplaceholder: {
      type: String,
      default() {
        return g("multiselect.addNewTag");
      },
    },
    placeholder: {
      type: String,
      default() {
        return g("multiselect.searchAddTag");
      },
    },
    selectLabel: {
      type: String,
      default() {
        return g("multiselect.pressNnterSelect");
      },
    },
    taggable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    addTag: {
      type: Function,
      default: function () {
        return null;
      },
    },
    selectValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | String | Object,
      default() {
        return [];
      },
    },
    onItemRemove: {
      type: Function,
      default: function () {
        return null;
      },
    },
    onItemSelect: {
      type: Function,
      default: function () {
        return null;
      },
    },
    isStacked: {
      type: Boolean,
      default() {
        return false;
      },
    },
    optionsLimit: {
      type: Number,
      default() {
        return 1000;
      },
    },
    asynSearchApi: {
      type: Function,
      default: function () {
        return null;
      },
    },
    expandQuery: {
      type: Object,
      default() {
        return null;
      },
    },
    propsMultiselectClose: {
      type: Function,
      default() {
        return null;
      },
    },
    expandOptionsAttributes: {
      type: Array,
      default() {
        return [];
      },
    },
    needWatchValue: {
      type: Boolean,
      default() {
        return true;
      },
    },
    tabIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    openDirection: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      value: [],
      data: [],
      searchText: "",
      curOptions: this.options,
      isInit: false,
      curPlaceholder:
        this.taggable && this.addTag
          ? this.placeholder
          : g("multiselect.pressNnterSelect"),
    };
  },
  model: {
    prop: "selectValue",
    event: "balabala",
  },
  methods: {
    multiselectClose(value, id) {
      this.$emit("blur", this.value, this.selectValue);
      this.handlePopScroll(false);
      if (this.propsMultiselectClose) {
        this.propsMultiselectClose(value, id);
      }
    },
    multiselectOpen(id) {
      this.$emit("open", this.value, this.selectValue);
      this.handlePopScroll(true);
    },
    searchChange(query) {
      if (this.asynSearchApi && this.asynSearchApi.length > 0) {
        this.searchText = query;
        debounce(this.getOptionsList);
      }
    },
    doSearch(query) {
      if (this.asynSearchApi && this.asynSearchApi.length > 0) {
        this.searchText = query;
        debounce(this.getOptionsList);
      }
    },
    getOptionsList() {
      let dataQuery = { searchText: this.searchText };
      if (this.expandQuery) {
        dataQuery = { ...dataQuery, ...this.expandQuery };
      }
      let nowItem = this.value;
      let isNowItem = false;
      this.asynSearchApi(dataQuery).then((res) => {
        this.curOptions = res.data?.map((item, index) => {
          if (!isNowItem && nowItem) {
            isNowItem = nowItem.value == item.value;
          }
          let options = {
            text: item.text,
            value: item.value,
          };
          if (this.expandOptionsAttributes.length > 0) {
            this.expandOptionsAttributes.forEach((e) => {
              this.$set(options, e, e);
              this.$set(options, "$isDisabled", false);
            });
            return options;
          } else {
            return options;
          }
        });
        this.pushNowItem(isNowItem, nowItem);
        this.setValue();
      });
    },
    pushNowItem(isNowItem, nowItem) {
      if (isNowItem) {
        return;
      }
      let typeStr = typeof nowItem;
      if (typeStr == "array" && nowItem.length > 0) {
        this.curOptions.push(...nowItem);
      } else if (typeStr == "object" && nowItem.length > 0) {
        this.curOptions.push(nowItem);
      }
    },
    onChange(val) {
      var tempv = "";
      if (this.multiple) {
        if (this.value) {
          this.value.forEach((value) => {
            tempv += value["value"] + ",";
          });
        }
        if (tempv == "") {
          tempv = [];
          this.$emit("balabala", tempv);
        } else {
          this.$emit(
            "balabala",
            tempv != null ? tempv.replace(/,$/gi, "").split(",") : tempv
          );
        }
        this.$emit("SelectChange", this.value, this.selectValue, val);
        this.$emit("blur", this.value, this.selectValue);
      } else {
        tempv = this.value?.value;
        var stemp = this.getValueIfArray(tempv);
        this.$emit("balabala", stemp);
        this.$emit("SelectChange", this.value?.value, this.selectValue, val);
        this.$emit("blur", this.value, this.selectValue);
      }
    },
    getValueIfArray(val) {
      var tp = {};
      if (Array.isArray(val) && val.length > 0) {
        tp = val[0];
      } else {
        tp = val;
      }
      return tp;
    },
    setValue() {
      var temp = [];
      if (this.multiple) {
        var tempv = this.getValStr();
        if (tempv) {
          this.curOptions.forEach((value) => {
            if (tempv.indexOf("," + value["value"] + ",") >= 0) {
              temp.push(value);
            }
          });
        }
        this.value = temp;
      } else {
        temp = this.getSelectItemByValue();
        this.value = temp;
      }
      var selValues = this.getValueAttr(temp);
      if (
        this.isInit &&
        this.isSelectContentChanged(selValues, this.selectValue)
      ) {
        if (!this.plaintext) {
          if (this.multiple) {
            this.$emit("balabala", selValues);
          } else {
            var tv = this.getValueIfArray(selValues);
            this.$emit("balabala", tv);
          }
        }
      }
    },
    getValueAttr(valObjArray) {
      var va = [];
      if (valObjArray && valObjArray.value !== undefined) {
        var temp = [];
        temp.push(valObjArray);
        valObjArray = temp;
      }
      if (valObjArray && Array.isArray(valObjArray)) {
        valObjArray.forEach((val) => {
          va.push(val["value"]);
        });
      }
      return va;
    },
    isSelectContentChanged(newValue, oldValue) {
      var refreshSelValue = false;
      if (!Array.isArray(newValue) || !Array.isArray(oldValue)) {
        refreshSelValue = true;
      } else if (newValue.length != oldValue.length) {
        refreshSelValue = true;
      } else {
        for (var i = 0; i < newValue.length; i++) {
          if (newValue[i] != oldValue[i]) {
            refreshSelValue = true;
            break;
          }
        }
      }
      return refreshSelValue;
    },
    isSelectListContentChanged(newValue, oldValue) {
      var refreshSelValue = false;
      if (!Array.isArray(newValue) || !Array.isArray(oldValue)) {
        refreshSelValue = true;
      } else if (newValue.length != oldValue.length) {
        refreshSelValue = true;
      } else {
        for (var i = 0; i < newValue.length; i++) {
          if (newValue[i].value != oldValue[i].value) {
            refreshSelValue = true;
            break;
          }
        }
      }
      return refreshSelValue;
    },
    getSelectItemByValue() {
      let ojb = {};
      if (!this.multiple) {
        ojb = this.curOptions.find((value) => {
          return value.value == this.selectValue;
        });
      }
      return ojb;
    },
    getValStr() {
      var tempv = "";
      if (this.selectValue instanceof Array) {
        tempv = this.selectValue.join(",");
      } else {
        tempv = this.selectValue;
      }
      if (tempv != "") {
        tempv = "," + tempv + ",";
      }
      return tempv;
    },
    select() {
      var tempv = "";
      if (this.value) {
        this.value.forEach((value) => {
          tempv += value["value"] + ",";
        });
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.selectValue = tempv.trimRight(",");
      this.$emit("SelectChange", this.value, this.selectValue);
      this.$emit("blur", this.value, this.selectValue);
    },
    removeNull() {
      if (this.curOptions.length >= 1 && this.curOptions[0].value == null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.curOptions.shift();
      }
    },
    handlePopScroll(data) {
      let multiSelect = this.$refs.multiselect.querySelector(".multiselect");
      let modalOpenStatus = multiSelect.closest(".modalOpenStatus");
      if (modalOpenStatus) {
        if (data) {
          let popHeight =
            modalOpenStatus.querySelector(".modalDialog").clientHeight;
          let modalHeight =
            modalOpenStatus.querySelector(".modalContent").clientHeight + 2;
          if (popHeight > modalHeight) {
            modalOpenStatus
              .querySelector(".modalContent")
              .classList.remove("contentScroll");
          }
        } else {
          setTimeout(() => {
            modalOpenStatus
              .querySelector(".modalContent")
              .classList.add("contentScroll");
          }, 300);
        }
      }
    },
    handleAddTag(e, id) {
      if (this.addTag) {
        this.addTag(e, id);
      }
      this.selectedChange();
    },
    handleSelect(e, id) {
      if (this.onItemSelect) {
        this.onItemSelect(e, id);
      }
      this.selectedChange();
    },
    handleRemove(e, id) {
      if (this.onItemRemove) {
        this.onItemRemove(e, id);
      }
      this.selectedChange();
    },
    selectedChange() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.focusTool?.focus();
        }, 100);
      });
    },
    openMultiselect() {
      this.$nextTick(() => {
        this.$refs.multiselectModel?.activate();
      });
    },
    focusToolFocus() {
      this.$refs.focusTool.tabIndex = this.tabIndex;
    },
    focusToolBlur() {
      this.$refs.focusTool.tabIndex = -1;
    },
  },
  computed: {
    displayColor() {
      var texts = "#000000";
      var tempv = this.getValStr();
      if (tempv) {
        this.curOptions.forEach((value) => {
          //数组循环
          if (
            tempv.indexOf("," + value["value"] + ",") >= 0 &&
            value["value"] != null
          ) {
            texts = value["color"];
          }
        });
      }
      return texts;
    },
    displayText() {
      var texts = "";
      var tempv = this.getValStr();
      if (tempv) {
        this.curOptions.forEach((value) => {
          //数组循环
          if (
            tempv.indexOf("," + value["value"] + ",") >= 0 &&
            value["value"] != null
          ) {
            if (this.isStacked) {
              texts += value["text"] + "\r\n";
            } else {
              texts += value["text"] + ", ";
            }
          }
        });
      }
      return texts.replace(/, $/gi, "");
    },
  },
  mounted: function () {
    this.setValue();
  },
  watch: {
    selectValue: function (newval, oldval) {
      if (!this.needWatchValue) {
        return;
      }
      if (this.isSelectContentChanged(newval, oldval)) {
        this.setValue();
      }
    },
    options(val, oldval) {
      if (
        !this.curOptions ||
        this.curOptions.length == 0 ||
        this.isSelectListContentChanged(val, oldval)
      ) {
        this.curOptions = val;
        this.removeNull();
        this.setValue();
        this.isInit = true;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep {
  .multiselect {
    min-height: calc(1.5em + 0.75rem + 2px);
  }

  .multiselect__tags {
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    padding-right: 40px;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 1rem;
    padding-left: 0;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .multiselect__tag {
    background-color: var(--tagsBackground);
    color: var(--tagsFont);
    margin-bottom: 3px;
    border-radius: 0.25rem;
  }

  .multiselect__tag-icon {
    line-height: 24px;
    font-weight: normal;
  }

  .multiselect__tag-icon:after {
    color: inherit;
    opacity: 0.5;
    font-size: 1.2rem;
    text-shadow: 0 1px 0 #fff;
    content: "×";
    font-weight: normal;
    font-family: arial;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: none;

    &:after {
      opacity: 0.75;
      color: inherit !important;
    }
  }

  .multiselect__option--selected,
  .multiselect__option--selected:after {
    background: #ddd;
    color: #333;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #eee;
    color: #333;
  }

  .multiselect__placeholder {
    padding-top: 0;
    margin-bottom: 0;
  }

  .multiselect__select {
    transition: none;
    height: calc(1.5em + 0.75rem);
  }

  .multiselect--active {
    .multiselect__tags {
      border-color: var(--focusStyle);
    }
  }

  .multiselect__select:before {
    top: 70%;
  }

  .multiselect__tags-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
