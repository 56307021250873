var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KValidationObserver',{ref:"observer",staticClass:"content-page",scopedSlots:_vm._u([{key:"default",fn:function(valdata){return [_c('k-form',{staticClass:"content-form",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.saveReportWidgetDetial.apply(null, arguments)}}},[_c('div',{staticClass:"nav-btn-group"},[_vm._t("formBar",null,null,valdata),_c('b-btn-group',[_c('k-button-back',{attrs:{"back-route":_vm.isEvent ? 'eleReport-widget-list' : 'report-widget-list',"back-query":{ tabindex: _vm.$route.query.tabindex }}},[_vm._v(" buttons.back ")]),(_vm.isView)?_c('k-button',{directives:[{name:"permission",rawName:"v-permission",value:(
            _vm.permissionCode.DashboardConfiguration.ConfigurationEdit
          ),expression:"\n            permissionCode.DashboardConfiguration.ConfigurationEdit\n          "}],on:{"click":_vm.goEdit}},[_vm._v(" buttons.edit ")]):_vm._e(),(!_vm.isView)?_c('k-button',{attrs:{"type":"submit"}},[_vm._v(" buttons.save ")]):_vm._e(),(!_vm.isView && _vm.form.id)?_c('k-button',{on:{"click":_vm.goView}},[_vm._v(" buttons.view ")]):_vm._e()],1)],2),_c('div',{staticClass:"content-main"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('div',{staticClass:"content-navTab"},[_c('Nav',{ref:"nav",attrs:{"tab-index":_vm.navIndex}})],1),_c('div',{staticClass:"content-party pt-3"},[_c('div',{staticClass:"content-group"},[_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"widgetName","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
                max: 200,
                remote: _vm.isNameExits,
              },"slim":"","name":"widgetName"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.isView},model:{value:(_vm.form.widgetName),callback:function ($$v) {_vm.$set(_vm.form, "widgetName", $$v)},expression:"form.widgetName"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"size","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
              },"slim":"","name":"size"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.sizeTypes,"plaintext":_vm.isView},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"widgetType","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[(_vm.form.widgetType != 2)?_c('div',[_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                },"slim":"","name":"widgetType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.widgetTypes,"plaintext":_vm.isView},model:{value:(_vm.form.widgetType),callback:function ($$v) {_vm.$set(_vm.form, "widgetType", $$v)},expression:"form.widgetType"}})]}}],null,true)})],1):_c('span',{staticClass:"otherDiv d-flex"},[(!_vm.isView && _vm.form.widgetType != 1)?_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                },"slim":"","name":"widgetType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.widgetTypes,"plaintext":_vm.isView},on:{"change":_vm.onWidgetTypesChanged},model:{value:(_vm.form.widgetType),callback:function ($$v) {_vm.$set(_vm.form, "widgetType", $$v)},expression:"form.widgetType"}})]}}],null,true)}):_vm._e(),_c('KValidationProvider',{attrs:{"rules":{
                  required: true,
                },"slim":"","name":"chartType"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.chartTypes,"plaintext":_vm.isView},on:{"change":_vm.onChartTypesChanged},model:{value:(_vm.form.chartType),callback:function ($$v) {_vm.$set(_vm.form, "chartType", $$v)},expression:"form.chartType"}})]}}],null,true)})],1)]),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"status","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
              },"slim":"","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.widgetStatus,"plaintext":_vm.isView},model:{value:(_vm.form.widgetStatus),callback:function ($$v) {_vm.$set(_vm.form, "widgetStatus", $$v)},expression:"form.widgetStatus"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"dataSource","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
              },"slim":"","name":"dataSource"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.dataSource,"plaintext":_vm.isView},on:{"change":_vm.onDataSourceChanged},model:{value:(_vm.form.reportId),callback:function ($$v) {_vm.$set(_vm.form, "reportId", $$v)},expression:"form.reportId"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"order","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                required: true,
                max: 3,
                numberBetween: { minValue: 1, maxValue: 999 },
                numeric: true,
                remote: _vm.isOrderExits,
              },"slim":"","name":"order"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.isView},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}})]}}],null,true)})],1),_c('k-form-group',{attrs:{"label-suffix":"description","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                max: 2000,
              },"slim":"","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-textarea',{attrs:{"plaintext":_vm.isView},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)})],1),_c('span',{staticClass:"d-flex"},[_c('div',{class:{ chart: true, active: _vm.form.widgetType != 2 }},[_c('Pivot',{ref:"dataRocks",attrs:{"beforetoolbarcreated":_vm.customizeToolbar,"toolbar":true},on:{"ready":_vm.onChartTypesChanged}})],1),(_vm.form.widgetType === 2)?_c('GChart',{staticClass:"chart",attrs:{"type":_vm.type,"data":_vm.data,"options":_vm.options,"settings":{ packages: ['corechart', 'table', 'sankey'] }}}):_vm._e()],1)],1)])],1)])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }