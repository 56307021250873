<template>
  <span>
    <span v-if="plaintext" class="col-form-label d-block">
      {{ displayValue }}
    </span>
    <b-input-group v-else :class="className">
      <b-form-input
        @keydown.enter.prevent
        v-model="kInputValue"
        type="text"
        autocomplete="off"
        :placeholder="dateformat"
        :disabled="disabled"
        @blur="handleBlur"
        :size="size"
        @input="inputChange"
        :aria-label="ariaLabel"
        :tabindex="tabIndex"
      />
      <b-input-group-append>
        <b-form-datepicker
          ref="datepicker"
          v-bind="_props"
          v-model="kvalue"
          button-only
          right
          :locale="klocale"
          :readonly="readonly"
          :disabled="disabled"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          @input="kvalueInput"
          :size="size"
          class="wcagBtn"
          @shown="shown(true)"
          @hidden="shown(false)"
          :tabindex="tabIndex"
        >
          <template #button-content>
            <b-icon icon="calendar" />
            <span style="display: none">wcag</span>
          </template>
        </b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </span>
</template>

<script>
import { BFormDatepicker } from "bootstrap-vue";
import Moment from "moment-timezone";
import storeage from "../../utils/storeage";

const addedProps = {
  plaintext: {
    type: Boolean,
    default() {
      return false;
    },
  },
  isTimeStamp: {
    type: Boolean,
    default() {
      return true;
    },
  },
  readonly: {
    type: Boolean,
    default() {
      return false;
    },
  },
  disabled: {
    type: Boolean,
    default() {
      return false;
    },
  },
  size: {
    type: String,
    default() {
      return "";
    },
  },
  className: {
    type: String,
    default() {
      return "datepickerBottom";
    },
  },
  ariaLabel: {
    type: String,
    default() {
      return "datepicker input";
    },
  },
  popDateformat: {
    type: String,
    default() {
      return storeage.getDateFormat();
    },
  },
  tabIndex: {
    type: Number,
    default() {
      return 0;
    },
  },
};
const parentProps = Object.assign(
  {},
  BFormDatepicker.options.props,
  addedProps
);
parentProps.value.type = [String, Date, Number];

export default {
  model: { prop: "value", event: "input" },
  props: {
    ...parentProps,
  },
  data() {
    return {
      displayValue: "",
      kvalue: "",
      kInputValue: "",
      klocale: "en",
      INVALID_DATE: "Invalid date",
      KVALUE_FOMATE: "YYYY-MM-DD",
      utc19900101: -2208988800000,
      dateformat: this.popDateformat
        ? this.popDateformat
        : storeage.getDateFormat(),
      timeZone: storeage.getTimeZone(),
    };
  },
  methods: {
    getDisplayValue() {
      if (this.value && this.value !== this.utc19900101) {
        let moment;
        if (this.isTimeStamp) {
          moment = Moment.tz(this.value, this.timeZone);
        } else {
          moment = Moment(this.value);
        }
        this.displayValue = moment?.format(this.dateformat);
      }
    },
    setInputAndKvalue() {
      if (!this.value) {
        this.kvalue = "";
        this.kInputValue = "";
      } else if (this.value !== this.INVALID_DATE) {
        if (this.isTimeStamp) {
          if (this.value === this.utc19900101) {
            this.kvalue = "";
            this.kInputValue = "";
          } else {
            let moment = Moment.tz(this.value, this.timeZone);
            this.kvalue = moment.format(this.KVALUE_FOMATE);
            this.kInputValue = moment.format(this.dateformat);
          }
        } else {
          let moment = Moment(this.value, this.dateformat);
          this.kvalue = moment.format(this.KVALUE_FOMATE);
          this.kInputValue = moment.format(this.dateformat);
        }
      }
    },

    kvalueInput(value) {
      this.kInputValue = Moment(value, this.KVALUE_FOMATE).format(
        this.dateformat
      );
      if (this.isTimeStamp) {
        let timevalue = Moment.tz(
          value,
          this.KVALUE_FOMATE,
          this.timeZone
        ).valueOf();
        let sm = Moment.tz(
          "1900-01-01",
          this.KVALUE_FOMATE,
          this.timeZone
        ).valueOf();
        if (timevalue > sm) {
          this.$emit("input", timevalue);
        } else {
          this.kvalue = "";
          this.$emit("input", this.INVALID_DATE);
        }
      } else {
        this.$emit("input", this.kInputValue);
      }

      this.$emit("change");
    },
    inputChange() {
      console.log(this.kInputValue);
      if (!this.kInputValue) {
        if (this.isTimeStamp) {
          this.$emit("input", this.utc19900101);
        } else {
          this.$emit("input", null);
        }
      }
    },
    handleBlur() {
      if (this.kInputValue) {
        let date = this.INVALID_DATE;
        let result = this.formatDate(this.kInputValue);
        if (result !== this.INVALID_DATE) {
          date = Moment(result, this.dateformat).format("YYYY-MM-DD");
        }
        if (date === this.INVALID_DATE) {
          this.kvalue = "";
          this.$emit("input", this.INVALID_DATE);
        } else {
          this.kvalue = date;
          this.kvalueInput(date);
        }
      } else {
        if (this.isTimeStamp) {
          this.$emit("input", this.utc19900101);
        } else {
          this.$emit("input", null);
        }
      }
      this.$emit("blur");
    },
    clearKInputs() {
      this.kvalue = "";
      this.kInputValue = "";
    },
    valueTrim(value) {
      let result = value;
      if (value && typeof value === "string") {
        result = value.trim();
      }
      return result;
    },
    formatDate(value) {
      value = this.valueTrim(value);
      let format1 = /^(\d{8})$/,
        format2 = /^(\d{6})$/,
        format3 = /^\d\d?\/\d\d?\/\d{4}$/,
        format4 = /^\d\d?\/\d\d?\/\d\d$/,
        format5 = /^(\d{2})(\d{2})\/(\d{4})$/,
        format6 = /^(\d{2})\/(\d{2})(\d{4})$/,
        format7 = /^(\d{2})\/(\d{2})(\d{2})$/,
        format8 = /^(\d{2})(\d{2})\/(\d{2})$/;
      switch (true) {
        case format1.test(value):
          value =
            value.substring(0, 2) +
            "/" +
            value.substring(2, 4) +
            "/" +
            value.substring(4, 8);
          break;
        case format2.test(value):
          // eslint-disable-next-line no-case-declarations
          let year2 = this.parseTwoDigitYear(value.substring(4, 6));
          value =
            value.substring(0, 2) + "/" + value.substring(2, 4) + "/" + year2;
          break;
        case format3.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates3 = value.split("/");
          if (dates3[0].length === 1) {
            dates3[0] = "0" + dates3[0];
          }
          if (dates3[1].length === 1) {
            dates3[1] = "0" + dates3[1];
          }
          value = dates3.join("/");
          break;
        case format4.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates4 = value.split("/");
          if (dates4[0].length === 1) {
            dates4[0] = "0" + dates4[0];
          }
          if (dates4[1].length === 1) {
            dates4[1] = "0" + dates4[1];
          }
          dates4[2] = this.parseTwoDigitYear(dates4[2]);
          value = dates4.join("/");
          break;
        case format5.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates5 = value.split("/");
          value =
            dates5[0].substring(0, 2) +
            "/" +
            dates5[0].substring(2, 4) +
            "/" +
            dates5[1];
          break;
        case format6.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates6 = value.split("/");
          value =
            dates6[0] +
            "/" +
            dates6[1].substring(0, 2) +
            "/" +
            dates6[1].substring(2, 6);
          break;
        case format7.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates7 = value.split("/");
          // eslint-disable-next-line no-case-declarations
          let year7 = this.parseTwoDigitYear(dates7[1].substring(2, 4));
          value = dates7[0] + "/" + dates7[1].substring(0, 2) + "/" + year7;
          break;
        case format8.test(value):
          // eslint-disable-next-line no-case-declarations
          let dates8 = value.split("/");
          // eslint-disable-next-line no-case-declarations
          let year8 = this.parseTwoDigitYear(dates8[1]);
          value =
            dates8[0].substring(0, 2) +
            "/" +
            dates8[0].substring(2, 4) +
            "/" +
            year8;
          break;
        default:
          value = this.INVALID_DATE;
          break;
      }
      return value;
    },
    parseTwoDigitYear(input) {
      let myDate = new Date();
      let curYear = myDate.getFullYear().toString().substr(2, 2);
      return (
        parseInt(input) + (parseInt(input) > parseInt(curYear) ? 1900 : 2000)
      );
    },
    shown(data) {
      let modalOpenStatus = this.$el.closest(".modalOpenStatus");
      if (modalOpenStatus) {
        if (data) {
          let popHeight =
            modalOpenStatus.querySelector(".modalDialog").clientHeight;
          let modalHeight =
            modalOpenStatus.querySelector(".modalContent").clientHeight + 2;
          if (popHeight > modalHeight) {
            modalOpenStatus
              .querySelector(".modalContent")
              .classList.remove("contentScroll");
          }
        } else {
          setTimeout(() => {
            modalOpenStatus
              .querySelector(".modalContent")
              .classList.add("contentScroll");
          }, 100);
        }
      }
    },
  },
  mounted() {
    if (this.locale !== undefined) this.klocale = this.locale;
    if (!this.plaintext) {
      this.setInputAndKvalue();
    } else {
      this.getDisplayValue();
    }
    this.$emit("mounted");
    if(this.$refs.datepicker) {
      this.$refs.datepicker.$el.querySelector("button").tabIndex = this.tabIndex;
    }
  },
  watch: {
    value() {
      if (!this.plaintext) {
        this.setInputAndKvalue();
      } else {
        this.getDisplayValue();
      }
    },
    plaintext(val) {
      if (!val) {
        this.setInputAndKvalue();
      } else {
        this.getDisplayValue();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search-container .datepickerBottom {
  margin-bottom: 1rem;
}
</style>
