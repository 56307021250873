<template>
  <ValidationProvider
    :mode="modelType"
    v-bind="props"
    v-slot="{ errors }"
    ref="vProvider"
    class="validateAlert"
    :debounce="mDebounce"
  >
    <slot :errors="errors" />
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";

const providerProps = Object.assign({}, ValidationProvider.options.props);
export default {
  components: {
    ValidationProvider,
  },
  props: {
    ...providerProps,
  },
  data() {
    let result = "aggressive";
    if (!this.isDateRules(this.$props.rules)) {
      if (
        this.$props.mode == "passive" ||
        this.$props.mode == "lazy" ||
        this.$props.mode == "aggressive"
      ) {
        result = this.$props.mode;
      } else {
        result = this.custom;
      }
    }
    return {
      modelType: result,
      mDebounce: this.debounce,
    };
  },
  computed: {
    props() {
      let { mode, ...params } = this.$props;
      return Object.assign({}, params);
    },
  },
  mounted() {
    if (this.mDebounce == 0) {
      this.hasModalRef(this.$refs.vProvider);
    }
  },
  methods: {
    hasModalRef(ref) {
      if (ref.$parent) {
        if (ref.$parent.$refs && ref.$parent.$refs.mdPop) {
          this.mDebounce = 200;
        } else {
          this.hasModalRef(ref.$parent);
        }
      }
    },
    custom(context) {
      if (this.rules) {
        return {
          on: ["blur", "change"],
        };
      }
    },
    validate() {
      return this.$refs.vProvider.validate();
    },
    isDateRules(rules) {
      let result = false;
      switch (true) {
        case !rules:
          result = true;
          break;
        // eslint-disable-next-line no-prototype-builtins
        case rules.hasOwnProperty("isLazy"):
          result = !rules.isLazy;
          break;
        case typeof rules === "object":
          for (const [key] of Object.entries(rules)) {
            let tempStr = key?.replace("validate", "");
            if (/date|time/gi.test(tempStr)) {
              result = true;
            }
          }
          break;
        default:
          if (/date|time/gi.test(rules.replace("validate", ""))) {
            result = true;
          }
          break;
      }
      return result;
    },
  },
};
</script>
