<template>
  <div>
    <KValidationObserver ref="saveTemplateObserver" class="content-page">
      <div class="require-mark mb-3">
        {{ $g("selectCategory") }}
      </div>
      <KValidationProvider
        :rules="{ required: true }"
        v-slot="{ errors }"
        slim
        :name="$g('category')"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="categoryId"
          :options="categories"
          class="mb-3"
        />
      </KValidationProvider>
    </KValidationObserver>
  </div>
</template>

<script>
import { getCategories } from "../../../api/messageTemplate";

export default {
  props: {
    templateType: {
      type: Number,
      default() {
        return 0;
      },
    },
    electionId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      categories: {},
      categoryId: "",
    };
  },
  methods: {
    async checkValid() {
      const isValid = await this.$refs.saveTemplateObserver.validate();
      if (isValid) {
        let dialogCheck = await this.$bvModal.msgBoxConfirm(
          this.$g("const.confirm.save"),
          {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          }
        );
        return Promise.resolve(dialogCheck != null && !dialogCheck);
      } else {
        return Promise.resolve(false);
      }
    },
    getOptions() {
      let data = {
        enumTemplateType: this.templateType,
        electionId: this.electionId,
      };
      getCategories(data).then((res) => {
        this.categories = res.data || [];
      });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>
