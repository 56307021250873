<template>
  <b-form
    ref="form"
    v-bind="props"
    v-on="listeners"
    @keydown.enter="isPreventFormSubmit"
  >
    <slot />
  </b-form>
</template>
<script>
import { BForm } from "bootstrap-vue";
const addedProps = {
  isPublicKeyDown: {
    type: Boolean,
    default() {
      return true;
    },
  },
};
let objProps = Object.assign({}, BForm.options.props, addedProps);
export default {
  components: { BForm },
  props: {
    ...objProps,
  },
  computed: {
    props() {
      let { isPublicKeyDown, ...params } = this.$props;
      return params;
    },
    listeners() {
      if (this.$listeners.hasOwnProperty("keydown") && this.isPublicKeyDown) {
        delete this.$listeners.keydown;
      }
      return this.$listeners;
    },
  },
  data() {
    return {
      timeout: true, //Prevent double submission
    };
  },
  methods: {
    isPreventFormSubmit(e) {
      if (this.isPublicKeyDown) {
        if (!this.timeout) {
          e.preventDefault();
        }
        let event = e.srcElement || e.target;
        // The following elements focus on prohibit submission
        // pageInput is Table paging
        if (
          (event.type == "checkbox" &&
            event.classList.contains("custom-control-input")) ||
          (event.type == "text" && event.classList.contains("pageInput")) ||
          event.classList.contains("prevent-form") || 
          (event.role == "searchbox" && event.closest(".searchBarInput")) 
        ) {
          e.preventDefault();
        }
        //Prevent double submission
        setTimeout(() => {
          this.timeout = true;
        }, 500);
        this.timeout = false;
      }
    },
    getElementsByClassName(e) {
      return this.$refs.form.getElementsByClassName(e);
    },
  },
};
</script>