<template>
  <k-breadcrumb>
    <b-breadcrumb-item
      :to="isSystemNested ? '' : homePath"
      :disabled="isSystemNested"
    >
      <b-icon icon="house-fill" />
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-for="item in routeName"
      :key="item.id"
      :to="item.to"
      :active="item.active"
    >
      {{ item.text }}
    </b-breadcrumb-item>
  </k-breadcrumb>
</template>

<script>
import { g } from "../../locale/lang-val";
import globalEnv from "../../libs/globalEnv";

export default {
  props: {
    homePath: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      routeName: [],
      isSystemNested: globalEnv.VUE_APP_SYSTEM_Nested ?? false,
    };
  },
  created: function () {
    this.getBreadcrumbs();
  },
  methods: {
    getBreadcrumbs() {
      let routeArry = this.$route.matched;
      let i = 1;

      if (routeArry.length <= 1) {
        i = 0;
      }
      for (i; i < routeArry.length; i++) {
        let breadListArry;
        let thisRoute = routeArry[i].meta.localRoute ? routeArry[i].meta.localRoute :routeArry[i].path;
        breadListArry = {
          text: routeArry[i].meta.isLocal ? routeArry[i].meta.title : g(`breadcrumb.${routeArry[i].meta.title}`),
          to: thisRoute,
          route: thisRoute,
        };
        if (i === routeArry.length - 1) {
          breadListArry.active = true;
        }

        this.routeName.push(breadListArry);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
a.disabled .b-icon.bi {
  cursor: auto;
  color: var(--breadcrumbIconFont);
}
</style>
