
export const PW_Worker_Portal_Code = {
    LogOut: "PW_Worker_Portal-LogOut",
    CompaignCode: {
        Message: "PW_Worker_Portal-Message"
    },
    Home: {
        View: "PW_Worker_Portal-Home",
        Apply: "PW_Worker_Portal-Home-Apply",
    },
    RequiredDocuments: {
        RequiredDocuments: "PW_worker-Portal-Required-Documentation-Processing",
        Submit: "PW_worker-Portal-Required-Documentation-Processing-Submit",
        Amend: "PW_worker-Portal-Required-Documentation-Processing-Amend",
        View: "PW_worker-Portal-Required-Documentation-Processing-View",
        ViewRejectionReason: "PW_worker-Portal-Required-Documentation-Processing-ViewRejectionReason"
    },
    WorkAssignments: {
        View: "PW_Worker_Portal-WorkAssignments",
        AssignPosition: "PW_Worker_Portal-WorkAssignments-AssignPosition",
        AssignWork: "PW_Worker_Portal-WorkAssignments-AssignWork",
        Accept: "PW_Worker_Portal-WorkAssignments-Accept",
        Decline: "PW_Worker_Portal-WorkAssignments-Decline",
        Withdraw: "PW_Worker_Portal-WorkAssignments-Withdraw",
        SendAMessage: "PW_Worker_Portal-WorkAssignments-SendAMessage",
        ViewMoreInformation: "PW_Worker_Portal-WorkAssignments-ViewMoreInformation",
        ViewFacilitiesAccessibilityPicture: "PW_Worker_Portal-WorkAssignments-ViewFacilitiesAccessibilityPicture",
        PaymentAmount: "PW_Worker_Portal-WorkAssignments-PaymentAmount"
    },
    Training: {
        View: "PW_Worker_Portal-Training",
        SignUp: "PW_Worker_Portal-Training-SignUp",
        Reschedule: "PW_Worker_Portal-Training-Reschedule",
        Accept: "PW_Worker_Portal-Training-Accept",
        Decline: "PW_Worker_Portal-Training-Decline",
        Withdraw: "PW_Worker_Portal-Training-Withdraw",
        CanSelectOptionalClass: "PW_Worker_Portal-Training-CanSelectOptionalClass",
        ViewCourseInfo: "PW_Worker_Portal-Training-ViewCourseInfo"
    },
    TrainingMaterials: {
        List: "PW_Worker_Portal-Material",
    },
    TrainingOrHistory: {
        View: "PW_Worker_Portal-Training-History",
        Worker: "PW_Worker_Portal-Worker-History"
    },
    Payment: {
        view: "PW_Worker_Portal-Payment"
    },
    MyProfile: {
        view: "PW_Worker_Portal-My-Profile",
        BasicInformation: "PW_Worker_Portal-BasicInformation",
        ChangePassword: "PW_Worker_Portal-ChangePassword"
    }
}

export const PL_NAV_KEY = "Pl_Nav_Key";

export default {
    PW_Worker_Portal_Code
}
