<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="right-bar" v-show="!isTemplatePage && seen">
        <b-icon icon="x" @click="onToggleSearch" />
        <search-area
          ref="searchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getCondition"
          :set-paras="setParas"
          :fields="fields"
          :show-dynamic="false"
          :other-type-name-condition="otherTypeNameCondition"
          :show-template="showSearchAreaTemplate"
        >
          <k-form-group label-suffix="search.title">
            <k-form-input v-model="queryParams.title" />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button
              @click="categoryClick"
              v-permission="permissionCode.Report.Category"
            >
              buttons.category
            </k-button>
            <k-button
              v-if="!isTemplatePage"
              @click="changeCategoryClick"
              v-permission="permissionCode.Report.ChangeCategory"
            >
              buttons.changeCategory
            </k-button>
          </k-button-group>
          <b-icon-search-area
            v-if="!isTemplatePage"
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
          <KWikiHelp :props-wiki-help-code="permissionCode.Report.View" />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add
                @click="addReportHandle"
                v-if="!isTemplatePage"
                v-permission="permissionCode.Report.Add"
              />
              <b-icon-delete
                v-if="
                  $has(
                    isTemplatePage
                      ? permissionCode.Report.TemplateDelete
                      : permissionCode.Report.Delete
                  )
                "
                @click="delReportHandle"
              />
            </div>
            <div>
              <nav-tab
                ref="navTabs"
                :refresh="navClick"
                :items="navItems"
                :need-total-count="true"
              />
            </div>
          </div>
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              select-mode="multi"
              :has-check-box="true"
              :has-action="
                $has(
                  isTemplatePage
                    ? permissionCode.Report.TemplateEdit
                    : permissionCode.Report.Edit
                ) || $has(permissionCode.Report.Communicate)
              "
              :get-condition="getCondition"
              @total-changed="onTotalChanged"
              :data-source="dataSource"
              :auto-bind="false"
              :set-paras="setParas"
              :sticky-header="true"
              class="stickyTable"
              :fields="fields"
              :is-original-fields="true"
              :grid-sort-desc="isGridSortDesc"
              :grid-sort-by="gridSortBy"
              list-id="reportList"
            >
              <template #cell(title)="row">
                <k-link @click="toReportView(row.item)">
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(tempDisplayName)="row">
                <k-link @click="toTemplateView(row.item)">
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(updateMethodDisplay)="row">
                <div
                  v-for="(methodName, index) in row.item.updateMethodDisplay"
                  :key="index"
                >
                  {{ methodName }}
                </div>
              </template>
              <template #cell(isCustomReport)="row">
                <div v-if="row.item.isCustomReport">
                  {{ $g("dataExport") }}
                </div>
                <div v-else>
                  {{ $g("cannedReport") }}
                </div>
              </template>
              <template #cell(viewType)="row">
                <k-form-select
                  v-model="row.item.viewType"
                  :options="viewTypeList"
                  :plaintext="true"
                  class="plaintext"
                />
              </template>
              <template #cell(actions)="row">
                <k-link
                  @click="onEditClicked(row.item)"
                  v-if="
                    row.item.canBeEdit &&
                    !row.item.disabled &&
                    $has(
                      isTemplatePage
                        ? permissionCode.Report.TemplateEdit
                        : permissionCode.Report.Edit
                    )
                  "
                  class="mr-2"
                >
                  {{ $g("konnGrid.edit") }}
                </k-link>
                <div v-else />
                <a
                  href="javascript:void(0)"
                  @click="communication(row.item)"
                  v-if="
                    row.item.forCommunication &&
                    $has(permissionCode.Report.Communicate)
                  "
                >
                  {{ $g("communication") }}
                </a>
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
      <k-popwindow
        id="changeCategoryPopWindow"
        :title="$g('popwindow.category')"
        @ok="onPopSelected"
        :before-close="onBeforeClose"
      >
        <ChangeCategory ref="changeCategory" :is-bau="!isEvent" />
      </k-popwindow>
    </div>
  </div>
</template>

<script>
import NavTab from "../../components/nav-tab";
import ChangeCategory from "../../components/ChangeCategory";
import {
  getReportNavs,
  getBauReportPage,
  getEleReportPage,
  getReportTemplatePage,
  delReports,
  delReportTemplates,
  changeCategory,
  getCommunicationIds,
  getViewTypeList,
} from "../../api/report";
import { repResult } from "../../../../libs/global";
import storeage from "../../../../utils/storeage";
import { enumContactAction } from "../../../common-massage/enums/enumContactAction";
import { handleTabs } from "../../../../utils/permission/permission";
import { templateNavAllTabs } from "../../components/templateTabs";
import { reportNavAllTabs } from "../../components/reportTabs";
import KWikiHelp from "../../../../business-components/wiki-help/wiki-help-link";

export default {
  props: {
    communicationPath: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;

    return {
      permissionCode: this.$route.meta.permissionCode,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      isEvent: isEvent,
      typeName: isEvent ? "eleReportList" : "reportList",
      electionId: isEvent ? storeage.getElectionId() : null,
      reportCategoryId: "",
      queryParams: {
        title: "",
      },
      seen: true,
      isActive: true,
      tabindex:
        this.$route.query.tabindex != null
          ? parseInt(this.$route.query.tabindex)
          : 0,
      fields: [],
      otherTypeNameCondition: null,
      categoryFields: [
        {
          key: "title",
          label: this.$g("title"),
          sortable: false,
          width: "20%",
          isDynamicCondition: true,
        },
        {
          key: "viewType",
          label: this.$g("viewType"),
          sortable: false,
          width: "20%",
          isDynamicCondition: true,
        },
        {
          key: "createBy",
          label: this.$g("creator"),
          sortable: false,
          width: "20%",
          isDynamicCondition: true,
        },
        {
          key: "createTime",
          label: this.$g("date"),
          sortable: false,
          width: "20%",
          isDynamicCondition: true,
        },
        {
          key: "status",
          label: this.$g("status"),
          sortable: false,
          width: "20%",
          isDynamicCondition: true,
        },
      ],
      templateFields: [
        {
          key: "tempDisplayName",
          label: this.$g("name"),
          sortable: false,
          width: "20%",
        },
        {
          key: "templateLevel",
          label: this.$g("templateLevel"),
          sortable: false,
          width: "10%",
        },
        {
          key: "updateMethodDisplay",
          label: this.$g("updateMethod"),
          sortable: false,
          width: "20%",
        },
        {
          key: "isCustomReport",
          label: this.$g("reportType"),
          sortable: false,
          width: "20%",
        },
        {
          key: "description",
          label: this.$g("description"),
          sortable: false,
          width: "30%",
        },
      ],
      curNav: {},
      navItems: [],
      viewTypeList: [],
      totalCount: 0,
      dataSource: getBauReportPage,
      showSearchAreaTemplate: false,
      gridCanEdit: false,
    };
  },
  components: {
    NavTab,
    ChangeCategory,
    KWikiHelp,
  },
  computed: {
    isTemplatePage() {
      return !this.curNav?.value || this.curNav?.value == this.emptyGuid;
    },
    isGridSortDesc() {
      return !this.isTemplatePage;
    },
    gridSortBy() {
      return this.isTemplatePage ? "tempDisplayName" : "CreateTime";
    },
  },
  methods: {
    toTemplateView(item) {
      let items = handleTabs(
        templateNavAllTabs(
          this.isEvent,
          true,
          item.isSystem,
          this.permissionCode
        ),
        this
      );
      if (items.length == 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }
      this.$router.push({
        name: items[0].route,
        query: {
          id: item.id,
          tabindex: this.tabindex,
          isView: "1",
        },
      });
    },
    toReportView(item) {
      // if(!this.$has(this.permissionCode.Report.BasicInfo)){
      //   this.$alert({
      //     title: this.$g("const.confirm.confirm"),
      //     content: this.$g("noPermission"),
      //   });
      //   return;
      // }
      let reouteName;
      if (item.isRegular) {
        if (item.viewType == 1) {
          reouteName = this.isEvent ? "eleReport-view" : "report-view";
        } else {
          reouteName = this.isEvent
            ? "eleReport-dynamic-view"
            : "report-dynamic-view";
        }
      } else {
        if (item.viewType == 1) {
          reouteName = this.isEvent
            ? "eleProfileReport-view"
            : "profileReport-view";
        } else {
          reouteName = this.isEvent
            ? "eleReport-dynamic-view"
            : "report-dynamic-view";
        }
      }
      this.$router
        .push({
          name: reouteName,
          query: {
            id: item.id,
            tempName: item.tempName,
            tabindex: this.tabindex,
            title: item.title,
          },
        })
        .catch();
    },
    categoryClick() {
      this.$router.push({
        name: this.isEvent ? "eleCategoryList" : "categoryList",
      });
    },
    changeCategoryClick() {
      let pmOpt = this.$refs.selectableTable.checkSelected();
      if (pmOpt == null) {
        return null;
      }
      this.$bvModal.show("changeCategoryPopWindow");
    },
    communication(item) {
      let query = {
        reportId: item.id,
        electionId: this.isEvent ? this.electionId : "",
      };
      getCommunicationIds(query, item.isRegular, item.tempName).then(
        (result) => {
          if (result.data.status != repResult.faild) {
            let idNames = result.data.info.communicationIds.map((c) => {
              return {
                id: c,
                name: c,
              };
            });
            storeage.setCommunicationIds(JSON.stringify(idNames));
            let path = "";
            switch (result.data.info.communicationType) {
              case enumContactAction.facility:
                path = this.communicationPath?.facility;
                break;
              case enumContactAction.eleFacility:
                path = this.communicationPath?.eleFacility;
                break;
              case enumContactAction.organization:
                path = this.communicationPath?.organization;
                break;
              default:
                path = "/worker/message/send/workerReport";
                if (this.isEvent) {
                  path = "/worker/eleMessage/send/eleWorkerReport";
                }
                break;
            }
            this.$router.push({
              path: path,
              query: {
                isStoreageCommunicationIds: 1,
              },
            });
          } else {
            this.$refs.lblMsg.message(result.status, result.message);
          }
        }
      );
    },
    onBeforeClose() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        });
    },
    async onPopSelected() {
      let ids = this.$refs.changeCategory.getSelectedIds();
      if (ids.length) {
        changeCategory({
          reportCategoryId: ids[0],
          reportIds: this.$refs.selectableTable.getSelectedIds(),
        }).then((rep) => {
          this.$refs.lblMsg.message(rep.status, rep.message);
          if (rep.data.status == repResult.success) {
            if (ids[0] != this.curNav.value) {
              this.$refs.selectableTable.deleteSelected();
            } else {
              this.$refs.selectableTable.clearSelected();
            }
            this.getTabs();
          }
        });
      }
    },
    delReportHandle() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        if (delIds.length) {
          let api;
          if (this.isTemplatePage) {
            api = delReportTemplates;
          } else {
            api = delReports;
          }
          api(delIds).then((rep) => {
            this.$refs.lblMsg.message(rep.data.status, rep.message);
            if (rep.data.status !== repResult.faild) {
              this.$refs.selectableTable.refresh();
            }
          });
        }
      });
    },
    addReportHandle() {
      if (this.$has(this.permissionCode.Report.BasicInfo)) {
        this.$router.push({
          name: this.isEvent ? "eleSelect-template" : "select-template",
          query: {
            tabindex: this.tabindex,
            categoryId: this.curNav.value,
          },
        });
      } else {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
      }
    },
    onEditClicked(item) {
      let items = [];
      if (this.isTemplatePage) {
        items = handleTabs(
          templateNavAllTabs(
            this.isEvent,
            this.isPlainText,
            item.isSystem,
            this.permissionCode
          ),
          this
        );
      } else {
        items = handleTabs(
          reportNavAllTabs(this.isEvent, this.isAdd, this.permissionCode),
          this
        );
      }
      if (items.length == 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }

      this.$router.push({
        name: items[0].route,
        query: {
          id: item.id,
          tabindex: this.tabindex,
        },
      });
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    bindViewTypeList() {
      getViewTypeList().then((resp) => {
        this.viewTypeList = resp.data || [];
      });
    },
    onToggleSearch() {
      this.seen = !this.seen;
      this.isActive = !this.isActive;
    },
    navClick: function (item) {
      let routeName;
      if (item.value && item.value != this.emptyGuid) {
        routeName = this.isEvent ? "eleReport" : "report";
      } else {
        routeName = this.isEvent ? "eleTemplate" : "template";
      }
      this.$router
        .push({
          name: routeName,
          query: {
            tabindex: item.orderIndex,
          },
        })
        .catch((err) => {});
    },
    onFieldsGet(fields) {
      this.fields = fields;
    },
    onTotalChanged(total) {
      if (this.curNav) {
        this.curNav.total = total;
        this.totalCount = total;
      }
    },
    onColumnChanged() {
      this.$refs.selectableTable.clearFields();
      this.$refs.searchArea.clearHandler();
      this.$refs.selectableTable?.refresh();
    },
    setParas(para) {
      this.queryParams.title = para.title;
      this.$refs.searchArea.setDynamicConditions(para.dynamicConditions);
    },
    clear() {
      this.setParas({ tabindex: this.tabindex });
    },
    getCondition() {
      return {
        tabindex: this.tabindex,
        title: this.queryParams.title,
        electionId: this.electionId,
        reportCategoryId: this.reportCategoryId,
        isBau: !this.isEvent,
        dynamicConditions: this.$refs.searchArea.getDynamicConditions(),
        Sort: this.gridSortBy,
        Order: this.isGridSortDesc ? "Desc" : "Asc",
      };
    },
    search: function () {
      console.log(this.isTemplatePage);
      console.log(this.gridSortBy);
      this.$refs.selectableTable.refresh();
      //this.$refs.selectableTable.pageChange(1);
    },
    pageCondition() {
      return this.$refs.selectableTable.getSearchState();
    },
    getSearchState() {
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    getTabs() {
      getReportNavs(!this.isEvent, this.electionId).then((data) => {
        const items = data.data || [];
        if (items.length) {
          const navArr = [];
          items.forEach(function (item, index) {
            navArr.push({
              text: item.categoryName,
              value: item.reportCategoryId,
              total: item.count,
              orderIndex: index,
            });
          });
          this.navItems = navArr;
          this.curNav = this.navItems[this.tabindex];

          if (!this.isTemplatePage) {
            this.dataSource = this.isEvent
              ? getEleReportPage
              : getBauReportPage;
            this.reportCategoryId = this.curNav.value;
            this.fields = this.categoryFields;
            this.bindViewTypeList();
          } else {
            this.dataSource = getReportTemplatePage;
            this.reportCategoryId = null;
            this.queryParams.title = null;
            this.fields = this.templateFields;
          }
          this.otherTypeNameCondition = {
            typeId: this.reportCategoryId,
          };
          this.showSearchAreaTemplate = true;

          setTimeout(() => {
            if (this.$refs.navTabs) {
              this.$refs.navTabs.tabindex = this.tabindex;
              this.$refs.searchArea?.getTemplateListByTemplateName();
              this.$refs.selectableTable?.clearFields();
              this.$refs.selectableTable?.refresh();
            }
          }, 200);
        }
        if (window.innerWidth < 1024) {
          if (this.seen) {
            this.onToggleSearch();
          }
        }
      });
    },
  },
  created() {
    this.getTabs();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .plaintext {
  > span {
    margin-top: -7px;
  }
}
</style>
