<template>
  <k-form-group
    :label-suffix="addressLabel"
    label-align-md="right"
    label-cols-md="3"
    :content-cols-lg="lgNum"
    :content-cols-md="mdNum"
    label-for="noneBind"
  >
    <div class="col-form-label" v-if="!isPlainText && addressCopyLabel">
      <k-form-checkbox
        :id="getId('isSameAddress')"
        v-model="innerAddress.isSame"
        :plaintext="isPlainText"
        @input="onIsSameAddressChanged"
        :no-locale="true"
        class="inline"
      />
      <div class="inline">
        {{ $g(addressCopyLabel) }}
      </div>
    </div>
    <k-form-group
      :label-suffix="isShowOverseasAddress ? 'address.overseasAddress' : ''"
      label-for="noneBind"
      label-align-md="left"
      label-cols-md="3"
      v-if="!isPlainText && !innerAddress.isSame"
    >
      <div
        class="space-between"
        :class="{ 'justify-content-end': !isShowOverseasAddress }"
      >
        <k-form-checkbox
          :id="getId('isOverseas')"
          v-model="innerAddress.isOverseas"
          :plaintext="isPlainText"
          :no-locale="true"
          @input="clearAddress"
          v-show="isShowOverseasAddress"
          @change="addressNativeChanged"
        />
        <k-button
          v-if="!isPlainText"
          @click="clearAddress"
          @keydown.enter="clearAddress"
          class="btn-level1"
        >
          address.clear
        </k-button>
      </div>
    </k-form-group>
    <native-address
      v-if="!innerAddress.isOverseas"
      ref="native"
      :address="innerAddress"
      :is-plain-text="isPlainText"
      :street-types="streetTypes"
      :state-types="stateTypes"
      :street-posts="streetPosts"
      :identifier="identifier"
      :addressformat="addressformat"
      :validate="validate"
      @googlePlaceChanged="googlePlaceChanged"
      @addressChanged="addressNativeChanged"
      :fullAddressValidate="fullAddressValidate"
      :streetNamePlaceholder="streetNamePlaceholder"
      :nonEditableFields="nonEditableFields"
      :subdivisionAndBoundaryAddressFormBlurEvent="
        subdivisionAndBoundaryAddressFormBlurEventNativeAddress
      "
    />
    <overseas-address
      v-if="innerAddress.isOverseas"
      ref="overseas"
      :address="innerAddress"
      :is-plain-text="isPlainText"
      :identifier="identifier"
      :validate="overseasValidate"
      :nonEditableFields="nonEditableFields"
      :has-oversea-country="hasOverseaCountry"
      @addressChanged="addressNativeChanged"
    />
  </k-form-group>
</template>
<script>
import NativeAddress from "./address-native.vue";
import OverseasAddress from "./address-overseas.vue";

export default {
  components: { NativeAddress, OverseasAddress },
  props: {
    lgNum: {
      type: Number,
      default() {
        return 6;
      },
    },
    mdNum: {
      type: Number,
      default() {
        return 7;
      },
    },
    identifier: {
      type: String,
      default() {
        return "";
      },
    },
    address: {
      type: Object,
      default() {
        return { isSame: false };
      },
    },
    addressToCopy: {
      type: Object,
      default() {
        return {};
      },
    },
    addressCopyLabel: {
      type: String,
      default() {
        return "";
      },
    },
    addressLabel: {
      type: String,
      default() {
        return "";
      },
    },
    streetTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    stateTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    streetPosts: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isPlainText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addressformat: {
      type: String,
      default() {
        return "$Street Number$ $Street Fraction$ $Street Pre$ $Street Name$ $Street Type$ $City$ $State$ $Postal Code$";
      },
    },
    validate: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isShowOverseasAddress: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasOverseaCountry: {
      type: Boolean,
      default() {
        return true;
      },
    },
    overseasValidate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fullAddressValidate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    streetNamePlaceholder: {
      type: String,
      default() {
        return "";
      },
    },
    nonEditableFields: {
      type: Object,
      default() {
        return {
          ResidentialStreetName: false,
          MailingAddressLine1: false,
        };
      },
    },
    subdivisionAndBoundaryAddressFormBlurEventNativeAddress: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  watch: {
    address: function (newAddress) {
      this.innerAddress = newAddress;
    },
    addressToCopy: {
      handler: function (newAddressToCopy) {
        this.copyAddress(newAddressToCopy);
      },
      deep: true,
    },
  },
  data() {
    var address = {
      unit: "",
      streetNumber: "",
      streetName: "",
      streetType: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      streetFraction: "",
      streetPre: "",
      streetPost: "",
      building: "",
      apartment: "",
    };
    if (this.address) address = this.address;
    return {
      innerAddress: address,
    };
  },
  methods: {
    clearAddress() {
      if (!this.loading) {
        this.innerAddress.unit = "";
        this.innerAddress.streetNumber = "";
        this.innerAddress.streetName = "";
        this.innerAddress.streetType = "";
        this.innerAddress.city = "";
        this.innerAddress.state = "";
        this.innerAddress.postcode = "";
        this.innerAddress.country = "";
        this.innerAddress.addressLine1 = "";
        this.innerAddress.addressLine2 = "";
        this.innerAddress.addressLine3 = "";
        this.innerAddress.overseaCountry = "";
        this.innerAddress.streetFraction = "";
        this.innerAddress.streetPre = "";
        this.innerAddress.streetPost = "";
        this.innerAddress.building = "";
        this.innerAddress.apartment = "";

        if (this.$refs.overseas) this.$refs.overseas.clearAddress();
        if (this.$refs.native) this.$refs.native.clearAddress();
      }
    },

    onIsSameAddressChanged(value) {
      if (value) {
        if (this.$refs.native?.$refs.googleAddressSearch) {
          this.$refs.native.$refs.googleAddressSearch.clear();
        }
        this.copyAddress(this.addressToCopy);
      }
    },

    getId(element) {
      return element + this.identifier;
    },

    copyAddress(addressToCopy) {
      if (this.innerAddress.isSame) {
        this.innerAddress.isOverseas = addressToCopy.isOverseas;
        this.innerAddress.unit = addressToCopy.unit;
        this.innerAddress.streetNumber = addressToCopy.streetNumber;
        this.innerAddress.streetName = addressToCopy.streetName;
        this.innerAddress.streetType = addressToCopy.streetType;
        this.innerAddress.city = addressToCopy.city;
        this.innerAddress.state = addressToCopy.state;
        this.innerAddress.postcode = addressToCopy.postcode;
        this.innerAddress.addressLine1 = addressToCopy.addressLine1;
        this.innerAddress.addressLine2 = addressToCopy.addressLine2;
        this.innerAddress.addressLine3 = addressToCopy.addressLine3;
        this.innerAddress.overseaCountry = addressToCopy.overseaCountry;

        this.innerAddress.country = addressToCopy.country;
        this.innerAddress.streetFraction = addressToCopy.streetFraction;
        this.innerAddress.streetPre = addressToCopy.streetPre;
        this.innerAddress.streetPost = addressToCopy.streetPost;
        this.innerAddress.building = addressToCopy.building;
        this.innerAddress.apartment = addressToCopy.apartment;
      }
    },

    googlePlaceChanged(place) {
      this.$emit("googlePlaceChanged", place);
    },
    addressNativeChanged() {
      this.$emit("addressNativeChanged");
    },
  },
};
</script>
<style scoped>
.inline {
  display: inline-block;
}
</style>
