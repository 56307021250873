<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button-back :back-route="getRouteName()" :back-query="backQuery">
              buttons.back
            </k-button-back>
          </k-button-group>
          <KWikiHelp :propsWikiHelpCode="wikiHelpCode" />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add @click="onAddCategory" v-if="categoryAdd" />
              <b-icon-delete @click="onDeleteCategory" v-if="categoryDel" />
            </div>
            <b-tabs ref="navTabs" />
          </div>
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              :data-source="dataSource"
              :get-condition="getCondition"
              :class="{ 'table-show-search-area': true }"
              list-id="selectableTable"
              :set-paras="setParas"
              :fields="fields"
              @total-changed="onTotalChanged"
              :grid-sort-by="sortBy"
              :grid-sort-desc="sortDesc"
              @edit-clicked="onEditClicked"
              sort-icon-left
              :has-action="categoryEdit"
              @items-get="itemsGet"
            >
              <template #cell(description)="row">
                <span v-html="row.value" />
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
      <k-popwindow
        id="popCategoryDetail"
        @ok="onSaveCategoryDetail"
        :check-selected="false"
        :before-close="onBeforeClose"
        :ok-title="$g('buttons.save')"
        ref="popCategoryDetail"
      >
        <MessageTemplateCategoryDetail
          ref="categoryDetail"
          :category-id="categoryId"
          :election-id="electionId"
          :isNotification="isNotification"
          :items="items"
          @onSubmit="onSubmit"
        />
      </k-popwindow>
    </div>
  </div>
</template>

<script>
import {
  postCategoryPage,
  addCategory,
  deleteCategory,
  updateCategory,
} from "../../../api/messageTemplateCategory";
import MessageTemplateCategoryDetail from "./components/messageTemplateCategoryDetail.vue";
import { repResult } from "../../../../../libs/global";
import { enumTemplateType } from "../../../enums/enumTemplateType";
import storeage from "../../../../../utils/storeage";
import { permissionCode } from "../../../mixins/permissionCode";
import { has } from "../../../../../utils/permission/operatePermission";

export default {
  mixins: [permissionCode],
  components: { MessageTemplateCategoryDetail },
  data() {
    return {
      isEvent: this.$route.meta.isEvent,
      electionId: this.$route.meta.isEvent ? storeage.getElectionId() : "",
      enumTemplateType,
      isActive: true,
      totalCount: 0,
      fields: [
        { key: "title", label: "title" },
        { key: "sort", label: "sort" },
      ],
      sortBy: "",
      sortDesc: "",
      showPopCategoryDetailType: 1, ///0 null 1 add 2 update, default 1
      showPopCategoryDetailTypeEnum: {
        null: 0,
        add: 1,
        update: 2,
      },
      backQuery: {
        tabindex: parseInt(this.$route.query.tabindex),
      },
      categoryId: null,
      categoryAdd: false,
      categoryDel: false,
      categoryEdit: false,
      wikiHelpCode: null,
      items: [],
    };
  },
  computed: {
    dataSource: function () {
      return postCategoryPage;
    },
  },
  methods: {
    itemsGet(items) {
      this.items = items;
    },
    getRouteName() {
      let basicRouteName = "";
      switch (true) {
        case this.$route.query.templateType ===
          this.enumTemplateType.documentation && !this.isNotification:
          basicRouteName = this.isEvent
            ? "eleDocumentTemplateList"
            : "documentTemplateList";
          break;
        case this.$route.query.templateType !=
          this.enumTemplateType.documentation && this.isNotification:
          basicRouteName = this.isEvent ? "eleNotification" : "notification";
          break;
        case this.$route.query.templateType ==
          this.enumTemplateType.documentation && this.isNotification:
          basicRouteName = this.isEvent
            ? "eleNotificationDocumentTemplateList"
            : "notificationDocumentTemplateList";
          break;
        default:
          basicRouteName = this.isEvent
            ? "eleMessageTemplate"
            : "messageTemplate";
          break;
      }
      return basicRouteName;
    },
    onEditClicked(item) {
      this.categoryId = item.id;
      this.showPopCategoryDetailType =
        this.showPopCategoryDetailTypeEnum.update;
      this.$bvModal.show("popCategoryDetail");
    },
    onAddCategory() {
      this.categoryId = null;
      this.showPopCategoryDetailType = this.showPopCategoryDetailTypeEnum.add;
      this.$bvModal.show("popCategoryDetail");
    },
    async onBeforeClose() {
      let valid = await this.$refs.categoryDetail.checkValid();
      if (valid) {
        return this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          })
          .catch(() => {
            return false;
          });
      } else {
        return false;
      }
    },
    onSaveCategoryDetail() {
      let dataForm = this.$refs.categoryDetail.form;
      dataForm.electionId = this.electionId;
      dataForm.templateType = this.$route.query.templateType;
      dataForm.isNotification = this.isNotification ?? false;
      switch (this.showPopCategoryDetailType) {
        case this.showPopCategoryDetailTypeEnum.add:
          this.addSaveFunc(dataForm);
          break;
        case this.showPopCategoryDetailTypeEnum.update:
          this.updateSaveFunc(dataForm);
          break;
      }
    },
    addSaveFunc(dataForm) {
      addCategory(dataForm).then((res) => {
        this.responseFunc(res);
      });
    },
    updateSaveFunc(dataForm) {
      updateCategory(dataForm).then((res) => {
        this.responseFunc(res);
      });
    },
    onDeleteCategory() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        let data = {
          ids: delIds,
          isNotification: this.isNotification,
        };
        deleteCategory(data).then((m) => {
          this.responseFunc(m);
        });
      });
    },
    responseFunc(m) {
      this.$refs.lblMsg.message(m.data.status, m.message);
      if (m.data.status == repResult.success) {
        this.$refs.selectableTable.refresh();
      }
    },

    onTotalChanged(total) {
      this.totalCount = total;
    },
    setParas(para) {},
    getCondition() {
      return {
        templateType: this.$route.query.templateType,
        electionId: this.electionId,
        isNotification: this.isNotification,
      };
    },
    search: function () {
      this.$refs.selectableTable.pageChange(1);
    },
    clear() {
      let para = {};
      this.setParas(para);
    },
    setPermission() {
      let permissionCode = "";
      permissionCode = this.isEvent
        ? this.permissionCodeEvent.Communication
        : this.permissionCode.Communication;
      this.categoryAdd = permissionCode.DocumentationCategoryAdd
        ? has(permissionCode.DocumentationCategoryAdd)
        : true;
      this.categoryDel = permissionCode.DocumentationCategoryDelete
        ? has(permissionCode.DocumentationCategoryDelete)
        : true;
      this.categoryEdit = permissionCode.DocumentationCategoryEdit
        ? has(permissionCode.DocumentationCategoryEdit)
        : true;
    },
    initWikiHelpCode() {
      let wikiHelpCodes = this.isEvent
        ? this.$wikiHelpLinkCodes?.Event.Communication.Template
        : this.$wikiHelpLinkCodes?.BAU.Communication.Template;
      switch (parseInt(this.$route.query.templateType ?? -1)) {
        case enumTemplateType.email:
          this.wikiHelpCode = wikiHelpCodes?.Email.Category;
          break;
        case enumTemplateType.phone:
          this.wikiHelpCode = wikiHelpCodes?.Phone.Category;
          break;
        case enumTemplateType.sms:
          this.wikiHelpCode = wikiHelpCodes?.SMS.Category;
          break;
        case enumTemplateType.letter:
          this.wikiHelpCode = wikiHelpCodes?.Letter.Category;
          break;
        case enumTemplateType.documentation:
          this.wikiHelpCode = wikiHelpCodes?.Document.Category;
          break;
        default:
          this.wikiHelpCode = null;
          break;
      }
    },
    onSubmit(){
      this.$refs.popCategoryDetail?.keyDownFormSubmit();
    },
  },
  created() {
    this.setPermission();
    this.initWikiHelpCode();
  },
};
</script>
