<template>
  <div ref="attrKonnFixedGridRef">
    <b-table
      :items="items"
      :fields="gridFields"
      :select-mode="selectMode"
      :class="tableClass"
      ref="selectableTable"
      no-provider-paging
      selectable
      :no-select-on-click="true"
      no-local-sorting
      @row-selected="onRowSelected"
      :tbody-tr-class="rowClass"
      :tbody-tr-attr="rowAttr"
      :sticky-header="stickyHeader"
      sort-icon-left
      :fixed="fixed"
      :responsive="responsive"
      style="word-wrap: break-word"
    >
      <template #cell(All)="row">
        <template v-if="row.rowSelected">
          <b-checkbox
            @change="selectRecord(row, $event)"
            checked="true"
            :disabled="row.item.disabled"
            @keydown.enter.native="enter"
            @keydown.space.native.prevent
          />
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <b-checkbox
            @change="selectRecord(row, $event)"
            checked="false"
            :disabled="row.item.disabled"
            @keydown.enter.native="enter"
            @keydown.space.native.prevent
          />
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #head(All)="data">
        <b-checkbox
          v-if="showAllCheckbox"
          @change="toggleSelect"
          v-model="selectAll"
          :disabled="!items || items.length === 0"
          @keydown.enter.native="enter"
          @keydown.space.native.prevent
        >
          {{ data.label }}
        </b-checkbox>
        <span v-else>{{ "" }}</span>
      </template>
      <template #cell(actions)="row">
        <slot
          :name="cellSlotName({ key: 'actions', label: actionNameLabel })"
          v-bind="row"
        >
          <b-link
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            {{ actionName }}
          </b-link>
        </slot>
      </template>
      <template v-for="field in cellSlots" #[cellSlotName(field)]="data">
        <slot :name="cellSlotName(field)" v-bind="data">
          {{ data.value }}
        </slot>
      </template>
      <template v-for="field in cellSlots" #[headSlotName(field)]="data">
        <slot :name="headSlotName(field)" v-bind="data">
          {{ data.label }}
        </slot>
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        />
      </template>
      <template #table-caption>
        <slot name="tableCaption">
          <div v-show="items.length == 0" class="noResults">
            {{ $g("noResultsAreAvailable") }}
          </div>
        </slot>
      </template>
    </b-table>
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";
import { debounce } from "../../utils/sysUtiles";

export default {
  props: {
    selectMode: {
      type: String,
      default() {
        return "multi";
      },
    },
    showAllCheckbox: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fields: {
      type: Array,
      default() {
        return null;
      },
    },
    originalItems: {
      type: Array,
      default() {
        return [];
      },
    },
    rowAttr: {
      type: Function,
      default() {
        return null;
      },
    },
    hasCheckBox: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
    actionName: {
      type: String,
      default() {
        return this.$g("konnGrid.edit");
      },
    },
    actionNameLabel: {
      type: String,
      default() {
        return this.$g("konnGrid.actions");
      },
    },
    tableClass: {
      type: Object,
      default() {
        return null;
      },
    },
    rowClass: {
      type: Function,
      default() {
        return null;
      },
    },
    autoSelect: {
      type: Boolean,
      default() {
        return false;
      },
    },
    changeCheckboxFunc: {
      type: Function,
      default() {
        return null;
      },
    },
    stickyHeader: {
      type: Boolean,
      default() {
        return false;
      },
    },
    needGFunc: {
      type: Boolean,
      default() {
        return true;
      },
    },
    listId: {
      type: String,
      default() {
        return null;
      },
    },
    noLocal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fieldDefColWidth: {
      type: Number,
      default: 150,
    },
    actionColWidth: {
      type: Number,
      default: 150,
    },
    checkBoxColWidth: {
      type: Number,
      default: 35,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      gridFields: this.gFields(this.fields),
      selectAll: false,
      disabledAndUnCheckBox: 0,
      HasAction: this.hasAction,
      cloneGridFields: [],
      debounceFun: debounce(),
      mElementResize: null,
    };
  },
  computed: {
    cellSlots() {
      if (!this.gridFields) {
        return [];
      }
      return this.gridFields.filter(
        (f) => f.key !== "All" && f.key !== "actions"
      );
    },
  },
  methods: {
    enter(e){
      let event = e.srcElement || e.target;
      event.click()
    },
    setSelectAll(value){
      this.selectAll = value;
    },
    confirmOpt(msg, noSelectedMsg) {
      if (!this.selected || this.selected.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: noSelectedMsg
            ? noSelectedMsg
            : this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      }
      return this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: msg,
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
        cancelType: "secondary",
      });
    },
    gFields(fields) {
      if (this.needGFunc && fields && fields.length > 0) {
        let gFields = fields.slice(0);
        if (!this.noLocal) {
          gFields.forEach((m) => (m.label = this.$g(m.label)));
        }
        return gFields;
      } else {
        return fields;
      }
    },
    getAllRows() {
      return this.items;
    },
    checkSelected() {
      if (!this.selected || this.selected.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else {
        return true;
      }
    },
    checkSingleSelected() {
      if (!this.selected || this.selected.length === 0) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else if (this.selected.length > 1) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.warning.select-one-records"),
          okText: this.$g("const.confirm.ok"),
        });
        return null;
      } else {
        return true;
      }
    },
    onRowSelected(items) {
      if (!items) {
        this.selectAll = false;
        return;
      }
      this.selectAll =
        items.length === this.items.length - this.disabledAndUnCheckBox;
      this.selected = items;
      this.$emit("row-selected", items);
    },
    getSelectedItems() {
      return this.selected;
    },
    getSelectedIds(ifn) {
      return this.getIds(ifn, this.selected);
    },
    getAllIds(ifn) {
      return this.getIds(ifn, this.items);
    },
    getIds(ifn, lst) {
      let result = [];
      if (lst) {
        lst.forEach((e) => {
          if (!ifn) {
            ifn = "id";
          }
          if (e[ifn]) {
            result.push(e[ifn]);
          }
        });
      }
      return result;
    },
    cellSlotName(fieldName) {
      return "cell(" + fieldName.key + ")";
    },
    headSlotName(fieldName) {
      return "head(" + fieldName.key + ")";
    },
    selectAllRows() {
      let disabledAndUnCheckBox = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].disabled) {
          this.selectRowByIndex(i);
        } else {
          if (!this.items[i].rowSelected) {
            disabledAndUnCheckBox++;
          }
        }
      }
      this.disabledAndUnCheckBox = disabledAndUnCheckBox;
    },
    refreshItem() {
      this.$refs.selectableTable.refresh();
    },
    clearSelected() {
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].disabled) {
          this.unselectRowByIndex(i);
        }
      }
    },
    selectRecord(row, selected) {
      if (selected) {
        this.$refs.selectableTable.selectRow(row.index);
      } else {
        this.$refs.selectableTable.unselectRow(row.index);
      }
      if (this.changeCheckboxFunc) {
        this.changeCheckboxFunc(row);
      }
    },
    toggleSelect(checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    selectRowByIndex(idx) {
      this.$refs.selectableTable?.selectRow(idx);
    },
    unselectRowByIndex(idx) {
      this.$refs.selectableTable?.unselectRow(idx);
    },
    addNewRow(data, usePush) {
      if (usePush) {
        this.items.push(data);
      } else {
        this.items.unshift(data);
      }
    },
    clearRow() {
      this.items = [];
    },
    insertNewRow(data, index) {
      this.items.splice(index, 0, data);
    },
    deleteNewRow(data) {
      let length = this.items.length;
      for (let i = 0; i < length; i++) {
        if (this.items[i] === data) {
          this.items.splice(i, 1); //删除下标为i的元素
        }
      }
    },
    getRowIndex(data) {
      let length = this.items.length;
      for (let i = 0; i < length; i++) {
        if (this.items[i] === data) {
          return i;
        }
      }
    },
    deleteSelected() {
      if (this.selected) {
        for (let item of this.selected) {
          this.deleteNewRow(item);
        }
        this.selected = [];
      }
    },
    setCheckBox(checkBox) {
      if (checkBox) {
        this.gridFields = [
          ...[{ key: "All", label: "", width: this.checkBoxColWidth + "px" }],
          ...this.gridFields,
        ];
      } else {
        if (this.gridFields[0].key === "All") this.gridFields.splice(0, 1);
      }
    },
    getPageAdditional() {
      return {
        routeName: this.$route?.name,
        query: this.$route?.query,
      };
    },
    getPageState() {
      let page = this.getPageAdditional();
      let rt = {};
      rt.pageAddtional = page;
      return rt;
    },
    bindGridRereshFields(newfileds) {
      this.gridFields = this.gFields(newfileds);
      this.bindGridFuncGridFields();
    },
    bindGridFuncGridFields() {
      if (this.gridFields == null) {
        throw new TypeError("please set fields");
      }
      if (this.$store && this.listId) {
        let pageState = this.getPageState();
        this.$store.dispatch("page/savePageState", {
          listId: this.listId,
          condition: pageState,
        });
      }
      this.items = this.originalItems;
      this.camelCaseField(this.items);
      if (this.hasCheckBox) {
        if (this.gridFields.findIndex((item) => item.key === "All") == -1)
          this.gridFields = [
            ...[{ key: "All", label: "", width: this.checkBoxColWidth + "px" }],
            ...this.gridFields,
          ];
      } else {
        if (this.gridFields[0]?.key === "All") this.gridFields.splice(0, 1);
      }
      if (this.HasAction) {
        if (this.gridFields.findIndex((item) => item.key === "actions") == -1)
          this.gridFields = [
            ...this.gridFields,
            ...[{ key: "actions", label: this.actionNameLabel }],
          ];
      } else {
        if (this.gridFields[this.gridFields.length - 1]?.key === "actions")
          this.gridFields.splice(this.gridFields.length - 1, 1);
      }
      this.cloneGridFields = _.cloneDeep(this.gridFields);
      this.calculatedColumnWidth();
      this.setTimeoutFunc();
    },
    calculatedColumnWidth() {
      if (
        !this.gridFields ||
        this.gridFields.length == 0 ||
        !this.$refs.attrKonnFixedGridRef ||
        !this.$refs.selectableTable ||
        !this.cloneGridFields ||
        this.cloneGridFields.length == 0
      ) {
        return;
      }
      this.gridFields = _.cloneDeep(this.cloneGridFields);
      let maxWidth = this.$refs.attrKonnFixedGridRef.offsetWidth;
      let curWidth = 0;
      this.gridFields.forEach((item) => {
        if (!item.width || item.width.indexOf("%") > -1) {
          if (item.key === "All") {
            item.width = this.checkBoxColWidth + "px";
          } else if (item.key === "actions") {
            item.width = this.actionColWidth + "px";
          } else {
            item.width = this.fieldDefColWidth + "px";
          }
        }

        if (item.width) {
          curWidth += parseInt(item.width.replace("px", ""));
        }
      });
      if (curWidth <= maxWidth) {
        let remainWidth = maxWidth;
        let withoutAllActionsWidth = curWidth;
        if (this.hasCheckBox) {
          remainWidth -= this.checkBoxColWidth;
          withoutAllActionsWidth -= this.checkBoxColWidth;
        }
        if (this.hasAction) {
          remainWidth -= this.actionColWidth;
          withoutAllActionsWidth -= this.actionColWidth;
        }
        this.gridFields.forEach((item) => {
          if (item.key !== "All" && item.key !== "actions") {
            item.width = `${
              (((remainWidth * parseInt(item.width.replace("px", ""))) /
                withoutAllActionsWidth) *
                100) /
              maxWidth
            }%`;
          } else {
            item.width = `${
              (parseInt(item.width.replace("px", "")) * 100) / maxWidth
            }%`;
          }
        });
      }
    },
    setTimeoutFunc() {
      let that = this;
      if (this.autoSelect) {
        window.setTimeout(function () {
          for (let i = 0; i < that.items.length; i++) {
            if (that.items[i].rowSelected) {
              that.selectRowByIndex(i);
            }
          }
        }, 100);
      } else {
        window.setTimeout(function () {
          that.selectAll = false;
          for (let i = 0; i < that.items.length; i++) {
            that.unselectRowByIndex(i);
          }
        }, 100);
      }
    },
    camelCaseField(rows) {
      if (
        !rows ||
        rows.length === 0 ||
        !this.gridFields ||
        this.gridFields.length === 0
      ) {
        return;
      }
      let dataFields = {};
      for (let f in rows[0]) {
        dataFields[f.toLowerCase()] = f;
      }
      for (let item of this.gridFields) {
        let lField = item.key.toLowerCase();
        let fieldName = dataFields[lField];
        if (fieldName) {
          item.key = fieldName;
        }
      }
    },
    info(item, index, target) {
      this.$emit("edit-clicked", item, index, target);
    },
    moveUpOneItem(index) {
      if (this.items.length > 1 && index > 0) {
        this.items[index] = this.items.splice(
          index - 1,
          1,
          this.items[index]
        )[0];
        this.$nextTick(() => {
          this.selectRowByIndex(index - 1);
        });
      }
    },
    moveDownOneItem(index) {
      if (this.items.length > 1 && index < this.items.length - 1) {
        this.items[index] = this.items.splice(
          index + 1,
          1,
          this.items[index]
        )[0];
        this.$nextTick(() => {
          this.selectRowByIndex(index + 1);
        });
      }
    },
  },
  mounted() {
    this.mElementResize = elementResizeDetectorMaker();
    this.mElementResize.listenTo(this.$refs.attrKonnFixedGridRef, () => {
      this.debounceFun(() => {
        this.calculatedColumnWidth();
      }, 50);
    });
    this.bindGridFuncGridFields();
  },
  beforeDestroy() {
    this.$refs.attrKonnFixedGridRef &&
      this.mElementResize.uninstall(this.$refs.attrKonnFixedGridRef);
  },
  watch: {
    originalItems: {
      handler(val) {
        this.items = val;
      },
    },
    hasAction: {
      handler(val) {
        this.HasAction = val;
        this.bindGridFuncGridFields();
      },
    },
  },
};
</script>
