<template>
  <div>
    <konn-grid
      ref="selectableTable"
      select-mode="single"
      :has-check-box="false"
      :has-action="false"
      :fields="fields"
      :get-condition="getCondition"
      :data-source="getLog"
      :sticky-header="stickyHeader"
      :class="{ stickyTable: stickyHeader }"
      :grid-sort-desc="true"
    >
      <template
        v-if="this.fieldsType === this.logFieldsType.communicationLog"
        #cell(subject)="row"
      >
        <router-link
          :to="{
            name:getCommunicationRouteName(row.item) ,
            query: {
              id: row.item.msgId,
              isView: '1',
              dbtn: '0',
              hidePreNext: '1',
              deliveryType: row.item.logType,
              historyBack: '1',
            },
          }"
          v-if="row.item.msgId != '00000000-0000-0000-0000-000000000000'"
        >
          {{ row.value }}
        </router-link>
        <k-link
          @click="showCommunicationLogDetialPop(row.item.id)"
          v-else
        >
          {{ row.value }}
        </k-link>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        >
      </template>
      <template #cell(description)="row">
        <div v-html="row.item.description"></div>
      </template>
    </konn-grid>

    <CommunicationLogDetialPop
      ref="communicationLogDetialPop"
      :ref-id="id"
      :log-request-type="logType"
      v-if="fieldsType == logFieldsType.communicationLog"
      @saveSuccess="saveSuccess"
      :is-plain-text="isPlainText"
    />
  </div>
</template>

<script>
import {
  getActivityLog,
  getActivityOtherLog,
  getCommunicationLogPage,
  getElectionUseLog,
  getSiteSurveyLog,
  getBoundaryLog,
} from "./api";

import { logFieldsType } from "./const";
import CommunicationLogDetialPop from "./communicationLogDetialPop";

export default {
  components: {
    CommunicationLogDetialPop,
  },
  props: {
    fieldsType: {
      type: String,
      default(){
        return "";
      },
    },
    logType: {
      type: Number,
      default(){
        return 0;
      },
    },
    id: {
      type: String,
      default(){
        return "";
      },
    },
    sysId: {
      type: Number,
      default(){
        return - 1;
      },
    },
    isModifyBaseUrl: {
      type: Boolean,
      default: false,
    },
    permissionCode: {
      type: String,
      default: "",
    },
    isEvent: {
      type: Boolean,
      default(){
        return false;
      },
    },
    isPlainText: {
      type: Boolean,
      default(){
        return false;
      },
    },
    logFunc: {
      type: Function,
      default(){
        return null;
      },
    },
    fieldsAry: {
      type: Array,
      default(){
        return [];
      },
    },
    DeleteElectionUseLog: {
      type: String,
      default(){
        return null;
      },
    },
    deleteElectionUseLogFacility: {
      type: String,
      default(){
        return null;
      },
    },
    electionId: {
      type: String,
      default(){
        return null;
      },
    },
    stickyHeader: {
      type: Boolean,
      default(){
        return false;
      },
    },
    condition: {
      type: Object,
      default(){
        return null;
      },
    },
  },
  data(){
    return {
      selectMode: "multi",
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
      pageOptions: [1, 10, 15, { value: 100, text: "Show a lot" }],
      logFieldsType: logFieldsType,
      routeName: this.isEvent
          ? "eleMessageDeliveryReport"
          : "messageDeliveryReport",
      letterRouteName: this.isEvent
          ? "eleMessageLetterDeliveryDetails"
          : "messageLetterDeliveryDetails",
      notificationRouteName: this.isEvent
          ? "eleNotificationDeliveryReport"
          : "notificationDeliveryReport",
      notificationLetterRouteName: this.isEvent
          ? "eleNotificationLetterDeliveryDetails"
          : "notificationLetterDeliveryDetails",
    };
  },
  computed: {
    getLog: function (){
      switch (this.fieldsType) {
        case logFieldsType.activityLog:
          if(this.isModifyBaseUrl){
            return getActivityOtherLog;
          }
          return getActivityLog;
        case logFieldsType.communicationLog:
          return getCommunicationLogPage;
        case logFieldsType.electionUseLog:
          return getElectionUseLog;
        case logFieldsType.surveyLogType:
          return getSiteSurveyLog;
        case logFieldsType.boundaryLog:
          return getBoundaryLog;
        case logFieldsType.customizeLog:
          return this.logFunc;
          // case logFieldsType.assessmentHistory:
          //     return getAssessmentHistory;
          //   case logFieldsType.workHistory:
          //     return getWorkHistory;
          //   case logFieldsType.applicationHistory:
          //     return getApplicationHistory;
        default:
          return null;
      }
    },

    fields(){
      switch (this.fieldsType) {
        case this.logFieldsType.activityLog:
          return [
            { key: "operator", label: "log.operator", width: "20%" },
            {
              key: "operation",
              label: "log.operation",
              width: "20%",
            },
            {
              key: "description",
              label: "log.description",
              width: "40%",
            },
            { key: "date", label: "log.date", width: "20%" },
            { key: "date", label: "log.dateOrTime", width: "20%" },
          ];
        case this.logFieldsType.communicationLog:
          return [
            { key: "subject", label: "log.subject", width: "14%" },
            { key: "logTypeName", label: "log.type", width: "14%" },
            {
              key: "sentTime",
              label: "log.sentTime",
              width: "18%",
            },
            { key: "sendby", label: "log.sendby", width: "18%" },
            {
              key: "receivedby",
              label: "log.receivedby",
              width: "18%",
            },
            {
              key: "surveyResponse",
              label: "log.surveyResponse",
              width: "18%",
            },
          ];
        case this.logFieldsType.electionUseLog:
          var item = [
            {
              key: "electionDate",
              label: "log.electionDate",
              width: "22%",
            },
            {
              key: "electionTitle",
              label: "log.electionName",
              width: "26%",
            },
            {
              key: "facilityName",
              label: "log.facilityName",
              width: "26%",
            },
            {
              key: "votingStationName",
              label: "log.votingStation",
              width: "26%",
            },
          ];
          if(this.DeleteElectionUseLog){
            let index = item.findIndex(
                (e) => e.key == this.DeleteElectionUseLog,
            );
            item.splice(index, 1);
          }
          if(this.deleteElectionUseLogFacility){
            let index = item.findIndex(
                (e) => e.key == this.deleteElectionUseLogFacility,
            );
            item.splice(index, 1);
          }
          return item;
        case this.logFieldsType.surveyLogType:
          return [
            { key: "creator", label: "log.surveyor", width: "14%" },
            {
              key: "updatedFileds",
              label: "log.updatedFileds",
              width: "14%",
            },
            {
              key: "surveyResult",
              label: "log.surveyResult",
              width: "18%",
            },
            { key: "from", label: "log.from", width: "18%" },
            { key: "to", label: "log.to", width: "18%" },
            { key: "createTime", label: "log.dateAndTime", width: "18%" },
          ];
        case this.logFieldsType.boundaryLog:
          return [
            { key: "boundaryName", label: "log.name", width: "50%" },
            {
              key: "redistributionName",
              label: "log.redistribution",
              width: "50%",
            },
          ];
        case this.logFieldsType.customizeLog:
          if(this.fieldsAry && this.fieldsAry.length){
            return JSON.parse(JSON.stringify(this.fieldsAry));
          } else {
            return [
              { key: "operator", label: "log.operator", width: "20%" },
              {
                key: "operation",
                label: "log.operation",
                width: "20%",
              },
              {
                key: "description",
                label: "log.description",
                width: "40%",
              },
              { key: "date", label: "log.date", width: "20%" },
              { key: "date", label: "log.dateOrTime", width: "20%" },
            ];
          }
          // case this.logFieldsType.assessmentHistory:
          //   return [
          //     { key: 'event', label: 'log.event', width: '30%' },
          //     { key: 'position', label: 'log.position', width: '30%' },
          //     { key: '', label: 'log.linktoAssessment', width: '40%' }
          //   ]
          // case this.logFieldsType.workHistory:
          //   return [
          //     { key: 'event', label: 'log.event', width: '50%' },
          //     { key: 'position', label: 'log.position', width: '30%' },
          //     { key: 'date', label: 'log.date', width: '20%' }
          //   ]
          // case this.logFieldsType.applicationHistory:
          //   return [
          //     { key: 'event', label: 'log.event', width: '50%' },
          //     { key: 'status', label: 'log.status', width: '20%' },
          //     { key: 'dateTime', label: 'log.dateTime', width: '30%' }
          //   ]
        default:
          return null;
      }
    },
  },
  methods: {
    getCommunicationRouteName(item){
      let routeName = "";
      if(item.isNotification){
        routeName = item.logType === 5 ? this.notificationLetterRouteName : this.notificationRouteName;
      } else {
        routeName = item.logType === 5 ? this.letterRouteName : this.routeName;
      }
      return routeName;
    },
    getCondition(){
      if(this.condition){
        return this.condition;
      }
      return {
        LogRequestType: this.logType,
        RefId: this.id,
        Sort: "createtime",
        SysID: this.sysId,
        PermissionCode: this.permissionCode,
        electionId: this.electionId,
      };
    },
    bindFields(){
      return null;
    },
    showCommunicationLogDetialPop(id){
      this.$refs.communicationLogDetialPop.showPop(id);
    },
    saveSuccess(res){
      this.$emit("saveSuccess", res);
    },
    reload(){
      this.$refs.selectableTable.pageChange(1);
    },
  },
  created(){
    this.bindFields();
  },
};
</script>
