<template>
  <span class="iconSpan">
    <b-icon
      icon="plus-circle-fill"
      @click="add"
      ref="addTip"
      @keyup.enter="add"
      :tabindex="tabIndex"
    />
    <b-tooltip
      :target="() => $refs['addTip']"
      :title="toolTipTitle.length>0 ? toolTipTitle : $g('icon.addToolTip')"
      boundary="window"
      :container="uid"
      placement="bottomleft"
      triggers="hover"
    />
    <div :id="uid" />
  </span>
</template>
<script>
import { getRouterName } from "../../utils/permission/permission";

export default {
  props: {
    toolTipTitle: {
      type: String,
      default () {
        return "";
      },
    },
    isHasPermission: {
      type: Boolean,
      default: false,
    },
    tabItems: {
      type: Array,
      default () {
        return [];
      },
    },
    tabIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data () {
    return {
      uid: "tip" + this._uid,
    };
  },
  methods: {
    add () {
      if (this.isHasPermission) {
        if (!getRouterName(this.tabItems)) {
          this.$alert({
            title: this.$g("icon.confirm"),
            content: this.$g("icon.noPermission"),
            okText: this.$g("const.confirm.ok"),
          });
        } else {
          this.$emit("click");
        }
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>
