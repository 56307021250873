<template>
  <div class="content">
    <div class="center-bar">
      <div class="nav-btn-group">
        <k-button-group>
          <k-button-back
            :back-route="isEvent ? 'eleTemplate' : 'template'"
            :back-query="{ tabindex: $route.query.tabindex }"
          >
            buttons.back
          </k-button-back>
          <k-button v-show="!isView" @click="switchEditView">
            buttons.view
          </k-button>
          <k-button
            v-show="isView && canBeEdit"
            @click="switchIsView"
            v-permission="permissionCode.Report.TemplateEdit"
          >
            buttons.edit
          </k-button>
        </k-button-group>
        <KWikiHelp
          :props-wiki-help-code="permissionCode.Report.TemplatePermissions"
        />
      </div>
      <div class="content-main">
        <k-alert-msg ref="lblMsg" />
        <div class="content-navTab">
          <div class="nav-tools" v-if="!isView">
            <b-icon-add
              v-permission="permissionCode.Report.TemplatePermissionsAdd"
              @click="onAddClick"
            />
            <b-icon-delete
              v-permission="permissionCode.Report.TemplatePermissionsDelete"
              @click="onDeleteClick"
            />
          </div>
          <div>
            <Nav
              ref="nav"
              :tab-index="navIndex"
              :is-event="isEvent"
              :is-plain-text="plaintext"
              :is-system="false"
            />
          </div>
        </div>
        <div class="content-party">
          <div class="pop-flex mb-3 mt-3">
            <k-form-group label-suffix="fristName" />
            <k-form-input
              name="name"
              v-model="form.firstName"
              type="text"
              class="mr-3 wid"
            />
            <k-form-group label-suffix="lastName" />
            <k-form-input
              name="name"
              v-model="form.lastName"
              type="text"
              class="mr-3 wid"
            />
            <k-form-group label-suffix="group" />
            <k-form-select
              id="status"
              name="status"
              :options="groupList"
              v-model="form.authorityRoleId"
              class="mr-3 wid"
            />
            <k-button @click="onSearchClick"> search </k-button>
          </div>
          <konn-grid
            ref="selectableTable"
            :data-source="dataSource"
            :get-condition="getCondition"
            @total-changed="onTotalChanged"
            :grid-sort-desc="sortDesc"
            grid-sort-by="orderIndex"
            sort-icon-left
            :fields="fields"
            :has-action="false"
          >
            <template #cell(canEditTemplate)="row">
              <k-form-checkbox
                v-model="row.item.canEditTemplate"
                @change="changeEditTemplate(row.item)"
                :plaintext="
                  isView || !$has(permissionCode.Report.TemplatePermissionsEdit)
                "
              />
            </template>
            <template #cell(canDeleteTemplate)="row">
              <k-form-checkbox
                v-model="row.item.canDeleteTemplate"
                @change="changeDeleteTemplate(row.item)"
                :plaintext="
                  isView || !$has(permissionCode.Report.TemplatePermissionsEdit)
                "
              />
            </template>
          </konn-grid>
        </div>
        <UserAdd
          @positionList="getUserlsit"
          :group-list="groupList"
          :is-report="false"
          @show-message="showmessage"
          :report-template-id="reportTemplateId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/template-nav";
import UserAdd from "../../components/select-user.vue";
import {
  getTemplatePermissionsList,
  getGroupsList,
  deleteTemplatepermissionss,
  changeTemolateEditPermission,
  changeTemolateDeletePermission,
  checkCanEdit,
} from "../../api/report";

export default {
  components: {
    Nav,
    UserAdd,
  },
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      navIndex: 1,
      isView: this.$route.query.isView == 1,
      plaintext: this.$route.query.isView == 1,
      isEvent: isEvent,
      form: {
        firstName: "",
        lastName: "",
        authorityRoles: "",
      },
      groupList: [],
      sortDesc: false,
      totalCount: 0,
      fields: [
        { key: "firstName", label: "firstName" },
        { key: "lastName", label: "lastName" },
        { key: "email", label: "email" },
        { key: "authorityRoles", label: "userGroups" },
        { key: "canEditTemplate", label: "editTemplate" },
        { key: "canDeleteTemplate", label: "deleteTemplate" },
      ],
      reportTemplateId: this.$route.query.id,
      canBeEdit: false,
    };
  },
  computed: {
    dataSource: function () {
      return getTemplatePermissionsList;
    },
  },
  methods: {
    showmessage(msg) {
      this.$refs.selectableTable.refresh();
      this.$refs.lblMsg.message(msg.data.status, msg.message);
    },
    getUserlsit(list) {
      console.log(list, "userList");
    },
    changeEditTemplate(item) {
      console.log(item);
      changeTemolateEditPermission({
        id: item.id,
        allow: item.canEditTemplate,
      }).then((m) => {
        this.$refs.lblMsg.message(m.data.status, m.message);
      });
    },
    changeDeleteTemplate(item) {
      changeTemolateDeletePermission({
        id: item.id,
        allow: item.canDeleteTemplate,
      }).then((m) => {
        this.$refs.lblMsg.message(m.data.status, m.message);
      });
    },
    getGroupsList() {
      getGroupsList().then((res) => {
        this.groupList = res.data;
      });
    },
    onAddClick() {
      this.$bvModal.show("model-user");
    },
    onDeleteClick() {
      let pmOpt = this.$refs.selectableTable.confirmOpt(
        this.$g("const.confirm.delete")
      );
      if (pmOpt == null) {
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        deleteTemplatepermissionss(delIds).then((m) => {
          this.$refs.selectableTable.refresh();
          this.$refs.lblMsg.message(m.data.status, m.message);
        });
      });
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
    onSearchClick() {
      this.$refs.selectableTable.pageChange(1);
    },
    getCondition() {
      return {
        ...this.form,
        reportTemplateId: this.$route.query.id,
      };
    },
    onColumnChanged() {
      this.$refs.selectableTable.refresh();
    },
    switchEditView() {
      this.isView = true;
      this.plaintext = true;
    },
    switchIsView() {
      this.isView = false;
      this.plaintext = false;
    },
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      checkCanEdit(id).then((resp) => {
        this.canBeEdit = resp.data;
      });
    }
    this.getGroupsList();
  },
};
</script>

<style scoped>
.table-actions {
  margin-left: 10px;
}

.wid {
  width: 15%;
}
</style>
