<template>
  <detail
    :is-event="isEvent"
    :is-plain-text="true"
    :id="id"
    ref="detail"
    :has-search-category="hasSearchCategory"
    :is-notification="isNotification"
    :message-template-detail-content-content-editor-required="
      MessageTemplateDetailContentSubjectRequired
    "
    :message-template-detail-content-subject-required="
      MessageTemplateDetailContentSubjectRequired
    "
  >
    <template #formBar="{ form }">
      <div>
        <k-button-back
          :back-route="getBackRoute()"
          :back-query="{
            tabindex: $route.query.tabindex,
            templateType:$route.query.templateType
          }"
        >
          buttons.back
        </k-button-back>
        <k-button
          v-if="isNotification ? (form.isCreator || isTemplateEdit) : isTemplateEdit"
          @click="onRedirectEdit"
        >
          buttons.edit
        </k-button>
        <k-pre-next
          :has-save-next="false"
          ref="pn"
        />
        <k-button-delete
          v-if="isTemplateDel"
          :delete-handler="deleteHandler"
          :redirect-route-name="getBackRoute()"
          :del-id="id"
          @delete-failed="deleteFailed"
          :redirect-query="{ tabindex: $route.query.tabindex }"
        />
      </div>
      <div class="nav-search">
        <search-bar
          :data-source="searchBarDataSource"
          query-keyword="keywords"
          :search-bar-fomatter="searchBarFomatter"
          @option-selected="searchSelected"
          sort="subject"
          order="Asc"
          :other-conditions="{
            electionId: electionId,
            templateType: $route.query.templateType,
            isNotification: isNotification,
            ignoreElection: ignoreElection,
          }"
        />
      </div>
    </template>
    <template #formBarNav="{ form }">
      <k-alert-msg ref="lblMsg" />
      <template-nav
        ref="nav"
        :tab-index="navIndex"
        :is-plain-text="true"
        :is-event="isEvent"
        :is-notification="isNotification"
        :show-edit="isNotification ? (form.isCreator || isTemplateEdit) : isTemplateEdit"
      />
    </template>
    <template #insertLabel>
      <slot name="insertLabel" />
    </template>
    <template #formGroup="{ form, plaintext, templateType, enumTemplateType }">
      <slot
        name="formGroup"
        :form="form"
        :plaintext="plaintext"
        :templateType="templateType"
        :enumTemplateType="enumTemplateType"
      />
    </template>
  </detail>
</template>
<script>
import detail from "./components/messageTemplateDetail.vue";
import { repResult } from "../../../../libs/global";
import templateNav from "../../components/template-nav.vue";
import { saveAlert } from "../../../../utils/saveAlert";
import {
  delTemplate,
  getTemplateSearchBarList,
} from "../../api/messageTemplate";
import { enumTemplateType } from "../../enums/enumTemplateType";
import { has } from "../../../../utils/permission/operatePermission";
import storeage from "../../../../utils/storeage";
import { permissionCode } from "../../mixins/permissionCode";

export default {
  mixins: [permissionCode],
  components: { detail, templateNav },
  props: {
    hasSearchCategory: {
      type: Boolean,
      default(){
        return true;
      },
    },
    ignoreElection: {
      type: Boolean,
      default() {
        return true;
      },
    },
    MessageTemplateDetailContentSubjectRequired: {
      type: Boolean,
      default: false,
    },
    MessageTemplateDetailContentContentEditorRequired: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    let isEvent = this.$route.meta.isEvent;
    return {
      isEvent: isEvent,
      id: this.$route.query.id,
      electionId: isEvent ? storeage.getElectionId() : "",
      navIndex: 0,
      isTemplateEdit: false,
      isTemplateDel: false,
    };
  },
  computed: {
    searchBarDataSource: function (){
      return getTemplateSearchBarList;
    },
    deleteHandler: function (){
      return delTemplate;
    },
  },
  methods: {
    getBackRoute(){
      let routeName = "";
      if(this.isNotification){
        routeName = this.isEvent ? "eleNotification" : "notification";
      } else {
        routeName = this.isEvent ? "eleMessageTemplate" : "messageTemplate";
      }
      return routeName;
    },
    setPermission(){
      let permissionCode = this.isEvent
          ? this.permissionCodeEvent?.Communication
          : this.permissionCode?.Communication;
      switch (parseInt(this.$route.query.templateType)) {
        case enumTemplateType.email:
          this.isTemplateEdit = has(permissionCode.EmailTemplateEdit);
          this.isTemplateDel = has(permissionCode.EmailTemplateDelete);
          break;
        case enumTemplateType.phone:
          this.isTemplateEdit = has(permissionCode.PhoneTemplateEdit);
          this.isTemplateDel = has(permissionCode.PhoneTemplateDelete);
          break;
        case enumTemplateType.sms:
          this.isTemplateEdit = has(permissionCode.SMSTemplateEdit);
          this.isTemplateDel = has(permissionCode.SMSTemplateDelete);
          break;
        case enumTemplateType.letter:
          this.isTemplateEdit = has(permissionCode.LetterTemplateEdit);
          this.isTemplateDel = has(permissionCode.LetterTemplateDelete);
          break;
        default:
          break;
      }
    },
    onRedirectEdit(){
      let routeName = "";
      if(this.isNotification){
        routeName = this.isEvent ? "eleNotificationEdit" : "notificationEdit";
      } else {
        routeName = this.isEvent
            ? "eleMessageTemplateEdit"
            : "messageTemplateEdit";
      }
      this.$router.push({
        name: routeName,
        query: {
          id: this.$route.query.id,
          isView: "0",
          hidePreNext: this.$route.query.hidePreNext,
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          tabindex: this.$route.query.tabindex,
        },
      });
    },
    showAlert(){
      let status = saveAlert.savedStatus(this.$route);
      if(status === repResult.success){
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },

    searchBarFomatter(item){
      return `${item.subject}`;
    },
    searchSelected(item){
      let routeName = "";
      if(this.isNotification){
        routeName = this.isEvent ? "eleNotificationView" : "notificationView";
      } else {
        routeName = this.isEvent
            ? "eleMessageTemplateView"
            : "messageTemplateView";
      }
      this.$router.push({
        name: routeName,
        query: {
          id: item.id,
          hidePreNext: "1",
          isView: "1",
          dbtn: this.$route.query.dbtn,
          templateType: this.$route.query.templateType,
          tabindex: this.$route.query.tabindex,
          IgnoreElection: false,
        },
      });
    },
    deleteFailed(m){
      this.$refs.lblMsg.message(m.data.status, m.message);
    },
  },
  mounted(){
    this.showAlert();
    this.setPermission();
  },
};
</script>
