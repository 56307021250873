<template>
  <div>
    <search-dynamic
      v-for="(dynamicCondition, i) in dynamicConditions"
      :key="i"
      :first="i == 0"
      :index="parseInt(i)"
      :info="dynamicCondition"
      @conditionChange="onConditionChange"
      @conditionDelete="onConditionDelete"
      :fields="fields"
      :show-delete="dynamicConditions.length > 1"
    />
  </div>
</template>
<script>
import searchDynamic from "./components/dynamicSingle";

export default {
  components: { searchDynamic },
  props: {
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dynamicConditions: [{ field: null, sign: 1, condition: 0, value: "" }],
    };
  },
  computed: {},
  methods: {
    getDynamicConditions() {
      let result = [];
      let dynamicConditions = [...this.dynamicConditions];
      dynamicConditions.forEach((element) => {
        if (element.field) {
          if (element.value && element.value.trim)
            element.value = element.value.trim();
          result.push(element);
        }
      });
      return result;
    },
    onConditionChange(index, info) {
      this.dynamicConditions[index] = info;
      if (index === this.dynamicConditions.length - 1 && info.condition > 0) {
        this.dynamicConditions.push({
          field: null,
          sign: 1,
          condition: 0,
          value: "",
        });
      } else if (
        index < this.dynamicConditions.length - 1 &&
        info.condition === 0
      ) {
        this.dynamicConditions.splice(
          index + 1,
          this.dynamicConditions.length - 1 - index
        );
      }
    },
    onConditionDelete(index) {
      if (this.dynamicConditions.length === 1) {
        this.dynamicConditions = [
          { field: null, sign: 1, condition: 0, value: "" },
        ];
      } else if (this.dynamicConditions.length > index) {
        this.dynamicConditions.splice(index, 1);
      }
    },
  },
};
</script>