<template>
  <span ref="ta">
    <pre v-if="plaintext" class="col-form-label d-block">{{ value }}</pre>
    <b-form-textarea
      v-else
      v-bind="props"
      :placeholder="gPlaceholder"
      v-on="$listeners"
      :aria-label="ariaLabel"
      :tabindex="tabIndex"
    />
  </span>
</template>
<script>
import { BFormTextarea } from "bootstrap-vue";
import { g } from "../../locale/lang-val";

const addedProps = {
  maxLength: {
    type: Number,
    default() {
      return null;
    },
  },
  tabIndex: {
    type: Number,
    default() {
      return 0;
    },
  },
};

const objProps = Object.assign({}, BFormTextarea.options.props, addedProps);

export default {
  components: { BFormTextarea },
  props: {
    ...objProps,
    ariaLabel: {
      type: String,
      default() {
        return "textarea";
      },
    },
  },
  computed: {
    props() {
      let { placeholder, ...params } = this.$props;
      return params;
    },
    gPlaceholder() {
      return g(this.placeholder);
    },
  },
  methods: {
    getContentLength() {
      var c = this.$refs.ta.getElementsByTagName("textarea");
      if (c && c.length > 0) {
        return c[0].value.length;
      }
      return 0;
    },
  },
};
</script>
