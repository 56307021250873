<template>
  <div>
    <KValidationObserver
      ref="observer"
      class="content-page"
    >
      <konn-grid
        :select-mode="selectMode"
        :has-action="false"
        :fields="fields"
        ref="selectableTable"
        :data-source="fieldsSettingDataSource"
        :get-condition="getCondition"
        @row-selected="onRowSelected"
        :has-pager="false"
        :auto-select="true"
        id-field="key"
        :has-search-default-template="false"
      >
        <template #cell(orderIndex)="row">
          <KValidationProvider
            :rules="{
              required: true,
              numberBetween: { minValue: 0, maxValue: 100 },
            }"
            v-slot="{ errors }"
            slim
            :name="`${row.item.key}orderIndex`"
          >
            <k-alert
              :show="errors.length > 0"
              variant="warning"
            >
              {{ errors[0] }}
            </k-alert>
            <k-form-input v-model="row.item.orderIndex" />
          </KValidationProvider>
        </template>
        <template #cell(width)="row">
          <KValidationProvider
            :rules="{
              required: true,
              numberBetween: { minValue: 1, maxValue: 999 },
              integer: true,
            }"
            v-slot="{ errors }"
            slim
            :name="`${row.item.key}width`"
          >
            <k-alert
              :show="errors.length > 0"
              variant="warning"
            >
              {{ errors[0] }}
            </k-alert>
            <k-form-input v-model="row.item.width" />
          </KValidationProvider>
        </template>
      </konn-grid>
    </KValidationObserver>
  </div>
</template>
<script>
import { getPageFieldsConfig, getCandidatePageFieldsConfig } from "./api/common";

export default {
  props: {
    listId: {
      type: String,
      default () {
        return null;
      },
    },
    isUseCandidateApi: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      keyword: "",
      selectMode: "multi",
      selected: [],
      fields: [
        {
          key: "label",
          label: "konnGrid.colSetting.Fields",
          sortable: false,
        },
        {
          key: "required",
          label: "konnGrid.colSetting.Required",
          sortable: false,
          formatter: (value) => {
            return value ? "Yes" : "No";
          },
        },
        {
          key: "orderIndex",
          label: "konnGrid.colSetting.Order",
          sortable: false,
        },
        {
          key: "width",
          label: "konnGrid.colSetting.Width",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    fieldsSettingDataSource: function () {
      if (this.isUseCandidateApi) {
        return getCandidatePageFieldsConfig;
      } else {
        return getPageFieldsConfig;
      }
    },
  },
  methods: {
    onRowSelected (items) {
      this.selected = items;
    },
    getAllRows () {
      return this.$refs.selectableTable.getAllRows();
    },
    getCondition () {
      return { listId: this.listId };
    },
    getSelected () {
      return this.selected;
    },
    async checkValid () {
      return this.$refs.observer.validate();
    },
  },
};
</script>
