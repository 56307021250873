<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div
        class="right-bar"
        v-show="seen"
      >
        <b-icon
          icon="x"
          @click="onToggleSearch"
        />
        <search-area
          ref="searchArea"
          :type-name="typeName"
          :search-click="search"
          :clear-click="clear"
          :get-paras="getParas"
          :set-paras="setParas"
          :show-dynamic="false"
        >
          <k-form-group label-suffix="search.widgetName">
            <k-form-input v-model="queryParams.widgetName" />
          </k-form-group>
          <k-form-group
            label-suffix="search.size"
            label-for="search-size"
          >
            <k-multiselect
              id="search-size"
              :options="sizeTypes"
              v-model="queryParams.sizeSelected"
            />
          </k-form-group>
          <k-form-group
            label-suffix="search.widgetType"
            label-for="search-widgetType"
          >
            <k-multiselect
              id="search-widgetType"
              :options="widgetTypes"
              v-model="queryParams.widgetTypeSelected"
            />
          </k-form-group>
          <k-form-group
            label-suffix="search.status"
            label-for="search-status"
          >
            <k-multiselect
              id="search-status"
              :options="widgetStatus"
              v-model="queryParams.widgetStatusSelected"
            />
          </k-form-group>
        </search-area>
      </div>
      <div class="center-bar">
        <div class="nav-btn-group">
          <b-icon-search-area
            @click="onToggleSearch"
            :class="{ active: isActive }"
          />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <div class="content-navTab">
            <div class="nav-tools">
              <b-icon-add
                @click="addReportHandle"
                v-permission="permissionCode.DashboardConfiguration.ConfigurationAdd"
              />
              <b-icon-delete
                @click="delReportHandle"
                v-permission="permissionCode.DashboardConfiguration.ConfigurationDelete"
              />
              <column-config-icon
                list-id="widgetlistpageid"
                @column-changed="onColumnChanged"
              />
            </div>
            <div>
              <nav-tab
                ref="navTabs"
                :refresh="navClick"
                :items="navItems"
                :need-total-count="true"
              />
            </div>
          </div>
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              select-mode="multi"
              :has-check-box="true"
              :has-action="$has(permissionCode.DashboardConfiguration.ConfigurationEdit)"
              :get-condition="getCondition"
              @total-changed="onTotalChanged"
              :data-source="dataSource"
              :auto-bind="false"
              :set-paras="setParas"
              :sticky-header="true"
              class="stickyTable"
              @fields-get="onFieldsGet"
              :grid-sort-desc="false"
              grid-sort-by="order"
              list-id="widgetlistpageid"
            >
              <template #cell(widgetName)="row">
                <k-link
                  @click="toWidgetView(row.item)"
                >
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(tempDisplayName)="row">
                <k-link
                  @click="toTemplateView(row.item)"
                >
                  {{ row.value }}
                </k-link>
              </template>
              <template #cell(widgetType)="row">
                <k-form-select
                  v-model="row.item.widgetType"
                  :options="widgetTypes"
                  :plaintext="true"
                  class="plaintext"
                />
              </template>
              <template #cell(widgetStatus)="row">
                <k-form-select
                  v-model="row.item.widgetStatus"
                  :options="widgetStatus"
                  :plaintext="true"
                  class="plaintext"
                />
              </template>
              <template #cell(size)="row">
                <k-form-select
                  v-model="row.item.size"
                  :options="sizeTypes"
                  :plaintext="true"
                  class="plaintext"
                />
              </template>
              <template #cell(actions)="row">
                <k-link
                  @click="onEditClicked(row.item)"
                  class="mr-2"
                >
                  {{ $g("konnGrid.edit") }}
                </k-link>
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTab from "../../components/nav-tab";
import {
  getReportWidgetPage,
  getSizeTypeList,
  getWidgetStatusList,
  getWidgetTypeList,
  getWidgetCategoryList,
  delReportWidgets,
} from "../../api/report";
import storeage from "../../../../utils/storeage";
import { repResult } from "../../../../libs/global";
import { deleteTemplateList } from "../../../../api/searchTemplate";

export default {
  data(){
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      permissionCode: this.$route.meta.permissionCode,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      isEvent: isEvent,
      typeName: "reportWidgetList",
      searchfields: [],
      electionId: isEvent ? storeage.getElectionId() : null,
      queryParams: {
        widgetName: "",
        widgetTypeSelected: [],
        widgetStatusSelected: [],
        sizeSelected: [],
      },
      seen: true,
      isActive: true,
      tabindex:
          this.$route.query.tabindex != null ? parseInt(this.$route.query.tabindex) : 0,
      category:
          this.$route.query.tabindex != null ? parseInt(this.$route.query.tabindex) + 1 : 1,
      fields: [],
      curNav: {},
      navItems: [],
      widgetTypes: [],
      widgetStatus: [],
      sizeTypes: [],
      totalCount: 0,
      dataSource: getReportWidgetPage,
    };
  },
  components: {
    NavTab,
  },
  computed: {},
  methods: {
    toTemplateView(item){
      if(!this.$has(this.permissionCode.Report.TemplateBasicInfo)){
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
        });
        return;
      }
      this.$router.push({
        name: this.isEvent ? "eleTemplate-view" : "template-view",
        query: {
          id: item.id,
          tabindex: this.tabindex,
          isSystem: item.isSystem ? "1" : "0",
        },
      });
    },
    toWidgetView(item){
      if(!this.$has(this.permissionCode.DashboardConfiguration.ConfigurationBasicInfo)){
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
        });
        return;
      }
      this.$router.push({
        name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
        query: {
          id: item.id,
          isView: 1,
          tabindex: this.tabindex,
        },
      });
    },
    onBeforeClose(){
      return this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
    },
    delReportHandle(){
      let pmOpt = this.$refs.selectableTable.confirmOpt(this.$g("const.confirm.delete"));
      if(pmOpt == null){
        return;
      }
      pmOpt.then(() => {
        let delIds = this.$refs.selectableTable.getSelectedIds();
        let items = this.$refs.selectableTable.getSelectedItems();
        let templateIds = [];
        items.forEach((element) => {
          templateIds.push(element.templateId);
        });
        if(delIds.length){
          delReportWidgets(delIds).then((rep) => {
            this.$refs.lblMsg.message(rep.data.status, rep.message);
            if(rep.data.status !== repResult.faild){
              deleteTemplateList(templateIds);
              this.$refs.selectableTable.refresh();
            }
          });
        }
      });
    },
    addReportHandle(){
      if(this.$has(this.permissionCode.DashboardConfiguration.ConfigurationBasicInfo)){
        this.$router.push({
          name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
          query: {
            tabindex: this.tabindex,
          },
        });
      } else {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
      }
    },
    onColumnChanged(){
      this.$refs.selectableTable.clearFields();
      this.$refs.selectableTable.refresh();
    },
    onFieldsGet(fields){
      this.fields = fields;
    },
    editStatus(status){
      this.isHasSubTab = status;
    },
    onEditClicked(item){
      if(!this.$has(this.permissionCode.DashboardConfiguration.ConfigurationBasicInfo)){
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }
      this.$router.push({
        name: this.isEvent ? "eleReport-widget-edit" : "report-widget-edit",
        query: {
          id: item.id,
          tabindex: this.tabindex,
        },
      });
    },
    bindSizeTypeList(){
      getSizeTypeList().then((resp) => {
        this.sizeTypes = resp.data || [];
      });
    },
    bindWidgetStatusList(){
      getWidgetStatusList().then((resp) => {
        this.widgetStatus = resp.data || [];
      });
    },
    bindWidgetTypeList(){
      getWidgetTypeList().then((resp) => {
        this.widgetTypes = resp.data || [];
      });
    },
    onToggleSearch(){
      this.seen = !this.seen;
      this.isActive = !this.isActive;
    },
    navClick: function (item){
      let routeName;
      routeName = this.isEvent ? "eleReport-widget-list" : "report-widget-list";
      this.$router.push({
        name: routeName,
        query: {
          tabindex: item.orderIndex,
        },
      });
    },
    onTotalChanged(total){
      if(this.curNav){
        this.curNav.total = total;
        this.totalCount = total;
      }
    },
    setParas(para){
      this.queryParams.widgetName = para.widgetName;
      this.queryParams.sizeSelected = para.sizeSelected;
      this.queryParams.widgetStatusSelected = para.widgetStatusSelected;
      this.queryParams.widgetTypeSelected = para.widgetTypeSelected;
      this.$refs.searchArea.setDynamicConditions(para.dynamicConditions);
    },
    getParas(){
      return {
        widgetName: this.queryParams.widgetName,
        sizeSelected: this.queryParams.sizeSelected,
        widgetStatusSelected: this.queryParams.widgetStatusSelected,
        widgetTypeSelected: this.queryParams.widgetTypeSelected,
        dynamicConditions: this.$refs.searchArea.getDynamicConditions(),
      };
    },
    clear(){
      this.setParas({
        category: this.category,
        widgetName: "",
        widgetTypeSelected: [],
        widgetStatusSelected: [],
        sizeSelected: [],
      });
    },
    getTotalValue(data){
      let totalN = 0;
      if(data){
        console.log(data);
        data.forEach((element) => {
          totalN += parseInt(element);
        });
        console.log(totalN);
      }
      return totalN;
    },
    getCondition(){
      return {
        category: this.category,
        widgetName: this.queryParams.widgetName,
        size: this.getTotalValue(this.queryParams.sizeSelected),
        widgetStatus: this.getTotalValue(this.queryParams.widgetStatusSelected),
        widgetType: this.getTotalValue(this.queryParams.widgetTypeSelected),
        electionId: this.electionId,
        isBau: !this.isEvent,
        dynamicConditions: this.$refs.searchArea.getDynamicConditions(),
      };
    },
    search: function (){
      this.$refs.selectableTable.refresh();
    },
    pageCondition(){
      return this.$refs.selectableTable.getSearchState();
    },
    getSearchState(){
      let condition = this.$refs.selectableTable.getCondition();
      return this.$refs.selectableTable.getSearchState(condition);
    },
    getTabs(){
      getWidgetCategoryList(!this.isEvent, this.electionId).then((data) => {
        const items = data.data || [];
        if(items.length){
          const navArr = [];
          items.forEach(function (item, index){
            navArr.push({
              text: item.text,
              value: item.value,
              total: item.total,
              orderIndex: index,
            });
          });
          this.navItems = navArr;
          this.curNav = this.navItems[this.tabindex];

          setTimeout(() => {
            if(this.$refs.navTabs){
              this.$refs.navTabs.tabindex = this.tabindex;
              this.$refs.searchArea?.refreshTemplateList();
              this.$refs.selectableTable?.clearFields();
              this.$refs.selectableTable?.refresh();
            }
          }, 100);
        }
        if(window.innerWidth < 1024){
          if(this.seen){
            this.onToggleSearch();
          }
        }
      });
    },
  },
  created(){
    this.getTabs();
    this.bindSizeTypeList();
    this.bindWidgetStatusList();
    this.bindWidgetTypeList();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .plaintext {
  > span {
    margin-top: -7px;
  }
}
</style>
