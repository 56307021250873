import { ElectionStatus } from "../utils/enums/electionStatus";
import {
  getSysGeneralInfo,
} from "@/api/app";


const userInfoKey = "__pollchief_userInfo_key";
const userLanguageIdKey = "__pollchief_language_id_key";
const systemGeneralInfoKey = "__pollchief_systemGeneralInfo_key";
const locationMenuListKey = "__pollchief_locationMenuList_key";
const electionMenuListKey = "__pollchief_electionMenuList_key";
const locationAllNavsKey = "__pollchief_locationAllNavS_key";
const electionIdKey = "__pollchief_electionId_key";
const electionStatusKey = "__pollchief_electionStatus_key";
const myballotNavKey = "__pollchief_myballot_nav_key";
const candidateNavKey = "__pollchief_candidateNav_key";
const adminNavKey = "__pollchief_adminNav_key";
const BauStaffNavKey = "__pollchief_BauStaff_nav_key";
const EventStaffNavKey = "__pollchief_EventStaff_nav_key";
const EventResultNavKey = "__pollchief_EventResult_nav_key";
const BauHelpdeskNavKey = "__pollchief_BauHelpdesk_nav_key";
const EventHelpdeskNavKey = "__pollchief_EventHelpdesk_nav_key";
const BauAssetNavKey = "__pollchief_BauAsset_nav_key";
const EventAssetNavKey = "__pollchief_EventAsset_nav_key";
const BauElectorNavKey = "__pollchief_BauElector_nav_key";
const EventElectorNavKey = "__pollchief_EventElector_nav_key";
const CommunicationIds = "__pollchief_CommunicationIds_key";
const languageResKey = "__pollchief_languageResKey_key";
const WikiHelpLinkCodesKey = "__pollchief_WikiHelpLinkCodes_key";

export const setWikiHelpLinkCodes = (data) => {
  localStorage.setItem(WikiHelpLinkCodesKey, JSON.stringify(data));
};

export const getWikiHelpLinkCodes = () => {
  if(!localStorage.getItem(WikiHelpLinkCodesKey)){
    return null;
  }
  return JSON.parse(localStorage.getItem(WikiHelpLinkCodesKey));
};
export const setMyballotNav = (data) => {
  localStorage.setItem(myballotNavKey, JSON.stringify(data));
};

export const getMyballotNav = () => {
  return JSON.parse(localStorage.getItem(myballotNavKey));
};

export const removeMyballotNav = () => {
  localStorage.removeItem(myballotNavKey);
};

export const setUserInfo = (data) => {
  localStorage.setItem(userInfoKey, JSON.stringify(data));
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(userInfoKey));
};

export const removeUserInfo = () => {
  localStorage.removeItem(userInfoKey);
};

export const setSystemGeneralInfo = (data) => {
  localStorage.setItem(systemGeneralInfoKey, JSON.stringify(data));
};

export const getSystemGeneralInfo = () => {
  return JSON.parse(localStorage.getItem(systemGeneralInfoKey));
};

export const setLocationMenuList = (data) => {
  localStorage.setItem(locationMenuListKey, JSON.stringify(data));
};

export const removeLocationMenuList = (data) => {
  localStorage.removeItem(locationMenuListKey);
};

export const getLocationMenuList = () => {
  let locationMenuListStr = localStorage.getItem(locationMenuListKey);
  if(locationMenuListStr){
    return JSON.parse(locationMenuListStr);
  }
  return [];
};

export const setElectionMenuList = (data) => {
  localStorage.setItem(electionMenuListKey, JSON.stringify(data));
};

export const removeElectionMenuList = (data) => {
  localStorage.removeItem(electionMenuListKey);
};

export const getElectionMenuList = () => {
  let electionMenuListStr = localStorage.getItem(electionMenuListKey);
  if(electionMenuListStr){
    return JSON.parse(electionMenuListStr);
  } else {
    return [];
  }
};

export const getTimeZone = () => {
  let timeZone = "America/Detroit";
  if(getSystemGeneralInfo()?.timeZone){
    timeZone = getSystemGeneralInfo()?.timeZone;
  }
  return timeZone;
};

export const getDateFormat = () => {
  let dateformat = "MM/DD/YYYY";
  if(getSystemGeneralInfo()?.dateFormat){
    dateformat = getSystemGeneralInfo()?.dateFormat.toUpperCase();
  }
  return dateformat;
};

export const setLanguageId = (languageId) => {
  localStorage.setItem(userLanguageIdKey, languageId);
};

export const getLanguageId = () => {
  return localStorage.getItem(userLanguageIdKey);
};

export const removeLanguageId = () => {
  return localStorage.removeItem(userLanguageIdKey);
};

export const setLocationAllNavs = (locationNav) => {
  localStorage.setItem(locationAllNavsKey, JSON.stringify(locationNav));
};

export const getLocationAllNavs = () => {
  let locationNav = localStorage.getItem(locationAllNavsKey);
  if(locationNav){
    return JSON.parse(locationNav);
  }
  return [];
};

export const removeLocationAllNavs = () => {
  return localStorage.removeItem(locationAllNavsKey);
};
export const setAdminNavs = (adminNav) => {
  localStorage.setItem(adminNavKey, JSON.stringify(adminNav));
};

export const getAdminNavs = () => {
  let adminNav = localStorage.getItem(adminNavKey);
  if(adminNav){
    return JSON.parse(adminNav);
  }
  return [];
};

export const removeAdminNavs = () => {
  return localStorage.removeItem(adminNavKey);
};

export const setElectionId = (electionId) => {
  sessionStorage.setItem(electionIdKey, electionId);
  localStorage.setItem(electionIdKey, electionId);
};

export const getElectionId = () => {
  if(!sessionStorage.getItem(electionIdKey) && localStorage.getItem(electionIdKey)){
    sessionStorage.setItem(electionIdKey, localStorage.getItem(electionIdKey));
  }
  return sessionStorage.getItem(electionIdKey);
};
export const setCandidateNav = (candidateNav) => {
  localStorage.setItem(candidateNavKey, JSON.stringify(candidateNav));
};

export const getCandidateNav = () => {
  let candidateNav = localStorage.getItem(candidateNavKey);
  if(candidateNav){
    return JSON.parse(candidateNav);
  }
  return [];
};

export const removeCandidateNav = () => {
  return localStorage.removeItem(candidateNavKey);
};

export const setBauStaffNav = (data) => {
  localStorage.setItem(BauStaffNavKey, JSON.stringify(data));
};

export const getBauStaffNav = () => {
  let bauStaffNav = localStorage.getItem(BauStaffNavKey);
  if(bauStaffNav){
    return JSON.parse(bauStaffNav);
  }
  return [];
};

export const removeBauStaffNav = () => {
  localStorage.removeItem(BauStaffNavKey);
};


export const setEventStaffNav = (data) => {
  localStorage.setItem(EventStaffNavKey, JSON.stringify(data));
};

export const getEventStaffNav = () => {
  let eventStaffNav = localStorage.getItem(EventStaffNavKey);
  if(eventStaffNav){
    return JSON.parse(eventStaffNav);
  }
  return [];
};

export const setEventAssetNav = (data) => {
  localStorage.setItem(EventAssetNavKey, JSON.stringify(data));
};

export const getEventAssetNav = () => {
  let eventAssetNav = localStorage.getItem(EventAssetNavKey);
  if(eventAssetNav){
    return JSON.parse(eventAssetNav);
  }
  return [];
};

export const removeEventAssetNav = () => {
  localStorage.removeItem(EventAssetNavKey);
};

export const setEventElectorNav = (data) => {
  localStorage.setItem(EventElectorNavKey, JSON.stringify(data));
};

export const getEventElectorNav = () => {
  let eventElectorNav = localStorage.getItem(EventElectorNavKey);
  if(eventElectorNav){
    return JSON.parse(eventElectorNav);
  }
  return [];
};

export const removeEventElectorNav = () => {
  localStorage.removeItem(EventElectorNavKey);
};

export const removeEventStaffNav = () => {
  localStorage.removeItem(EventStaffNavKey);
};

export const setEventResultNav = (data) => {
  localStorage.setItem(EventResultNavKey, JSON.stringify(data));
};

export const getEventResultNav = () => {
  let eventResultNav = localStorage.getItem(EventResultNavKey);
  if(eventResultNav){
    return JSON.parse(eventResultNav);
  }
  return [];
};

export const removeEventResultNav = () => {
  localStorage.removeItem(EventResultNavKey);
};

export const removeElectionId = () => {
  return localStorage.removeItem(electionIdKey);
};

export const setElectionStatus = (electionStatus) => {
  localStorage.setItem(electionStatusKey, electionStatus);
};

export const getElectionStatus = () => {
  return localStorage.getItem(electionStatusKey);
};

export const isElectionIsArchived = () => {
  let status = getElectionStatus();
  return status && status == ElectionStatus.archived;
};

export const set = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};
export const get = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};
export const clearAll = () => {
  localStorage.clear();
};
export const removeItem = (key) => {
  localStorage.removeItem(key);
};


export const timeSet = (key, object, outtime) => {
  let ctime = parseInt(Date.now() / 1000);
  let exp = outtime || 24 * 60 * 60;  //outtime
  let outObj = {
    outime: ctime + exp,
    data: object,
  };
  localStorage.setItem(key, JSON.stringify(outObj));
};
export const timeGet = (key, isClear = false) => {
  let data = JSON.parse(localStorage.getItem(key));
  if(!data){
    return false;
  }
  if(data.outime >= parseInt(Date.now() / 1000)){
    if(isClear){
      localStorage.removeItem(key);
    }
    return data.data;
  } else {
    return false;
  }
};
export const setCommunicationIds = (data) => {
  localStorage.setItem(CommunicationIds, data);
};

export const getCommunicationIds = () => {
  return localStorage.getItem(CommunicationIds);
};

export const setBauAssetNav = (data) => {
  localStorage.setItem(BauAssetNavKey, JSON.stringify(data));
};

export const getBauAssetNav = () => {
  let bauAssetNav = localStorage.getItem(BauAssetNavKey);
  if(bauAssetNav){
    return JSON.parse(bauAssetNav);
  }
  return [];
};

export const removeBauAssetNav = () => {
  localStorage.removeItem(BauAssetNavKey);
};

export const setBauElectorNav = (data) => {
  localStorage.setItem(BauElectorNavKey, JSON.stringify(data));
};

export const getBauElectorNav = () => {
  let bauElectorNav = localStorage.getItem(BauElectorNavKey);
  if(bauElectorNav){
    return JSON.parse(bauElectorNav);
  }
  return [];
};

export const removeBauElectorNav = () => {
  localStorage.removeItem(BauElectorNavKey);
};
export const setBauHelpdeskNav = (data) => {
  localStorage.setItem(BauHelpdeskNavKey, JSON.stringify(data));
};

export const getBauHelpdeskNav = () => {
  let bauHelpdeskNav = localStorage.getItem(BauHelpdeskNavKey);
  if(bauHelpdeskNav){
    return JSON.parse(bauHelpdeskNav);
  }
  return [];
};

export const removeBauHelpdeskNav = () => {
  localStorage.removeItem(BauHelpdeskNavKey);
};

export const setEventHelpdeskNav = (data) => {
  localStorage.setItem(EventHelpdeskNavKey, JSON.stringify(data));
};

export const getEventHelpdeskNav = () => {
  let eventHelpdeskNav = localStorage.getItem(EventHelpdeskNavKey);
  if(eventHelpdeskNav){
    return JSON.parse(eventHelpdeskNav);
  }
  return [];
};
export const removeEventHelpdeskNav = () => {
  return JSON.parse(localStorage.getItem(EventHelpdeskNavKey));
};
export const setLanguageRes = (languageRes) => {
  sessionStorage.setItem(languageResKey, JSON.stringify(languageRes));
};

export const getLanguageRes = () => {
  let languageResStr = sessionStorage.getItem(languageResKey);
  if(languageResStr){
    return JSON.parse(languageResStr);
  }
  return {};
};

export const removeLanguageRes = () => {
  sessionStorage.removeItem(languageResKey);
};

export const getDecimalPlacesOfMoney = () => {
  let payAmountDigits = 2;
  if(getSystemGeneralInfo()?.payAmountDigits){
    payAmountDigits = getSystemGeneralInfo()?.payAmountDigits;
  }
  return Number(payAmountDigits);
};

export const getDefaultPageSize = () => {
  let defaultPageSize = 20;
  if(getSystemGeneralInfo()?.defaultPageSize){
    defaultPageSize = getSystemGeneralInfo()?.defaultPageSize;
  }
  return Number(defaultPageSize);
};

export const getPayrollTotalAmountDigits = () => {
  let payrollTotalAmountDigits = 2;
  if(getSystemGeneralInfo()?.payrollTotalAmountDigits){
    payrollTotalAmountDigits = getSystemGeneralInfo()?.payrollTotalAmountDigits;
  }
  return Number(payrollTotalAmountDigits);
};

export default {
  getWikiHelpLinkCodes,
  setWikiHelpLinkCodes,
  timeSet,
  timeGet,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  set,
  get,
  clearAll,
  removeItem,
  setSystemGeneralInfo,
  getSystemGeneralInfo,
  setLanguageId,
  getLanguageId,
  removeLanguageId,
  getTimeZone,
  getDateFormat,
  getLocationMenuList,
  setLocationMenuList,
  getElectionMenuList,
  setElectionMenuList,
  setElectionId,
  getElectionId,
  removeElectionId,
  setMyballotNav,
  getMyballotNav,
  removeMyballotNav,
  setCandidateNav,
  getCandidateNav,
  removeCandidateNav,
  getLocationAllNavs,
  setLocationAllNavs,
  removeLocationAllNavs,
  removeLocationMenuList,
  removeElectionMenuList,
  setElectionStatus,
  getElectionStatus,
  isElectionIsArchived,
  setBauStaffNav,
  getBauStaffNav,
  removeBauStaffNav,
  setEventStaffNav,
  getEventStaffNav,
  removeEventStaffNav,
  setCommunicationIds,
  getCommunicationIds,
  setBauAssetNav,
  getBauAssetNav,
  setEventAssetNav,
  getEventAssetNav,
  removeBauAssetNav,
  getAdminNavs,
  removeAdminNavs,
  setAdminNavs,
  setBauElectorNav,
  setEventElectorNav,
  getBauElectorNav,
  getEventElectorNav,
  removeBauElectorNav,
  removeEventElectorNav,
  setLanguageRes,
  getLanguageRes,
  removeLanguageRes,
  setBauHelpdeskNav,
  getBauHelpdeskNav,
  removeBauHelpdeskNav,
  setEventHelpdeskNav,
  getEventHelpdeskNav,
  removeEventHelpdeskNav,
  setEventResultNav,
  getEventResultNav,
  removeEventResultNav,
  getDecimalPlacesOfMoney,
  getDefaultPageSize,
  getPayrollTotalAmountDigits
};
