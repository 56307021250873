export default [
  {
    name: 'contactUs',
    path: '/contactUs',
    meta: {
      title: 'contactUs', requiresAuth: true,
      anonymous: true
    },
    component: () => import('@/views/contactus/contactus'),
    route: true,

  },
]
