<template>
  <span class="iconSpan">
    <b-icon
      icon="trash"
      @click="deleteItem"
      ref="deleteToolTip"
      @keyup.enter="deleteItem"
      :tabindex="tabIndex"
    />
    <b-tooltip
      :target="() => $refs['deleteToolTip']"
      :title="$g('icon.deleteToolTip')"
      boundary="window"
      :container="uid"
      placement="bottomleft"
      triggers="hover"
    />
    <div :id="uid" />
  </span>
</template>
<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data () {
    return {
      uid: 'tip' + this._uid,
    }
  },
  methods: {
    deleteItem () {
      this.$emit('click')
    },
  },
}
</script>
