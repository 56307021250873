<template>
  <span id="multiselect" ref="multiselect">
    <span
      v-if="plaintext"
      class="col-form-label d-block"
      :style="{
        color: displayColor,
        whiteSpace: isStacked ? 'pre-line' : '',
      }"
    >
      {{ displayText }}
    </span>
    <multiselect
      v-else
      ref="multiselectModel"
      v-model="value"
      @input="onChange"
      :tag-placeholder="tagplaceholder"
      :placeholder="placeholder"
      label="text"
      track-by="value"
      select-label=""
      :hide-selected="true"
      :options="curOptions"
      :multiple="multiple"
      :taggable="taggable"
      @remove="handleRemove"
      :block-keys="selectLabel ? [] : ['Enter']"
      @tag="handleAddTag"
      :options-limit="optionsLimit"
      @search-change="searchChange"
      @close="multiselectClose"
      @select="handleSelect"
      :tabindex="tabIndex"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <div :style="{ color: option.color ? option.color : '#000000' }">
          {{ option.text }}
        </div>
      </template>
      <!-- <template slot="option" slot-scope="props">
        <div
          :style="{
            color: props.option.color ? props.option.color : '#000000'
          }"
        >
          {{ props.option.text }}
        </div>
      </template> -->
    </multiselect>
    <span
      tabindex="-1"
      ref="focusTool"
      @keydown.enter.prevent="openMultiselect"
      @focus="focusToolFocus"
      @blur="focusToolBlur"
      style="margin: 0; padding: 0;"
      class="multiselect__focusTool"
    ></span>
    <!--focusTool This is needed for the drop-down keyboard event -->
  </span>
</template>
<script>
import multiselect from "vue-multiselect";
import { g } from "../../locale/lang-val";
import { debounce } from "../../utils/tools";

export default {
  components: { multiselect },
  props: {
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
    tagplaceholder: {
      type: String,
      default() {
        return g("addNewTag");
      },
    },
    placeholder: {
      type: String,
      default() {
        return g("multiselect.searchAddTag");
      },
    },
    selectLabel: {
      type: String,
      default() {
        return g("multiselect.pressNnterSelect");
      },
    },
    taggable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    addTag: {
      type: Function,
      default: function () {
        return null;
      },
    },
    selectValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | String | Object,
      default() {
        return [];
      },
    },
    onItemRemove: {
      type: Function,
      default: function () {
        return null;
      },
    },
    isStacked: {
      type: Boolean,
      default() {
        return false;
      },
    },
    optionsLimit: {
      type: Number,
      default() {
        return 1000;
      },
    },
    asynSearchApi: {
      type: Function,
      default: null,
    },
    expandQuery: {
      type: Object,
      default() {
        return null;
      },
    },
    propsMultiselectClose: {
      type: Function,
      default() {
        return null;
      },
    },
    tabIndex: {
      type: Number,
      default() {
        return 0;
  },
    },
  },
  data() {
    return {
      value: [],
      data: [],
      searchText: "",
      curOptions: this.options,
    };
  },
  model: {
    prop: "selectValue",
    event: "balabala",
  },
  methods: {
    multiselectClose(value, id) {
      this.$emit("blur", this.value, this.selectValue);
      if (this.propsMultiselectClose) {
        this.propsMultiselectClose(value, id);
      }
    },
    searchChange(query) {
      if (this.asynSearchApi) {
        this.searchText = query;
        debounce(this.getOptionsList);
      }
    },
    doSearch(query) {
      if (this.asynSearchApi) {
        this.searchText = query;
        debounce(this.getOptionsList);
      }
    },
    getOptionsList() {
      let dataQuery = { searchText: this.searchText };
      if (this.expandQuery) {
        dataQuery = { ...dataQuery, ...this.expandQuery };
      }
      let nowItem = this.value;
      let isNowItem = false;
      this.asynSearchApi(dataQuery).then((res) => {
        this.curOptions = res.data?.map((item) => {
          if (!isNowItem && nowItem) {
            isNowItem = nowItem.value == item.value;
          }
          return { text: item.text, value: item.value };
        });
        if (!isNowItem && nowItem) {
          this.curOptions.push(nowItem);
        }
        this.setValue();
      });
    },
    onChange(val) {
      var tempv = "";
      if (this.multiple) {
        if (this.value) {
          this.value.forEach((value) => {
            tempv += value["value"] + ",";
          });
        }
        if (tempv == "") {
          tempv = [];
          this.$emit("balabala", tempv);
        } else {
          this.$emit(
            "balabala",
            tempv != null ? tempv.replace(/,$/gi, "").split(",") : tempv
          );
        }
        this.$emit("SelectChange", this.value, this.selectValue, val);
        this.$emit("blur", this.value, this.selectValue);
      } else {
        tempv = this.value.value;
        this.$emit("balabala", tempv);
        this.$emit("SelectChange", this.value.value, this.selectValue, val);
        this.$emit("blur", this.value, this.selectValue);
      }
    },
    setValue() {
      if (this.multiple) {
        var temp = [];
        var tempv = this.getValStr();
        if (tempv) {
          this.curOptions.forEach((value) => {
            if (tempv.indexOf("," + value["value"] + ",") >= 0) {
              temp.push(value);
            }
          });
        }
        this.value = temp;
      } else {
        this.value = this.getSelectItemByValue();
      }
    },

    getSelectItemByValue() {
      let ojb = {};
      if (!this.multiple) {
        ojb = this.curOptions.find((value) => {
          return value.value == this.selectValue;
        });
      }
      return ojb;
    },
    getValStr() {
      var tempv = "";
      if (this.selectValue instanceof Array) {
        tempv = this.selectValue.join(",");
      } else {
        tempv = this.selectValue;
      }
      if (tempv != "") {
        tempv = "," + tempv + ",";
      }
      return tempv;
    },
    select() {
      var tempv = "";
      if (this.value) {
        this.value.forEach((value) => {
          tempv += value["value"] + ",";
        });
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.selectValue = tempv.trimRight(",");
      this.$emit("SelectChange", this.value, this.selectValue);
      this.$emit("blur", this.value, this.selectValue);
    },
    removeNull() {
      if (this.curOptions.length >= 1 && this.curOptions[0].value == null) {
        // eslint-disable-next-line vue/no-mutating-props
        this.curOptions.shift();
      }
    },
    handleAddTag(e, id) {
      if (this.addTag) {
        this.addTag(e, id);
      }
      this.selectedChange();
    },
    handleSelect(e,id){
      this.selectedChange();
    },
    handleRemove(e,id){
      if(this.onItemRemove){
        this.onItemRemove(e,id);
      }
      this.selectedChange();
    },
    selectedChange() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.focusTool?.focus();
        }, 100);
      });
    },
    openMultiselect() {
      this.$nextTick(() => {
        this.$refs.multiselectModel?.activate();
      });
    },
    focusToolFocus() {
      this.$refs.focusTool.tabIndex = this.tabIndex;
    },
    focusToolBlur() {
      this.$refs.focusTool.tabIndex = -1;
    },
  },
  computed: {
    displayColor() {
      var texts = "#000000";
      var tempv = this.getValStr();
      if (tempv) {
        this.curOptions.forEach((value) => {
          //数组循环
          if (
            tempv.indexOf("," + value["value"] + ",") >= 0 &&
            value["value"] != null
          ) {
            texts = value["color"];
          }
        });
      }
      return texts;
    },
    displayText() {
      var texts = "";
      var tempv = this.getValStr();
      if (tempv) {
        this.curOptions.forEach((value) => {
          //数组循环
          if (
            tempv.indexOf("," + value["value"] + ",") >= 0 &&
            value["value"] != null
          ) {
            if (this.isStacked) {
              texts += value["text"] + "\r\n";
            } else {
              texts += value["text"] + ", ";
            }
          }
        });
      }
      return texts.replace(/, $/gi, "");
    },
  },
  mounted: function () {
    this.setValue();
  },
  watch: {
    selectValue: function () {
      this.setValue();
    },
    options(val) {
      this.curOptions = val;
      this.removeNull();
      this.setValue();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep {
  .multiselect {
    min-height: calc(1.5em + 0.75rem + 2px);
  }
  .multiselect__tags {
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    padding-right: 40px;
  }
  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 1rem;
    padding-left: 0;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .multiselect__tag {
    background-color: var(--tagsBackground);
    color: var(--tagsFont);
    margin-bottom: 3px;
    border-radius: 0.25rem;
  }

  .multiselect__tag-icon {
    line-height: 24px;
    font-weight: normal;
  }

  .multiselect__tag-icon:after {
    color: inherit;
    opacity: 0.5;
    font-size: 1.2rem;
    text-shadow: 0 1px 0 #fff;
    content: "×";
    font-weight: normal;
    font-family: arial;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: none;

    &:after {
      opacity: 0.75;
      color: inherit !important;
    }
  }

  .multiselect__option--selected,
  .multiselect__option--selected:after {
    background: #ddd;
    color: #333;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #eee;
    color: #333;
  }

  .multiselect__placeholder {
    padding-top: 0;
    margin-bottom: 0;
  }

  .multiselect__select {
    transition: none;
    height: calc(1.5em + 0.75rem);
  }

  .multiselect--active {
    .multiselect__tags {
      border-color: var(--focusStyle);
    }
  }
  .multiselect__select:before {
    top: 70%;
  }
  .multiselect__tags-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
