<template>
  <div class="content-page">
    <election-select v-if="isEvent" />
    <div class="content">
      <div class="center-bar">
        <div class="nav-btn-group">
          <k-button-group>
            <k-button-back
              :back-route="isEvent ? 'eleReport' : 'report'"
              :back-query="{ tabindex: $route.query.tabindex }"
              v-if="hasBack"
            >
              buttons.back
            </k-button-back>
            <k-export-button
              :params-getter="getExportCSV"
              :export-handler="exportHandler"
              :check-has-data="checkHasData"
              :exported-callback="exportCallbacker"
              v-permission="permissionCode.Report.Export"
            >
              buttons.exportCSV
            </k-export-button>
            <k-export-button
              v-if="
                $route.query.tempName ===
                'ele-poll-worker-basicinfo-work-assignment-report'
              "
              :params-getter="getExportExcel"
              :export-handler="exportExcelHandler"
              :check-has-data="checkHasData"
              :exported-callback="exportCallbacker"
              v-permission="permissionCode.Report.Export"
            >
              buttons.exportExcel
            </k-export-button>
          </k-button-group>
          <KWikiHelp :props-wiki-help-code="permissionCode.Report.View" />
        </div>
        <div class="content-main">
          <k-alert-msg ref="lblMsg" />
          <p v-show="totalCount == 0">
            {{ $g("reportNoRecords") }}
          </p>
          <h1 class="text-center" v-show="totalCount != 0">
            {{ title }}
          </h1>
          <div class="content-party">
            <konn-grid
              ref="selectableTable"
              :has-check-box="false"
              :has-action="false"
              :get-condition="getCondition"
              :data-source="dataSource"
              :set-paras="setParas"
              :sticky-header="true"
              class="stickyTable"
              @total-changed="onTotalChanged"
              :grid-footer-string="footString"
              :has-footer="true"
              v-show="totalCount != 0"
            >
              <template #cell(trainingLink)="row">
                <div v-html="row.value" />
              </template>
              <template #cell(workBarCodeImage)="row">
                <div v-html="row.value" />
              </template>
              <template #cell(trainingBarCodeImage)="row">
                <div v-html="row.value" />
              </template>
              <template #cell(trainingDescription)="row">
                <div v-html="row.value" />
              </template>
              <template #tableCaption>
                <div style="background: #f1f1f1">
                  <div
                    style="
                      color: var(--tableTbodyFont);
                      padding: 0 0.45rem;
                      font-weight: bold;
                    "
                  >
                    {{ $g("total") }}
                  </div>
                  <konn-grid
                    ref="selectableFootTable"
                    :has-check-box="false"
                    :has-action="false"
                    :get-condition="getCondition"
                    :data-source="footDataSource"
                    :set-paras="setParas"
                    :has-pager="false"
                    :auto-bind="false"
                    class="footData"
                    :needDynamicCalculatedColumnWidth="false"
                  />
                </div>
              </template>
            </konn-grid>
          </div>
          <div style="padding: 0.45rem">
            {{ $g("report-last-update-at") }} {{ reportTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReportDetailPage,
  exportReport,
  getReportFoot,
  checkReportNeedSum,
  getReportDetail,
} from "../../api/report";
import storeage from "../../../../utils/storeage";
import { repResult } from "../../../../libs/global";
import { startTaskProgress } from "../../../common-remote-task/remoteTaskStarter";
import http from "../../../../utils/http";

export default {
  props: {
    hasBack: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    propsElectionId: {
      type: String,
      default: () => {
        return null;
      },
    },
    permissionCode: {
      type: Object,
      default() {
        return this.$route.meta.permissionCode;
      },
    },
  },
  data() {
    let isEvent = this.$route.meta.isEvent ?? false;
    return {
      reportId: this.$route.query.id,
      isEvent: isEvent,
      title: "",
      footString: null,
      reportTime: "",
      electionId: this.propsElectionId ?? storeage.getElectionId() ?? "",
      totalCount: 1,
    };
  },
  computed: {
    dataSource() {
      return getReportDetailPage;
    },
    footDataSource() {
      return getReportFoot;
    },
    exportHandler: function () {
      return exportReport;
    },
    exportExcelHandler: function () {
      return this.exportExcelReport;
    },
  },
  methods: {
    exportExcelReport(params) {
      return http.post(params.api, params.data, {
        useBody: true,
      });
    },
    onTotalChanged(total) {
      this.totalCount = total;
    },
    exportCallbacker(rep) {
      startTaskProgress(rep.data.info);
    },
    checkHasData() {
      if (!this.totalCount) {
        this.$refs.lblMsg.message(
          repResult.warning,
          this.$g("const.confirm.exportNoData")
        );
        return false;
      } else {
        return true;
      }
    },
    getExportCSV() {
      return {
        reportId: this.reportId,
        exportType: 1,
        electionId: this.electionId,
      };
    },
    getExportExcel() {
      return {
        api: this.$route.meta.exportExcelApi,
        data: {
          reportId: this.reportId,
          exportType: 3,
          electionId: this.electionId,
        },
      };
    },
    setParas() {},
    getCondition() {
      return {
        reportId: this.reportId,
        electionId: this.electionId,
      };
    },
  },
  created() {
    checkReportNeedSum(this.reportId).then((resp) => {
      if (resp.data.status == repResult.success) {
        this.$refs.selectableFootTable.refresh();
      }
    });
    getReportDetail(this.reportId).then((resp) => {
      if (resp.data) {
        this.title = resp.data.title;
        this.reportTime = resp.data.generateTime;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .stickyTable .table.b-table {
    > thead > tr > th {
      min-width: 200px;
    }

    > tbody > tr > td {
      min-width: 200px;
    }
  }

  .footData {
    .table-responsive-sm {
      margin-bottom: 0;
    }

    .table.b-table {
      border-bottom: 0;

      > thead {
        display: none;
      }

      > tbody {
        background-color: inherit;

        > tr > td {
          border: 0;
          background-color: inherit;
        }
      }
    }
  }
}
</style>
