import { render, staticRenderFns } from "./shareToAddUsers.vue?vue&type=template&id=284f70a6&"
import script from "./shareToAddUsers.vue?vue&type=script&lang=js&"
export * from "./shareToAddUsers.vue?vue&type=script&lang=js&"
import style0 from "./shareToAddUsers.vue?vue&type=style&index=0&id=284f70a6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../fe-workerportal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports