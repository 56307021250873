<template>
  <div>
    <konn-grid
      :select-mode="selectMode"
      :has-check-box="hasCheckBox"
      :has-action="hasAction"
      :fields="fields"
      :is-original-fields="true"
      :auto-bind="true"
      ref="importLogList"
      :data-source="dataProvider"
      :get-condition="getCondition"
      @edit-clicked="onEditClicked"
      :class="{ 'table-show-search-area': showThisSearchArea }"
      @row-selected="onRowSelected"
      grid-sort-by="importTime"
      grid-sort-desc
    />
  </div>
</template>

<script>
import { getImportLogPage, getImportLogPageWorker } from "../api/import";
import storeage from "../../../utils/storeage";
import { importType } from "../../../libs/global";

export default {
  components: {},
  data() {
    return {
      selectMode: "single",
      hasCheckBox: false,
      hasAction: false,
      fields: [
        {
          key: "displayImportTime",
          label: "DateTime",
          width: "150px",
        },
        {
          key: "logContent",
          label: "Description",
          width: "450px",
        },
        {
          key: "operator",
          label: "Imported By",
          width: "150px",
        },
      ],

      showThisSearchArea: false,
    };
  },
  computed: {
    dataProvider() {
      if (
        this.importType ==
          importType.votingStationUpdateResourceAdjustmentBAU ||
        this.importType == importType.votingStationUpdateResourceAdjustmentEvent
      ) {
        return getImportLogPageWorker;
      }
      return getImportLogPage;
    },
  },

  props: {
    importType: {
      type: Number,
      default() {
        return 0;
      },
    },
    isEvent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    refresh() {
      this.$refs.importLogList.refresh();
    },
    getCondition() {
      return {
        importType: this.importType,
        electionId: this.isEvent ? storeage.getElectionId() : null,
      };
    },
    onEditClicked() {
      return;
    },
    onRowSelected() {
      return;
    },
  },
};
</script>
